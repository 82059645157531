import { useCallback, useEffect } from "react";
import { EventTracker } from "../../../../../components/eventTracker/EventTracker";
import { BankSelection } from "../../../../../components/klarna/bankSelection/BankSelection";
import { TranslatedText } from "../../../../../components/TranslatedText";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
  TrackerEvent,
} from "../../../../../data/dataCustomerTracking";
import { AvailableBank } from "../../../../../data/dataKlarna";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { useMonthlyContext } from "../MonthlyContext";
import { BASE_ROUTES } from "../MonthlyStory";

interface Props {
  next: () => void;
}

export function BankSelectionWrapper({ next }: Props) {
  const monthlyContext = useMonthlyContext();
  const setMonthlyContext = monthlyContext.setState;

  const onComplete = useCallback(
    (bank: AvailableBank) => {
      setMonthlyContext({ bank });
      EventTracker.track({
        event: TrackerEvent.ADD_MONTHLY_KLARNA_BANK,
        message: bank.displayName,
      });
      dataCustomerTrackingService.postEvent({
        eventName: "monthlyBankSelected",
        domain: FeatureDomain.TRANSFERS,
        subDomain: SubDomain.MONTHLY,
      });
      setTimeout(() => {
        next();
      }, 0);
    },
    [next, setMonthlyContext]
  );

  useEffect(() => {
    if (!monthlyContext.state.bank) {
      dataCustomerTrackingService.postEvent({
        eventName: "monthlyInitBankSelection",
        domain: FeatureDomain.TRANSFERS,
        subDomain: SubDomain.MONTHLY,
      });
    }
  }, [monthlyContext.state.bank]);

  return (
    <BankSelection
      onComplete={onComplete}
      missingBank={{
        pathname: getNavLink(BASE_ROUTES.MANUAL_ACCOUNT_SELECTION),
        state: {
          returnUrl: getNavLink(BASE_ROUTES.EXTERNAL_ACCOUNT),
          returnState: monthlyContext.state,
        },
      }}
      missingBankHeader={
        <TranslatedText id="klarna.bank-selection.missing-bank.header" />
      }
      missingBankDescription={
        <TranslatedText id="klarna.bank-selection.missing-bank.description" />
      }
    />
  );
}

import { Typography } from "@lysaab/ui-2";
import { FunctionComponent } from "react";
import { TranslatedText } from "../../../../../components/TranslatedText";

import "./Chip.scss";

interface Props {
  value: number;
  currentValue: number;
  onClick: (newValue: number) => void;
}

export const Chip: FunctionComponent<Props> = ({
  value,
  currentValue,
  onClick,
}) => {
  return (
    <button
      className={`chip ${value === currentValue && "selected"}`}
      value={value}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        onClick(Number(event.currentTarget.value));
      }}
    >
      {value === -1 ? "Livslång" : value}{" "}
      {value !== -1 && (
        <Typography type="label">
          <TranslatedText
            id="sweden.withdrawal-pension.age-and-length.quick-choice.unit"
            defaultMessage="år"
          />
        </Typography>
      )}
    </button>
  );
};

import {
  Alternative,
  Button,
  Form,
  LysaFormRef,
  Select,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Typography,
} from "@lysaab/ui-2";
import { Chip } from "./Chip";
import { useLocation } from "react-router";
import {
  dataInvestments,
  isPensionAdviseAccount,
  PensionWithdrawalParametersResponse,
} from "../../../../../data/dataInvestments";
import { GuideLinesText } from "./GuideLinesText";
import { defineMessages, useIntl } from "react-intl";
import { useUser } from "../../../../../context/UserContext";
import { getUserAge } from "../../transferPensions/utils/userAge";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { InvestmentAccountId } from "../../../../../data/dataAccounts";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { dataLifePensionStorage } from "../../../../../data/dataLifePensionStorage";

import "./AgeAndLength.scss";
import { useAdviseAccount } from "../../../../../hooks/useAdviseAccount";

const messages = defineMessages({
  withdrawalAgePreference: {
    id: "sweden.transfer-pension.age-and-length.withdrawal-age.alternative",
  },
  withdrawalAgeDefaultPreference: {
    id: "sweden.transfer-pension.age-and-length.withdrawal-age.default-alternative",
  },
  withdrawalPeriodPreference: {
    id: "sweden.transfer-pension.age-and-length.withdrawal-period.alternative",
  },
  withdrawalPeriodDefaultPreference: {
    id: "sweden.transfer-pension.age-and-length.withdrawal-period.default-alternative",
  },
  january: {
    id: "sweden.transfer-pension.age-and-length.withdrawal-month.alternative.january",
  },
  february: {
    id: "sweden.transfer-pension.age-and-length.withdrawal-month.alternative.february",
  },
  march: {
    id: "sweden.transfer-pension.age-and-length.withdrawal-month.alternative.march",
  },
  april: {
    id: "sweden.transfer-pension.age-and-length.withdrawal-month.alternative.april",
  },
  may: {
    id: "sweden.transfer-pension.age-and-length.withdrawal-month.alternative.may",
  },
  june: {
    id: "sweden.transfer-pension.age-and-length.withdrawal-month.alternative.june",
  },
  july: {
    id: "sweden.transfer-pension.age-and-length.withdrawal-month.alternative.july",
  },
  august: {
    id: "sweden.transfer-pension.age-and-length.withdrawal-month.alternative.august",
  },
  september: {
    id: "sweden.transfer-pension.age-and-length.withdrawal-month.alternative.september",
  },
  october: {
    id: "sweden.transfer-pension.age-and-length.withdrawal-month.alternative.october",
  },
  november: {
    id: "sweden.transfer-pension.age-and-length.withdrawal-month.alternative.november",
  },
  december: {
    id: "sweden.transfer-pension.age-and-length.withdrawal-month.alternative.december",
  },
  lifeLong: {
    id: "sweden.transfer-pension.age-and-length.withdrawal-month.alternative.life-long",
  },
});

const MAX_WITHDRAWAL_AGE = 100;

interface Props {
  toAccountWithoutChange: (accountId: string) => void;
  toAccountWithChange: (accountId: string) => void;
}

export const AgeAndLength: FunctionComponent<Props> = ({
  toAccountWithoutChange,
  toAccountWithChange,
}) => {
  const intl = useIntl();
  const user = useUser();
  const age = getUserAge(user.tin);
  const formRef = useRef<LysaFormRef>();
  const [withdrawalAge, setWithdrawalAge] = useState<number>();
  const [withdrawalPeriod, setWithdrawalPeriod] = useState<number>();
  const [withdrawalMonth, setWithdrawalMonth] = useState<number>();
  const [hasRepayment, setHasRepayment] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [withdrawalParameters, setWithdrawalParameters] =
    useState<PensionWithdrawalParametersResponse>();

  const location = useLocation();
  const { pathname } = location;
  const pathnameParts = pathname.split("/");
  const accountId = pathnameParts[
    pathnameParts.length - 2
  ] as InvestmentAccountId;

  const [advise] = useAdviseAccount(accountId);

  useEffect(() => {
    dataInvestments
      .getPensionWithdrawalParameters(accountId as InvestmentAccountId)
      .then((withdrawalParametersResponse) => {
        /**
         * Ensure we update withdrawalAge if the user has moved past the previous selected age.
         */
        const normalisedWithdrawalAge: number =
          age !== undefined && withdrawalParametersResponse.withdrawalAge < age
            ? age
            : withdrawalParametersResponse.withdrawalAge;

        setWithdrawalParameters(withdrawalParametersResponse);
        setWithdrawalMonth(withdrawalParametersResponse.withdrawalMonth);
        setWithdrawalAge(normalisedWithdrawalAge);
        setWithdrawalPeriod(
          withdrawalParametersResponse.withdrawalMonths
            ? withdrawalParametersResponse.withdrawalMonths / 12
            : -1
        );
      })
      .catch((error) => {
        console.log(
          `Could not fetch pension withdrawal parameters. Error: ${error}`
        );
        throw error;
      });
  }, [accountId, age]);

  useEffect(() => {
    if (accountId) {
      dataLifePensionStorage
        .getPension(accountId)
        .then((pension) => {
          if (pension.repaymentProtection === true) {
            setHasRepayment(true);
          }
        })
        .catch((error) => {
          console.log(`Could not fetch pension. Error: ${error}`);
          throw error;
        });
    }
  }, [accountId]);

  if (
    !age ||
    !withdrawalParameters ||
    !withdrawalAge ||
    !withdrawalPeriod ||
    !withdrawalMonth
  ) {
    return <Spinner />;
  }

  /**
   * Make sure user can't select a weird combination that doesn't make sense or violates our rules.
   */

  const minWithdrawalAge = Math.max(withdrawalParameters.minWithdrawalAge, age);
  const maxWithdrawalAge = Math.min(
    withdrawalParameters.maxWithdrawalAge,
    MAX_WITHDRAWAL_AGE - withdrawalPeriod
  );

  const withdrawalAgeAlternatives = generateYearArray(
    minWithdrawalAge,
    maxWithdrawalAge
  ).map(
    (age): Alternative<number> => ({
      text: intl.formatMessage(messages.withdrawalAgePreference, {
        age: age,
      }),
      value: age,
    })
  );

  const minWithdrawalPeriod = withdrawalParameters.minWithdrawalMonths / 12;
  const maxWithdrawalPeriod = Math.min(
    withdrawalParameters.maxWithdrawalMonths / 12,
    MAX_WITHDRAWAL_AGE - withdrawalAge
  );

  const withdrawalPeriodAlternatives = generateYearArray(
    minWithdrawalPeriod,
    maxWithdrawalPeriod
  ).map(
    (year): Alternative<number> => ({
      text: intl.formatMessage(messages.withdrawalPeriodPreference, {
        years: year,
      }),
      value: year,
    })
  );

  if (!hasRepayment) {
    withdrawalPeriodAlternatives.push({
      text: intl.formatMessage(messages.lifeLong),
      value: -1,
    });
  }

  const MAX_AGE_QUICK_CHOICES = 3;
  const withdrawalAgeQuickChoice = withdrawalAgeAlternatives
    .slice(0, MAX_AGE_QUICK_CHOICES)
    .map((ageAlternative) => ageAlternative.value);

  const MAX_PERIOD_QUICK_CHOICES = 3;
  const withdrawalPeriodQuickChoice = withdrawalPeriodAlternatives
    .slice(0, MAX_PERIOD_QUICK_CHOICES)
    .map((periodAlternative) => periodAlternative.value);

  if (!hasRepayment) {
    withdrawalPeriodQuickChoice.push(-1);
  }

  const withdrawalMonthAlternatives = [
    { text: intl.formatMessage(messages.january), value: 1 },
    { text: intl.formatMessage(messages.february), value: 2 },
    { text: intl.formatMessage(messages.march), value: 3 },
    { text: intl.formatMessage(messages.april), value: 4 },
    { text: intl.formatMessage(messages.may), value: 5 },
    { text: intl.formatMessage(messages.june), value: 6 },
    { text: intl.formatMessage(messages.july), value: 7 },
    { text: intl.formatMessage(messages.august), value: 8 },
    { text: intl.formatMessage(messages.september), value: 9 },
    { text: intl.formatMessage(messages.october), value: 10 },
    { text: intl.formatMessage(messages.november), value: 11 },
    { text: intl.formatMessage(messages.december), value: 12 },
  ];

  const hasChanged =
    withdrawalAge !== withdrawalParameters.withdrawalAge ||
    withdrawalPeriod * 12 !== withdrawalParameters.withdrawalMonths;

  if (!isPensionAdviseAccount(advise)) {
    return null;
  }

  return (
    <article className="withdrawal-pension-age-and-length">
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid) {
            return;
          }
          dataInvestments
            .updatePensionWithdrawalParameters(
              accountId as InvestmentAccountId,
              {
                withdrawalMonths: withdrawalPeriod * 12,
                withdrawalAge: withdrawalAge,
                withdrawalMonth: withdrawalMonth,
                lifeLong: withdrawalPeriod === -1 ? true : false,
              }
            )
            .then(() => {
              if (hasChanged) {
                toAccountWithChange(accountId);
              } else {
                toAccountWithoutChange(accountId);
              }
            })
            .catch((error) => {
              console.log(
                `Could not update pension withdrawal parameters. Error: ${error}`
              );
              throw error;
            });
        }}
      >
        <section>
          <Typography type="h1">
            <TranslatedText id="sweden.withdrawal-pension.age-and-length.header" />
          </Typography>
          <Typography type="body">
            <TranslatedText id="sweden.withdrawal-pension.age-and-length.ingress" />
          </Typography>
          <Select
            alternatives={withdrawalAgeAlternatives}
            label={intl.formatMessage({
              id: "sweden.withdrawal-pension.withdrawal-age.select.label",
            })}
            placeholder={intl.formatMessage({
              id: "sweden.withdrawal-pension.withdrawal-age.select.placeholder",
            })}
            value={withdrawalAgeAlternatives.find(
              (alternative) => alternative.value === withdrawalAge
            )}
            onChange={(newValue) => {
              setWithdrawalAge(newValue.value);
            }}
          />
          <div className="chips">
            {withdrawalAgeQuickChoice.map((choice) => {
              return (
                <Chip
                  key={choice}
                  value={choice}
                  currentValue={withdrawalAge}
                  onClick={(newValue) => {
                    setWithdrawalAge(newValue);
                  }}
                />
              );
            })}
          </div>
          <div className="withdrawal-month-select-container">
            <Select
              alternatives={withdrawalMonthAlternatives}
              label={intl.formatMessage({
                id: "sweden.withdrawal-pension.withdrawal-month.select.label",
              })}
              placeholder={intl.formatMessage({
                id: "sweden.withdrawal-pension.withdrawal-month.select.placeholder",
              })}
              value={withdrawalMonthAlternatives.find(
                (alternative) => alternative.value === withdrawalMonth
              )}
              onChange={(newValue) => {
                setWithdrawalMonth(newValue.value);
                /** Just change month does not count as a major change */
              }}
            />
          </div>
          <Select
            alternatives={withdrawalPeriodAlternatives}
            label={intl.formatMessage({
              id: "sweden.withdrawal-pension.withdrawal-period.select.label",
            })}
            placeholder={intl.formatMessage({
              id: "sweden.withdrawal-pension.withdrawal-period.select.placeholder",
            })}
            value={withdrawalPeriodAlternatives.find(
              (alternative) => alternative.value === withdrawalPeriod
            )}
            onChange={(newValue) => {
              setWithdrawalPeriod(newValue.value);
            }}
          />
          <div className="chips">
            {withdrawalPeriodQuickChoice.map((choice) => {
              return (
                <Chip
                  key={choice}
                  value={choice}
                  currentValue={withdrawalPeriod}
                  onClick={(newValue) => {
                    setWithdrawalPeriod(newValue);
                  }}
                />
              );
            })}
          </div>
          <Snackbar type={SNACKBAR_TYPES.INFO} icon textAlign="left">
            <TranslatedText id="sweden.withdrawal-pension.withdrawal-period.snackbar" />
          </Snackbar>
        </section>
        {hasChanged && (
          <section>
            <GuideLinesText
              user={user}
              advise={advise}
              withdrawalAge={withdrawalAge}
              withdrawalMonths={withdrawalPeriod * 12}
            />
          </section>
        )}
        <section>
          {hasChanged ? (
            <Button
              className="next"
              block
              type="submit"
              variant="primary"
              label={intl.formatMessage({
                id: "sweden.withdrawal-pension.age-and-length.save.button",
                defaultMessage: "Spara",
              })}
            />
          ) : (
            <Button
              className="next"
              block
              type="submit"
              variant="primary"
              label={intl.formatMessage({
                id: "sweden.withdrawal-pension.age-and-length.continue.button",
                defaultMessage: "Fortsätt",
              })}
            />
          )}
        </section>
      </Form>
    </article>
  );
};

function generateYearArray(start: number, end: number) {
  const years = [];
  for (let i = start; i <= end; i++) {
    years.push(i);
  }
  return years;
}

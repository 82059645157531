import {
  Button,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Typography,
} from "@lysaab/ui-2";
import { useEffect, useMemo, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { GridCol } from "../../components/grid/gridCol/GridCol";
import { AlignValues, GridRow } from "../../components/grid/gridRow/GridRow";
import { CompoundAccountId } from "../../data/dataAccounts";
import {
  dataAttorney,
  ShareAccountInvitation,
  ShareAccountInvitationStatus,
  ShareAccountSentInvitation,
} from "../../data/dataAttorney";
import { getNavLink } from "../../hooks/useCountryUrls";
import { SHARE_ACCOUNT_SENT_INVITATION_URL } from "../shareAccountSentInvitation/ShareAccountSentInvitationPage";
import {
  ShareAccountLocationState,
  SHARE_ACCOUNT_URL,
} from "../shareAccountStory/ShareAccountStory";
import {
  SharedAccountsView,
  SHARED_ACCOUNTS_VIEW_URL,
} from "../sharedAccountsPage/SharedAccountsPage";
import { AccountSelect } from "./AccountSelect";
import { InvitationCard } from "./InvitationCard";
import {
  ShareAccountInvitationsView,
  SHARE_ACCOUNT_INVITATIONS_URL,
  SHARE_ACCOUNT_INVITATIONS_VIEW_URL,
} from "./ShareAccountInvitationsPage";
import { RouteAwareToggle } from "../../components/route/RouteAwareToggle";

const messages = defineMessages({
  noEntriesText: {
    id: "shareAccountInvitations.sent.noEntriesText",
  },
});

interface Props {
  selectedAccount?: CompoundAccountId;
}

export const SentInvitations: React.VFC<Props> = ({ selectedAccount }) => {
  const [invitations, setInvitations] =
    useState<(ShareAccountSentInvitation | ShareAccountInvitation)[]>();
  const [error, setError] = useState<boolean>(false);
  const history = useHistory();
  const intl = useIntl();

  const handleAccountChange = (value?: CompoundAccountId | string) => {
    const search = new URLSearchParams(history.location.search);

    if (value) {
      search.set("accountId", value);
      history.replace({
        pathname: getNavLink(SHARE_ACCOUNT_INVITATIONS_URL),
        search: search.toString(),
      });
    } else {
      search.delete("accountId");
      history.replace({
        pathname: getNavLink(SHARE_ACCOUNT_INVITATIONS_URL),
        search: search.toString(),
      });
    }
  };

  useEffect(() => {
    dataAttorney
      .getSentInvitations()
      .then((invitations) => {
        setInvitations(invitations);
        setError(false);
      })
      .catch(() => {
        setError(true);
      });
  }, []);

  const sortedInvitations = useMemo(
    () =>
      invitations
        ?.filter(
          ({ accountId }) => !selectedAccount || selectedAccount === accountId
        )
        .sort(
          (a, b) =>
            new Date(b.created).getTime() - new Date(a.created).getTime()
        ),
    [invitations, selectedAccount]
  );
  const pendingInvitations = useMemo(
    () =>
      sortedInvitations?.filter(
        ({ status }) => status === ShareAccountInvitationStatus.ACCEPTED_INVITEE
      ) ?? [],
    [sortedInvitations]
  );

  const activeInvitations = useMemo(
    () =>
      sortedInvitations?.filter(
        ({ status }) => status === ShareAccountInvitationStatus.CREATED
      ) ?? [],
    [sortedInvitations]
  );

  const inactiveInvitations = useMemo(
    () =>
      sortedInvitations?.filter(({ status }) =>
        [
          ShareAccountInvitationStatus.TIMED_OUT,
          ShareAccountInvitationStatus.DECLINED_INVITEE,
          ShareAccountInvitationStatus.DECLINED_OWNER,
        ].includes(status)
      ) ?? [],
    [sortedInvitations]
  );

  if (error) {
    return (
      <Snackbar type={SNACKBAR_TYPES.ERROR}>
        <FormattedMessage id="shareAccountInvitations.sent.error" />
      </Snackbar>
    );
  }

  if (typeof sortedInvitations === "undefined") {
    return <Spinner />;
  }

  return (
    <div>
      <GridRow>
        <GridCol xsmall={12} medium={6} large={4}>
          <AccountSelect
            onChange={handleAccountChange}
            value={selectedAccount}
          />
        </GridCol>
        <GridCol
          xsmall={12}
          medium={6}
          large={4}
          alignSelf={AlignValues.END}
          className="col-button"
        >
          <Button
            component={Link}
            to={getNavLink(
              SHARED_ACCOUNTS_VIEW_URL(
                SharedAccountsView.SHARED_BY_ME,
                selectedAccount
              )
            )}
            block
            variant="secondary"
            label={
              <FormattedMessage id="shareAccountInvitations.sent.viewInvitesButton" />
            }
          />
        </GridCol>
        <RouteAwareToggle path={getNavLink(SHARE_ACCOUNT_URL)}>
          <GridCol
            xsmall={12}
            medium={6}
            large={4}
            alignSelf={AlignValues.END}
            className="col-button"
          >
            <Link<ShareAccountLocationState>
              className="lysa-ui-button button-primary button-medium block"
              to={{
                pathname: getNavLink(SHARE_ACCOUNT_URL),
                state: {
                  accountId: selectedAccount,
                  returnUrl: getNavLink(
                    SHARE_ACCOUNT_INVITATIONS_VIEW_URL(
                      ShareAccountInvitationsView.SENT
                    )
                  ),
                },
              }}
            >
              <Typography type="label">
                <FormattedMessage id="shareAccountInvitations.sent.newInviteButtonText" />
              </Typography>
            </Link>
          </GridCol>
        </RouteAwareToggle>
      </GridRow>
      <h3>
        <FormattedMessage id="shareAccountInvitations.sent.pendingHeader" />
      </h3>
      <GridRow>
        {pendingInvitations.length ? (
          pendingInvitations.map((invitation) => (
            <GridCol
              key={invitation.invitationId}
              xsmall={12}
              medium={6}
              large={4}
            >
              <InvitationCard
                invitation={invitation}
                viewMoreUrl={SHARE_ACCOUNT_SENT_INVITATION_URL}
              />
            </GridCol>
          ))
        ) : (
          <GridCol>
            <p>{intl.formatMessage(messages.noEntriesText)}</p>
          </GridCol>
        )}
      </GridRow>
      <h3>
        <FormattedMessage id="shareAccountInvitations.sent.activeHeader" />
      </h3>
      <GridRow>
        {activeInvitations.length ? (
          activeInvitations.map((invitation) => (
            <GridCol
              key={invitation.invitationId}
              xsmall={12}
              medium={6}
              large={4}
            >
              <InvitationCard
                invitation={invitation}
                viewMoreUrl={SHARE_ACCOUNT_SENT_INVITATION_URL}
              />
            </GridCol>
          ))
        ) : (
          <GridCol>
            <p>{intl.formatMessage(messages.noEntriesText)}</p>
          </GridCol>
        )}
      </GridRow>
      <h3>
        <FormattedMessage id="shareAccountInvitations.sent.inactiveHeader" />
      </h3>
      <GridRow>
        {inactiveInvitations.length ? (
          inactiveInvitations.map((invitation) => (
            <GridCol
              key={invitation.invitationId}
              xsmall={12}
              medium={6}
              large={4}
            >
              <InvitationCard invitation={invitation} />
            </GridCol>
          ))
        ) : (
          <GridCol>
            <p>{intl.formatMessage(messages.noEntriesText)}</p>
          </GridCol>
        )}
      </GridRow>
    </div>
  );
};

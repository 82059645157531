import { Button } from "@lysaab/ui-2";
import { Link } from "react-router-dom";
import { RouteAwareToggle } from "../../../components/route/RouteAwareToggle";
import { TranslatedText } from "../../../components/TranslatedText";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "../../../pages/overview/OverviewPage";
import { MONTHLY_OVERVIEW_PAGE } from "../../../pages/deposits/monthly/overview/MonthlyOverview";
import { DEPOSITS_OVERVIEW_URL } from "../../../pages/deposits/overview/Recommendation";
import "./Done.scss";
import {
  setUserState,
  UserContext,
  useUser,
} from "../../../context/UserContext";
import { useBootstrapUser } from "../../../data/dataLogin";
import { useContext, useEffect } from "react";
import { useUserStorage } from "../../../context/UserStorageContext";

const useEnsureSavingsAccountsAllowed = () => {
  const user = useUser();
  const userContext = useContext(UserContext);
  const { setUserStorage } = useUserStorage();
  const bootstrapUser = useBootstrapUser();
  useEffect(() => {
    if (!user.isSavingsAccountsAllowed) {
      console.log(
        "User is not allowed to have savings accounts, bootstrapping user"
      );
      bootstrapUser().then(
        ([
          bootstrap,
          legalEntity,
          userStates,
          isSavingsAccountsAllowed,
          economicSituation,
        ]) => {
          setUserState(
            bootstrap,
            legalEntity,
            userContext.setState,
            isSavingsAccountsAllowed,
            economicSituation
          );
          setUserStorage(userStates);
        }
      );
    }
  }, [
    bootstrapUser,
    setUserStorage,
    user.isSavingsAccountsAllowed,
    userContext.setState,
  ]);
};

export function Done() {
  useEnsureSavingsAccountsAllowed();
  return (
    <div className="create-account--done-page">
      <h2>
        <TranslatedText id="create-account.done.header" />
      </h2>
      <p className="text-body-margins">
        <TranslatedText id="create-account.done.text" />
      </p>
      <RouteAwareToggle path={getNavLink(DEPOSITS_OVERVIEW_URL)}>
        <Button
          block
          component={Link}
          to={getNavLink(DEPOSITS_OVERVIEW_URL)}
          label={<TranslatedText id="create-account.done.link.deposits" />}
        />
      </RouteAwareToggle>
      <RouteAwareToggle path={getNavLink(MONTHLY_OVERVIEW_PAGE)}>
        <Button
          block
          component={Link}
          to={getNavLink(MONTHLY_OVERVIEW_PAGE)}
          label={<TranslatedText id="create-account.done.link.monthly" />}
        />
      </RouteAwareToggle>
      <Button
        block
        variant="secondary"
        component={Link}
        to={getNavLink(OVERVIEW_PAGE_URL)}
        label={<TranslatedText id="create-account.done.link.overview" />}
      />
    </div>
  );
}

import { Button, Snackbar, SNACKBAR_TYPES, Spinner } from "@lysaab/ui-2";
import { LayoutGroup, motion } from "framer-motion";
import { useState, useEffect, useMemo, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { GridCol } from "../../../components/grid/gridCol/GridCol";
import { AlignValues, GridRow } from "../../../components/grid/gridRow/GridRow";
import { CompoundAccountId } from "../../../data/dataAccounts";
import { AccountShareByMe, dataAttorney } from "../../../data/dataAttorney";
import { getNavLink } from "../../../hooks/useCountryUrls";
import {
  ShareAccountInvitationsView,
  SHARE_ACCOUNT_INVITATIONS_VIEW_URL,
} from "../../shareAccountInvitations/ShareAccountInvitationsPage";
import { SHARE_ACCOUNT_URL } from "../../shareAccountStory/ShareAccountStory";
import { AccountSelect } from "../AccountSelect";
import { AccountShareCard } from "../AccountShareCard";
import {
  SharedAccountsView,
  SHARED_ACCOUNTS_URL,
  SHARED_ACCOUNTS_VIEW_URL,
} from "../SharedAccountsPage";
import { RouteAwareToggle } from "../../../components/route/RouteAwareToggle";

const ANIMATION_LAYOUT_SCOPE = "SHARED_BY_ME_LAYOUT";

interface Props {
  selectedAccount?: CompoundAccountId;
}

export const SharedByMe: React.VFC<Props> = ({ selectedAccount }) => {
  const [accountShares, setAccountShares] = useState<AccountShareByMe[]>();
  const [error, setError] = useState<boolean>(false);
  const history = useHistory();

  const load = useCallback(async () => {
    return dataAttorney
      .getAccountSharesByMe()
      .then((shares) => {
        setAccountShares(shares);
        setError(false);
      })
      .catch(() => {
        setError(true);
      });
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  const handleAccountChange = (value?: CompoundAccountId) => {
    const search = new URLSearchParams(history.location.search);

    if (value) {
      search.set("accountId", value);
      history.replace({
        pathname: getNavLink(SHARED_ACCOUNTS_URL),
        search: search.toString(),
      });
    } else {
      search.delete("accountId");
      history.replace({
        pathname: getNavLink(SHARED_ACCOUNTS_URL),
        search: search.toString(),
      });
    }
  };

  const sortedShares = useMemo(
    () =>
      accountShares
        ?.filter(
          ({ accountId }) => !selectedAccount || selectedAccount === accountId
        )
        .sort(
          (a, b) =>
            new Date(b.created).getTime() - new Date(a.created).getTime()
        ),
    [accountShares, selectedAccount]
  );

  if (error) {
    return (
      <Snackbar type={SNACKBAR_TYPES.ERROR}>
        <FormattedMessage id="sharedAccountsPage.sharedByMe.error" />
      </Snackbar>
    );
  }

  if (typeof sortedShares === "undefined") {
    return <Spinner />;
  }

  return (
    <div>
      <GridRow>
        <GridCol xsmall={12} medium={6} large={4}>
          <AccountSelect
            onChange={handleAccountChange}
            value={selectedAccount}
          />
        </GridCol>
        <GridCol
          xsmall={12}
          medium={6}
          large={4}
          alignSelf={AlignValues.END}
          className="col-button"
        >
          <Button
            component={Link}
            to={getNavLink(
              SHARE_ACCOUNT_INVITATIONS_VIEW_URL(
                ShareAccountInvitationsView.SENT,
                selectedAccount
              )
            )}
            block
            variant="secondary"
            label={
              <FormattedMessage id="sharedAccountsPage.sharedByMe.viewInvitesButton" />
            }
          />
        </GridCol>
        <GridCol
          xsmall={12}
          medium={6}
          large={4}
          alignSelf={AlignValues.END}
          className="col-button"
        >
          <RouteAwareToggle path={getNavLink(SHARE_ACCOUNT_URL)}>
            <Button
              component={Link}
              block
              to={{
                pathname: getNavLink(SHARE_ACCOUNT_URL),
                state: {
                  accountId: selectedAccount,
                  returnUrl: getNavLink(
                    SHARED_ACCOUNTS_VIEW_URL(SharedAccountsView.SHARED_BY_ME)
                  ),
                },
              }}
              label={
                <FormattedMessage id="sharedAccountsPage.sharedByMe.newInviteButton" />
              }
            />
          </RouteAwareToggle>
        </GridCol>
      </GridRow>
      <h3>
        <FormattedMessage id="sharedAccountsPage.sharedByMe.activeSharesHeader" />
      </h3>

      <LayoutGroup id={ANIMATION_LAYOUT_SCOPE}>
        <GridRow>
          {sortedShares.length ? (
            sortedShares.map((accountShare) => (
              <GridCol
                key={accountShare.accountShareId}
                xsmall={12}
                medium={6}
                large={4}
              >
                <motion.div
                  layout
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, zIndex: 1 }}
                  layoutId={accountShare.accountShareId}
                  transition={{
                    type: "spring",
                    damping: 24,
                    stiffness: 200,
                  }}
                >
                  <AccountShareCard
                    accountShare={accountShare}
                    onDelete={load}
                  />
                </motion.div>
              </GridCol>
            ))
          ) : (
            <GridCol>
              <p>
                <FormattedMessage id="sharedAccountsPage.sharedByMe.noEntriesText" />
              </p>
            </GridCol>
          )}
        </GridRow>
      </LayoutGroup>
    </div>
  );
};

import { createContext } from "../../../../context/CreateContext";
import { ExternalAccount } from "../../../../data/dataWithdrawals";

/**
 * Just a small context for keeping data in sync. Not sure if we need it once backend is ready.
 */

export type WithdrawalPensionsContextState = {
  externalAccount?: ExternalAccount;
};

export const [WithdrawalPensionsContextProvider, useWithdrawalPensions] =
  createContext<WithdrawalPensionsContextState>(
    "WithdrawalPensionsContext",
    {}
  );

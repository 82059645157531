import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useIntl } from "react-intl";
import {
  Button,
  NewIcon,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import {
  dataInvestments,
  PensionAdviseAccount,
  PensionQuestions,
  SustainabilityImportance,
  SustainabilityQuestions,
} from "../../../data/dataInvestments";
import { TargetAllocation } from "./components/targetAllocation/TargetAllocation";
import { dataFees, FeesEstimatedSignedIn } from "../../../data/dataFees";
import {
  InvestmentAccountId,
  isInvestmentAccount,
} from "../../../data/dataAccounts";
import { FlatButton } from "../../../components/flatButton/FlatButton";
import { useUser } from "../../../context/UserContext";
import { usePensionAllocation } from "../../../hooks/usePensionAllocation";
import { getUserAge } from "../../../utils/userAge";
import { FeesModal } from "./components/feesModal/FeesModal";
import { GuideLinesText } from "./components/guideLinesText/GuideLinesText";
import { getInvestmentTypeTextFromSustainabilityImportance } from "../../../texts/investmentTypeTexts";
import { Params } from "../UpdatePensionStory";
import { useAccount } from "../../../hooks/useAccount";
import { TranslatedText } from "../../../components/TranslatedText";

import "./VerifyUpdate.scss";

const EXAMPLE_AMOUNT = 100_000;

interface UpdateTakenRiskDeviationAction {
  type: "UPDATE_RISK_DEVIATION";
  value: {
    takenRiskDeviation: number;
  };
}

interface UpdateWithdrawalPlanAction {
  type: "UPDATE_WITHDRAWAL_PLAN";
  value: PensionQuestions;
}

interface UpdateSustainabilityAction {
  type: "UPDATE_SUSTAINABILITY";
  value: SustainabilityQuestions;
}

type UpdateAction =
  | UpdateTakenRiskDeviationAction
  | UpdateWithdrawalPlanAction
  | UpdateSustainabilityAction;

export type VerifyUpdateLocationState = UpdateAction;

interface Props {
  locationState: VerifyUpdateLocationState;
  advise: PensionAdviseAccount;
  onSave: () => void;
}

export const VerifyUpdate: FC<Props> = ({ advise, locationState, onSave }) => {
  const { accountId } = useParams<Params>();
  const account = useAccount(accountId);
  const intl = useIntl();
  const user = useUser();
  const age = getUserAge(user.tin, user.country);

  const [submitError, setSubmitError] = useState(false);
  const [fees, setFees] = useState<FeesEstimatedSignedIn>();
  const [showFeesModal, setShowFeesModal] = useState(false);

  const newAllocationValues =
    locationState.type === "UPDATE_RISK_DEVIATION" ||
    locationState.type === "UPDATE_WITHDRAWAL_PLAN"
      ? locationState.value
      : undefined;

  const { allocation, status } = usePensionAllocation({
    age,
    withdrawalAge: advise.withdrawalAge,
    withdrawalMonths: advise.withdrawalMonths,
    takenRiskDeviation: advise.takenRiskDeviation,
    lifeLong: advise.lifeLong,
    ...newAllocationValues,
  });

  useEffect(() => {
    if (!allocation || !user.country || !isInvestmentAccount(account)) {
      return;
    }

    dataFees
      .getEstimatedFeesSignedIn({
        amount: EXAMPLE_AMOUNT,
        risk: allocation.currentTakenRisk,
        investmentType: advise.investmentType,
        country: user.country,
        accountType: account.type,
      })
      .then(setFees);
  }, [allocation, user.country, advise.investmentType, account]);

  const submit = useCallback(() => {
    getUpdatePromise(locationState, accountId)
      .then(() => {
        setSubmitError(false);
        onSave();
      })
      .catch(() => {
        setSubmitError(true);
      });
  }, [accountId, locationState, onSave]);

  if (!fees || status !== "success") {
    return <Spinner />;
  }

  return (
    <section className="VerifyUpdate">
      {(locationState.type === "UPDATE_RISK_DEVIATION" ||
        locationState.type === "UPDATE_WITHDRAWAL_PLAN") && (
        <TargetAllocation allocation={allocation} />
      )}

      {locationState.type === "UPDATE_SUSTAINABILITY" && (
        <FlatButton
          title={intl.formatMessage({
            id: "updatePensionStory.verifyUpdate.sustainability",
          })}
          value={getInvestmentTypeTextFromSustainabilityImportance(
            intl,
            locationState.value.sustainability
          )}
          icon={sustainabilityIcons[locationState.value.sustainability]}
        />
      )}

      <FlatButton
        title={intl.formatMessage({
          id: "updatePensionStory.verifyUpdate.new-fees",
        })}
        value={intl.formatNumber(fees.cost.total / 100, {
          style: "percent",
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        })}
        onClick={() => {
          setShowFeesModal(true);
        }}
      />

      <div className="button-container">
        {submitError && (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon textAlign="left">
            <TranslatedText id="updatePensionStory.verifyUpdate.submit-error" />
          </Snackbar>
        )}

        <GuideLinesText
          user={user}
          sustainability={
            locationState.type === "UPDATE_SUSTAINABILITY"
              ? locationState.value.sustainability
              : advise.sustainability
          }
          currentTakenRisk={allocation.currentTakenRisk}
        />

        <Button
          block
          label={intl.formatMessage({
            id: "updatePensionStory.verifyUpdate.button",
          })}
          type="button"
          onClick={() => {
            submit();
            console.log("save", locationState);
          }}
        />
      </div>

      <FeesModal
        showModal={showFeesModal}
        header={intl.formatMessage({
          id: "updatePensionStory.verifyUpdate.new-fees",
        })}
        fees={fees}
        amount={EXAMPLE_AMOUNT}
        onClose={() => {
          setShowFeesModal(false);
        }}
      />
    </section>
  );
};

function getUpdatePromise(
  action: UpdateAction,
  accountId: InvestmentAccountId
): Promise<unknown> {
  switch (action.type) {
    case "UPDATE_RISK_DEVIATION":
      return dataInvestments.updatePensionRiskDeviation(
        accountId,
        action.value.takenRiskDeviation
      );

    case "UPDATE_WITHDRAWAL_PLAN":
      return dataInvestments.updatePensionWithdrawalParameters(accountId, {
        ...action.value,
      });

    case "UPDATE_SUSTAINABILITY":
      return dataInvestments.updatePensionSustainability(
        accountId,
        action.value
      );
  }
}

const sustainabilityIcons: Record<
  SustainabilityImportance,
  React.ReactElement
> = {
  [SustainabilityImportance.NOT_IMPORTANT]: <NewIcon.Earth size={16} />,
  [SustainabilityImportance.IMPORTANT]: <NewIcon.Sustainable size={16} />,
};

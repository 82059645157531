import { NewIcon, Typography } from "@lysaab/ui-2";
import { FunctionComponent, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { TranslatedText } from "../../../../../components/TranslatedText";

import "./FAQ.scss";

const faqItems: FaqItemProps[] = [
  {
    title: "Vad händer nu?",
    content:
      "Eftersom du har valt att skjuta fram din första utbetalning, kommer vi att kontakta dig igen när det är dags att slutföra din utbetalningsplan. Du kan också se status och detaljer direkt i tjänsten.",
  },
  {
    title: "Kan jag ändra mitt beslut om att skjuta upp utbetalningen?",
    content:
      "Ja, du kan ändra ditt beslut och tidigarelägga din utbetalning genom att gå till “pensionsinställningar” i webben eller appen.",
  },
  {
    title:
      "Hur får jag reda på när det är dags att slutföra min utbetalningsplan?",
    content:
      "Vi skickar en påminnelse när det närmar sig. Du kan också logga in för att se aktuella datum och status.",
  },
  {
    title: "Hur påverkar detta mitt pensionssparande?",
    content:
      "Att skjuta fram din utbetalning innebär att pengarna fortsätter att investeras och växa under tiden, enligt din valda målfördelning.",
  },
];

export const FAQWithChange: FunctionComponent = () => {
  return (
    <section className="withdrawal-pension-done-faq">
      <Typography type="h2">
        <TranslatedText id="sweden.withdrawal-pension.done.faq.header" />
      </Typography>
      {faqItems.map((item, index) => (
        <AccordionItem key={index} title={item.title} content={item.content} />
      ))}
    </section>
  );
};

interface FaqItemProps {
  title: string;
  content: string;
}

const AccordionItem: FunctionComponent<FaqItemProps> = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-item">
      <button onClick={toggleOpen} className="faq-button">
        <Typography type="label" className="title">
          {title}
        </Typography>
        <span
          className={isOpen ? "chevron chevron-open" : "chevron chevron-closed"}
        >
          <NewIcon.ChevronRight />
        </span>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{
              opacity: 0,
              height: 0,
            }}
            transition={{ duration: 0.3 }}
          >
            <Typography type="body" className="faq-content">
              {content}
            </Typography>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

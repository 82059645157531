import { useState, useEffect, useCallback } from "react";
import { Page } from "../Page";
import { GraphCard } from "./GraphCard";
import { OverviewPageAccounts } from "./OverviewPageAccounts";
import { Retry, Status } from "../../components/retry/Retry";
import { dataAccounts } from "../../data/dataAccounts";
import "./OverviewPage.scss";
import { useAccounts } from "../../hooks/useAccounts";
import { WaitingSharedAccountInvitations } from "./WaitingSharedAccountInvitations";
import { RouteAwareToggle } from "../../components/route/RouteAwareToggle";
import { getNavLink } from "../../hooks/useCountryUrls";
import { SHARE_ACCOUNT_INVITATIONS_URL } from "../shareAccountInvitations/ShareAccountInvitationsPage";
import { NewsBanner } from "./components/newsBanner/NewsBanner";
import { KfActiveMigrationInfo } from "./components/components/KfActiveMigrationInfo";
import { KfMigrationInfo } from "./components/components/KfMigrationInfo";
import { Variant } from "@lysaab/shared";
import { experimentKeys } from "../../experimentConfig";
import { PensionModalEntryPoint } from "./components/components/PensionModalEntryPoint/PensionModalEntryPoint";
import { useIsPerson } from "../../hooks/useIsPerson";

export const OVERVIEW_PAGE_URL = "/";

export function OverviewPage() {
  const { accounts, getAccounts } = useAccounts();
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const isPerson = useIsPerson();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("reload") === "true") {
      // Remove the "reload" parameter from the URL
      searchParams.delete("reload");
      const newUrl = `${window.location.pathname}${
        searchParams.toString().length > 0 ? `?${searchParams.toString()}` : ""
      }`;
      window.history.replaceState(null, "", newUrl);

      //reset app
      window.resetApp();
    }
  }, []);

  const load = useCallback(() => {
    getAccounts()
      .then(() => {
        setStatus(Status.SUCCESS);
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, [getAccounts]);

  const retry = useCallback(() => {
    dataAccounts.clearGetAccounts();
    setTimeout(load, 500);
  }, [load]);

  useEffect(load, [load]);

  return (
    <Page className="overview-page">
      <NewsBanner />
      {isPerson && (
        <Variant propertyKey={experimentKeys.PENSION_MOVE} value="true">
          <PensionModalEntryPoint />
        </Variant>
      )}
      <Variant propertyKey={experimentKeys.KF_MIGRATE_ENABLED} value="true">
        <KfMigrationInfo />
      </Variant>
      <Retry retry={retry} status={status}>
        <GraphCard
          accounts={
            typeof accounts !== "undefined"
              ? [...accounts.investmentAccounts, ...accounts.savingsAccounts]
              : []
          }
        />
        <RouteAwareToggle path={getNavLink(SHARE_ACCOUNT_INVITATIONS_URL)}>
          <WaitingSharedAccountInvitations />
        </RouteAwareToggle>
        <KfActiveMigrationInfo />
        <OverviewPageAccounts accounts={accounts} />
      </Retry>
    </Page>
  );
}

import { FC } from "react";
import { useIntl } from "react-intl";
import { Typography } from "@lysaab/ui-2";
import { FeesEstimatedSignedIn } from "../../../../../data/dataFees";
import HorizontalDivider from "../../../../../components/horizontalDivider/HorizontalDivider";
import { Modal } from "../../../../../components/modal/Modal";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { FormattedPercentage } from "../../../../../components/formattedPercentage/FormattedPercentage";
import { Amount } from "../../../../../components/amount/Amount";
import { useLocalizationContext } from "../../../../../context/LocalizationContext";

// TODO: Tax amount, should be dynamic or moved to config. Maybe year aswell.

import "./FeesModal.scss";

interface Props {
  showModal: boolean;
  header: string;
  fees: FeesEstimatedSignedIn;
  amount: number;
  onClose: () => void;
}

export const FeesModal: FC<Props> = ({
  showModal,
  header,
  fees: { cost, future },
  amount,
  onClose,
}) => {
  const intl = useIntl();
  const localization = useLocalizationContext();

  return (
    <Modal
      showModal={showModal}
      onClose={onClose}
      header={header}
      closeOnOverlayClick
    >
      <section className="FeesModal">
        <dl>
          <dt>
            <TranslatedText id="updatePensionStory.verifyUpdate.components.feesModal.insurancePremium" />
          </dt>
          <dd>
            <FormattedPercentage
              value={cost.insurancePremium ?? 0}
              decimals={3}
            />
            <Amount amount={future.insurancePremium ?? 0} decimals={2} />
          </dd>
        </dl>
        <dl>
          <dt>
            <TranslatedText id="updatePensionStory.verifyUpdate.components.feesModal.lysaFee" />
          </dt>
          <dd>
            <FormattedPercentage
              value={cost.discretionary + cost.fundManagement}
              decimals={3}
            />
            <Amount
              amount={future.discretionary + future.fundManagement}
              decimals={2}
            />
          </dd>
        </dl>
        <dl>
          <dt>
            <TranslatedText id="updatePensionStory.verifyUpdate.components.feesModal.fundAssets" />
          </dt>
          <dd>
            <FormattedPercentage value={cost.fundAssets} decimals={3} />
            <Amount amount={future.fundAssets} decimals={2} />
          </dd>
        </dl>
        <dl>
          <dt>
            <TranslatedText id="updatePensionStory.verifyUpdate.components.feesModal.transactionFees" />
          </dt>
          <dd>
            <FormattedPercentage value={cost.transactionFees} decimals={3} />
            <Amount amount={future.transactionFees} decimals={2} />
          </dd>
        </dl>
        <HorizontalDivider />
        <dl className="total">
          <dt>
            <TranslatedText id="updatePensionStory.verifyUpdate.components.feesModal.total" />
          </dt>
          <dd>
            <FormattedPercentage value={cost.total} decimals={3} />
            <Amount amount={future.total} decimals={2} />
          </dd>
        </dl>

        <Typography type="body-small">
          <TranslatedText
            id="updatePensionStory.verifyUpdate.components.feesModal.calculation"
            values={{
              amount: intl.formatNumber(amount, {
                currency: localization.state.currency,
                style: "currency",
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              }),
            }}
          />
        </Typography>
        <Typography type="label">
          <TranslatedText id="updatePensionStory.verifyUpdate.components.feesModal.tax.header" />
        </Typography>
        <Typography>
          <TranslatedText
            id="updatePensionStory.verifyUpdate.components.feesModal.tax.body"
            values={{
              tax: intl.formatNumber(0.372 / 100, {
                style: "percent",
                minimumFractionDigits: 3,
              }),
            }}
          />
        </Typography>
      </section>
    </Modal>
  );
};

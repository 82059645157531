import { DocModalLink } from "@lysaab/ui-2";
import { FunctionComponent } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export const SuitabilityAssessmentInfo: FunctionComponent = () => {
  const intl = useIntl();

  return (
    <div>
      <FormattedMessage
        id="sweden.transfer-pension.footer.suitability-assessment"
        values={{
          a: (linkText) => {
            return (
              <DocModalLink
                document="legal/se/sv/lämplighetsbedömning.md"
                modalAnnouncement={intl.formatMessage({
                  id: "sweden.transfer-pension.footer.suitability-assessment.modal-announcement",
                })}
                key="element-key-pp"
              >
                {linkText}
              </DocModalLink>
            );
          },
        }}
      />
    </div>
  );
};

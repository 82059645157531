import {
  API,
  BankIdInitResponse,
  BankIdStandardResponses,
  OrderRef,
  cache,
  encode,
} from "@lysaab/ui-2";
import {
  PAIImportance,
  SFDRImportance,
  SustainabilityImportance,
  SustainabilityPreference,
  TaxonomyImportance,
} from "./dataInvestments";
import { Beneficiary } from "../countries/sweden/pages/transferPensions/TransferContext";
import { AccountType, InvestmentAccountId } from "./dataAccounts";

export const DEFAULT_START_RISK = 100;
export const DEFAULT_FINAL_RISK = 20;
export const DEFAULT_REALLOCATION_YEARS = 10;

export enum Institute {
  AMF = "AMF",
  ALECTA = "ALECTA",
  AVANZA = "AVANZA",
  FOLKSAM = "FOLKSAM",
  FUTUR = "FUTUR",
  HANDELSBANKEN = "HANDELSBANKEN",
  IDUN_LIV = "IDUN_LIV",
  KPA = "KPA",
  LANSFORSAKRINGAR = "LANSFORSAKRINGAR",
  MOVESTIC = "MOVESTIC",
  NORDEA = "NORDEA",
  NORDNET = "NORDNET",
  SEB = "SEB",
  SKANDIA = "SKANDIA",
  SPP = "SPP",
  SVENSKA_FRIBREVSBOLAGET = "SVENSKA_FRIBREVSBOLAGET",
  SWEDBANK = "SWEDBANK",
}

export enum PensionMoveStatus {
  CREATED = "CREATED",
  WAITING_SEND_INSURED = "WAITING_SEND_INSURED",
  SIGNING_INSURED = "SIGNING_INSURED",
  WAITING_SEND_EMPLOYER = "WAITING_SEND_EMPLOYER",
  WAITING_FOR_EMPLOYER = "WAITING_FOR_EMPLOYER",
  WAITING_SIGNING_ADMIN = "WAITING_SIGNING_ADMIN",
  WAITING_SEND_INSTITUTE = "WAITING_SEND_INSTITUTE",
  WAITING_FOR_INSTITUTE = "WAITING_FOR_INSTITUTE",
  ADDITIONAL_INFO_INSTITUTE = "ADDITIONAL_INFO_INSTITUTE",
  APPROVED_INSTITUTE = "APPROVED_INSTITUTE",
  DONE = "DONE",
  REJECTED_CUSTOMER = "REJECTED_CUSTOMER",
  REJECTED_EMPLOYER = "REJECTED_EMPLOYER",
  REJECTED_INSTITUTE = "REJECTED_INSTITUTE",
  REJECTED_LYSA = "REJECTED_LYSA",
}

export type ActivePensionMoveStatus = Extract<
  PensionMoveStatus,
  | PensionMoveStatus.CREATED
  | PensionMoveStatus.WAITING_SEND_INSURED
  | PensionMoveStatus.SIGNING_INSURED
  | PensionMoveStatus.WAITING_SEND_EMPLOYER
  | PensionMoveStatus.WAITING_FOR_EMPLOYER
  | PensionMoveStatus.WAITING_SIGNING_ADMIN
  | PensionMoveStatus.WAITING_SEND_INSTITUTE
  | PensionMoveStatus.WAITING_FOR_INSTITUTE
  | PensionMoveStatus.ADDITIONAL_INFO_INSTITUTE
  | PensionMoveStatus.APPROVED_INSTITUTE
>;

/**
 * Used to toggle visibility of mailing instructions.
 */

export const waitingForInsuredStatuses = [
  PensionMoveStatus.CREATED,
  PensionMoveStatus.WAITING_SEND_INSURED,
  PensionMoveStatus.SIGNING_INSURED,
];

export enum PensionMoveSigningOptions {
  BANKID = "BANKID",
  MANUAL = "MANUAL",
  SCRIVE = "SCRIVE",
}

export enum LoginMethod {
  SWEDISH_MOBILE_BANKID_ANY_DEVICE = "SWEDISH_MOBILE_BANKID_ANY_DEVICE",
  SWEDISH_MOBILE_BANKID_SAME_DEVICE = "SWEDISH_MOBILE_BANKID_SAME_DEVICE",
  SWEDISH_MOBILE_BANKID_OTHER_DEVICE = "SWEDISH_MOBILE_BANKID_OTHER_DEVICE",
  SWEDISH_MOBILE_BANKID_ANY_DEVICE_TEST = "SWEDISH_MOBILE_BANKID_ANY_DEVICE_TEST",
  SWEDISH_MOBILE_BANKID_OTHER_DEVICE_MOCK = "SWEDISH_MOBILE_BANKID_OTHER_DEVICE_MOCK",
}

export type CollectionStatus =
  | "CREATED"
  | "RUNNING"
  | "LOGIN"
  | "COLLECTING"
  | "DATA_AVAILABLE"
  | "SAVING_DATA"
  | "CHECKING_DATA"
  | "COMPLETED"
  | "COMPLETED_EMPTY"
  | "COMPLETED_PARTIAL"
  | "LOGIN_TIMEOUT"
  | "FAILED"
  | "AUTHENTICATION_CONFLICT"
  | "AUTHENTICATION_MISMATCH"
  | "EXTRA_INFO_NEEDED"
  | "MANUAL_LOGIN_NEEDED"
  | "NOT_FOUND"
  | "OTHER"
  | "INSTITUTE_DOWN";

export const idleStatuses: Array<CollectionStatus> = ["CREATED"];

export const pendingStatuses: Array<CollectionStatus> = [
  "RUNNING",
  "LOGIN",
  "COLLECTING",
  "DATA_AVAILABLE",
  "CHECKING_DATA",
];

export const completedStatuses: Array<CollectionStatus> = [
  "COMPLETED",
  "COMPLETED_EMPTY",
  "INSTITUTE_DOWN",
];

export const failedStatuses: Array<CollectionStatus> = [
  "LOGIN_TIMEOUT",
  "FAILED",
  "AUTHENTICATION_CONFLICT",
  "AUTHENTICATION_MISMATCH",
  "COMPLETED_PARTIAL",
  "EXTRA_INFO_NEEDED",
  "MANUAL_LOGIN_NEEDED",
  "NOT_FOUND",
  "OTHER",
];

export interface PensionMoveAdviceRequest {
  takenRiskDeviation?: number;
  withdrawalAge?: number;
  withdrawalDuration: number | undefined; // This is in years. We use months in internal state (context). Should be undefined if lifeLong is true
  sfdr?: SFDRImportance;
  pai?: PAIImportance;
  sustainabilityPreference?: SustainabilityPreference;
  taxonomy?: TaxonomyImportance;
  sustainability: SustainabilityImportance;
  lifeLong: boolean;
}

export interface PensionMoveAdviceResponse {
  takenRiskDeviation?: number;
  withdrawalAge?: number;
  withdrawalLength?: number; // This is in years. We use months in internal state (context).
  sfdr?: SFDRImportance;
  pai?: PAIImportance;
  sustainabilityPreference?: SustainabilityPreference;
  taxonomy?: TaxonomyImportance;
  sustainability: SustainabilityImportance;
}
export interface PensionMoveRequest {
  institute: Institute;
  pensionId?: string;
  employerTin?: string;
  employer?: string;
  insuranceNumber?: string;
  type?: AccountType;
  privateProperty?: boolean;
  currentWorth?: number;
  beneficiaryAlternative?: Beneficiary;
}

export type MoveId = string & {
  readonly isMoveId: true;
};

export type CaseId = string & {
  readonly isCaseId: true;
};
interface BasePensionMoveIn {
  employer?: string;
  id: MoveId;
  caseId: CaseId;
  insuranceNumber?: string;
  reference?: string;
  institute: InsuranceCompany;
  repayment: boolean;
  signing: PensionMoveSigningOptions;
  type: AccountType;
  amount?: number;
}

export interface PensionMoveInResponse extends BasePensionMoveIn {
  state: PensionMoveStatus;
}

export interface ActivePensionMoveInResponse extends BasePensionMoveIn {
  state: ActivePensionMoveStatus;
}

export interface PensionWithdrawalPlanStatusResponse {
  needUpdate: InvestmentAccountId[];
  ready: InvestmentAccountId[];
}

/**
 * length and expectedEnd won't be returned in case the withdrawal plan is "livsvarig"
 */

export interface PensionWithdrawalPlanSummaryResponse {
  startAge: number;
  expectedStart: string;
  withdrawalAccount?: InvestmentAccountId;
  length?: number;
  expectedEnd?: string;
}

export interface InitiatePensionMoveRequest {
  caseId: string;
  moves: PensionMoveRequest[];
  advice: PensionMoveAdviceRequest;
}

export interface PensionMoveResponse {
  caseId: string;
  moves: PensionMoveInResponse[];
  advice: PensionMoveAdviceResponse;
}

export interface CompanyNameResponse {
  name?: string;
  status: string;
}

type QrPollResponse = {
  code: string;
};

export interface InsuranceCompaniesStatusResponse {
  insuranceCompany: InsuranceCompany;
  insuranceCompanyDisplayName: string;
  loginMethods: Array<LoginMethod>;
  downTimeReason?: string;
}

export type InsuranceCompany = Institute & {
  DEMO: "DEMO";
  DEMO_TWO: "DEMO_TWO";
};

export interface StartCollectionRequest {
  name: string;
  insuranceCompany: InsuranceCompany;
  loginMethod: LoginMethod;
}

export interface CompanyStatusBaseType {
  status: CollectionStatus;
  insuranceCompany: InsuranceCompany;
}

export interface CompanyStatusOther extends CompanyStatusBaseType {
  status: Exclude<CollectionStatus, "RUNNING" | "LOGIN" | "EXTRA_INFO_NEEDED">;
}

export interface CompanyStatusRunning extends CompanyStatusBaseType {
  status: "RUNNING";
  extraInformation?: BankIDExtraInformation;
}

export interface CompanyStatusLogin extends CompanyStatusBaseType {
  status: "LOGIN";
  extraInformation: BankIDExtraInformation;
}

export interface CompanyStatusExtraInfoNeeded extends CompanyStatusBaseType {
  status: "EXTRA_INFO_NEEDED";
  extraInformation: InfoNeededExtraInformation;
}

export type BankIDExtraInformation = {
  autostartToken: string;
  qrCode?: string;
  animatedQrData?: string;
};

export type InfoNeededExtraInformation = {
  infoNeeded: string;
};

export type CollectionStatusResponse =
  | CompanyStatusRunning
  | CompanyStatusLogin
  | CompanyStatusExtraInfoNeeded
  | CompanyStatusOther;

export type InsurelyPensionType =
  | "PRIVATE_PENSION"
  | "OCCUPATIONAL_PENSION"
  | "COLLECTIVE_OCCUPATIONAL_PENSION"
  | "IPS_PENSION";

export const InsurelyPrivatePensions: Array<InsurelyPensionType> = [
  "PRIVATE_PENSION",
];

export const InsurelyOccupationalPensions: Array<InsurelyPensionType> = [
  "OCCUPATIONAL_PENSION",
];

export type InformationMessageKey =
  | "MISSING_INSURANCE_HOLDER_DATA"
  | "MISSMATCHED_INSURED_NAME"
  | "INSURED_NAME_MISMATCH"
  | "NOT_SUPPORTED_TYPE"
  | "ACTIVE_PAYMENT"
  | "ACTIVE_PAYOUT"
  | "NO_RESULT"
  | "TRADITIONALLY_MANAGED"
  | "INDIVIDUAL_COMPANY"
  | "INSURANCE_NUMBER_MISSING";

export type Information = {
  [MessageKey in keyof InformationMessageKey]: "INFO" | "WARNING";
};
export interface ScrapedPensionData {
  currentWorth: number;
  id: string;
  insuranceCompany: Institute;
  insuranceHolderName?: string;
  insuranceHolderTin?: string;
  insuranceNumber?: string;
  insuredTin?: string;
  movable: "MOVABLE" | "NOT_MOVABLE" | "UNKNOWN";
  pensionType: InsurelyPensionType;
  information?: Information;
  pensionId: string;
  privateProperty?: boolean;
}

export type GetPensionDataResponse = {
  movability: {
    MOVABLE?: Array<ScrapedPensionData>;
    NOT_MOVABLE?: Array<ScrapedPensionData>;
    UNKNOWN?: Array<ScrapedPensionData>;
  };
  noPensionsFound: {
    [InstituteKey in keyof Institute]:
      | "FAILED"
      | "NOT_FOUND"
      | "OTHER"
      | "COMPLETED_EMPTY";
  };
};

type ScriveStatus =
  | "INITIATED"
  | "UPDATABLE"
  | "ONGOING"
  | "DOWNLOAD"
  | "COMPLETED"
  | "FAILED"
  | "CANCELLED"
  | "REJECTED"
  | "TIMEDOUT"
  | "NOT_FOUND";

export interface ScriveStatusResponse {
  status: ScriveStatus;
  signingUrl: string;
}

export interface ScriveStatusByCaseIdEntry {
  accountId: string;
  status: ScriveStatus;
  documentOpened: boolean;
  redirectUrl: string;
  signingUrl: string;
}

export type ScriveStatusByCaseIdResponse = ScriveStatusByCaseIdEntry[];

/**
 * TESTING INSURELY INTEGRATION
 *
 * Insurely use the users tin to authenticate against selected institutes with BankID.
 *
 * PROD: We use the users real tin.
 * TEST: We set the tin on the backend to avoid sending in a test users tin and trigger real a BankID transactions.
 * DEVELOPMENT (local): We have the possibility to send in a testTin from the list below to trigger different outcomes.
 * To use a testTin simply add it to the environmental variable as outlined below. Or just paste it in instead "undefined"
 * Just remember not to commit it.
 *
 * Tin          Expected result
 * ------------ ---------------
 * 192106049279 Fails at start
 * 192106179282 Fails after login
 * 192107139269 Returns a QR code for logging in, fails after login
 * 192107169274 Returns COMPLETED PARTIAL and one insurance after login
 * 192107249241 Returns an animated QR code updated every second for logging in, fails after login
 * 192108119138 Returns COMPLETED EMPTY after login
 * 192108139268 Returns COMPLETED with two of each type of insurance. One that contains the maximum amount of data, and one that contains the minimum amount of data
 * 192109059259 Returns COMPLETED with one of each type of insurance. All of them containing the minimum amount of data
 * 192109099180 Returns a QR code for logging in, returns COMPLETED with insurances after login
 * 192109109187 Returns an animated QR code updated every second for logging in, returns COMPLETED with insurances after login
 * 192109279204 Returns CONTACT FORM INPUT status and requires input of email and phone number before proceeding and returning COMPLETED EMPTY
 * 192110059207 Returns a 500 http response
 *
 */

const testTin = process.env.REACT_APP_INSURELY_TIN ?? undefined;

const shouldUseTestTin = process.env.NODE_ENV === "development" && testTin;
const testTinQueryString = shouldUseTestTin ? `?tin=${testTin}` : "";

const sortMoves = <T extends PensionMoveInResponse>(moves: T[]): T[] => {
  return moves
    .slice(0)
    .sort(
      (a, b) =>
        a.institute.localeCompare(b.institute) ||
        (a.amount ?? 0) - (b.amount ?? 0) ||
        (a.insuranceNumber ?? "").localeCompare(b.insuranceNumber ?? "")
    );
};

export const dataLifePensionMove = {
  /**
   * General requests and responses for the sending in the move application
   */
  initMove: (data: InitiatePensionMoveRequest) => {
    return API.post<PensionMoveResponse>(
      "/pension/move/init?scrive=true",
      data
    ).then((response) => {
      cache.delete(`/pension/moves`);
      return {
        ...response,
        moves: sortMoves(response.moves),
      };
    });
  },

  getMove: (caseId: string) => {
    return API.get<PensionMoveResponse>(
      `/pension/move/case/${caseId}/summary`
    ).then((response) => {
      cache.delete(`/pension/move/case/${caseId}/summary`);
      return {
        ...response,
        moves: sortMoves(response.moves),
      };
    });
  },

  getActiveMoves: () => {
    return API.get<ActivePensionMoveInResponse[]>(`/pension/moves/active`).then(
      (response) => {
        cache.delete(`/pension/moves/active`);
        return sortMoves(response);
      }
    );
  },

  getCompanyName: (orgNr: string) => {
    return API.post<CompanyNameResponse>(
      `/pension/move/company/check/${orgNr}`
    ).then((response) => {
      return response;
    });
  },

  /**
   * Set delivery method for move applicaiton (self service download or send by mail)
   */

  setDeliveryMethodForCase: async (
    caseId: string,
    deliveryMethod: "DOWNLOAD" | "MAIL"
  ) => {
    return API.post<PensionMoveResponse>(
      `/pension/move/case/${caseId}/delivery-method`,
      { sendHome: deliveryMethod === "MAIL" }
    );
  },

  setDeliveryMethodForMove: async (
    accountId: string,
    deliveryMethod: "DOWNLOAD" | "MAIL"
  ) => {
    return API.post<PensionMoveResponse>(
      `/pension/move/${accountId}/delivery-method`,
      { sendHome: deliveryMethod === "MAIL" }
    );
  },

  /**
   * Sign the move application with BankID
   */

  startSigning: (caseId: string) => {
    return API.post<BankIdInitResponse>(`/pension/move/${caseId}/bankid/start`);
  },

  pollSigning: (caseId: string, orderRef: OrderRef) => {
    return API.get<BankIdStandardResponses>(
      encode`/pension/move/${caseId}/bankid/poll/${orderRef}`,
      true
    );
  },

  pollQrCode: (caseId?: string, orderRef?: OrderRef) => {
    if (!caseId) {
      return Promise.resolve(undefined);
    }
    if (!orderRef) {
      return Promise.resolve(undefined);
    }

    return API.get<QrPollResponse>(
      encode`/pension/move/${caseId}/bankid/qr/${orderRef}`,
      true
    );
  },

  /**
   * Sign the move application with Scrive
   */

  startScriveSigning: ({
    moveId,
    callbackUrl,
  }: {
    moveId: string;
    callbackUrl: string;
  }) => {
    return API.post(encode`/pension/move/scrive/${moveId}/start`, {
      callbackUrl,
    });
  },

  getScriveStatusByMoveId: (moveId: string) => {
    return API.post<ScriveStatusResponse>(
      encode`/pension/move/scrive/${moveId}/status`
    );
  },

  getScriveStatusByCaseId: (caseId: string) => {
    return API.post<ScriveStatusByCaseIdResponse>(
      encode`/pension/move/scrive/case/${caseId}/status`
    );
  },

  /**
   * Insurely integration
   */

  // This Insurely endpoint doesn't require tin query parameter.
  getInsuranceCompaniesStatus: () => {
    return API.get<InsuranceCompaniesStatusResponse[]>(
      `/pension/collection/insurance-companies/status`
    );
  },

  getInsuranceCompanies: () => {
    return API.get<InsuranceCompaniesStatusResponse[]>(
      `/pension/collection${testTinQueryString}`
    ).then((response) => {
      cache.delete(`/pension/collection${testTinQueryString}`);
      return response;
    });
  },

  initiateCollection: (insuranceCompanies: InsuranceCompany[]) => {
    return API.post<void>(
      `/pension/collection${testTinQueryString}`,
      insuranceCompanies
    ).then((response) => {
      cache.delete(`/pension/collection${testTinQueryString}`);
      return response;
    });
  },

  // Add type and proper handling for this. Can return "Ok", "FAILED" or "INSTITUTE_DOWN".
  startCollection: (body: StartCollectionRequest) => {
    return API.post<"OK" | "ERROR" | "INSTITUTE_DOWN">(
      `/pension/collection/start${testTinQueryString}`,
      body
    );
  },

  collectionStatus: (company: string) => {
    return API.post<CollectionStatusResponse>(
      encode`/pension/collection/status/${company}` + testTinQueryString
    );
  },

  collectionArchive: (company: string) => {
    return API.post<unknown>(
      encode`/pension/collection/archive?insuranceCompany=${company}` +
        testTinQueryString
    );
  },

  getPensionData: () => {
    return API.get<GetPensionDataResponse>(
      encode`/pension/collection/result` + testTinQueryString
    ).then((response) => {
      cache.delete(`/pension/collection/result${testTinQueryString}`);
      return response;
    });
  },

  /**
   * @param id id of a pension move or a case.
   * @param type refers to the type of the id, either pension case (containing multiple moves) or a single move.
   */
  getPensionMoveApplicationUrl: (id: string, type: "case" | "move"): string => {
    return type === "case"
      ? `${process.env.REACT_APP_API}/pension/move/case/${id}/print/Pensionsflytt_till_Lysa.pdf`
      : `${process.env.REACT_APP_API}/pension/move/${id}/print/${id}.pdf`;
  },

  /**
   * Withdrawal
   */

  getWithdrawalAccount: (accountId: InvestmentAccountId) => {
    return API.get<unknown>(
      encode`/life/pension/${accountId}/withdrawalAccount`
    );
  },

  updateWithdrawalAccount: (
    accountId: InvestmentAccountId,
    body: { account: string }
  ) => {
    return API.post<void>(
      encode`/life/pension/${accountId}/withdrawalAccount`,
      body
    );
  },

  getPensionWithdrawalPlanStatus: () => {
    return API.get<PensionWithdrawalPlanStatusResponse>(
      "/life/pension/withdrawal-plan-status"
    );
  },

  getPensionWithdrawalPlanSummary: (accountId: InvestmentAccountId) => {
    return API.get<PensionWithdrawalPlanSummaryResponse>(
      `/life/pension/${accountId}/withdrawal/plan-summary`
    );
  },
};

import { FunctionComponent, useEffect } from "react";

import { useIntl } from "react-intl";
import { Button, Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../components/TranslatedText";

import "./GroupIntroInsuranceInfo.scss";
import { useTransfer } from "../TransferContext";
import { useHistory } from "react-router";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { TRANSFER_PENSIONS_URL } from "../TransferPensionsStory";
import { Ingress } from "../components/ingress/Ingress";
import ScienceImage from "../../../../../assets/illustration-science.png";

interface Props {
  next: () => void;
}

export const GroupIntroInsuranceInfo: FunctionComponent<Props> = ({ next }) => {
  const intl = useIntl();
  const [transfer] = useTransfer();
  const history = useHistory();

  /**
   * Safe guard in case we loose context. On refresh etc. Send user back to start of story.
   */
  useEffect(() => {
    if (typeof transfer?.moves[0]?.institute === "undefined") {
      history.replace(getNavLink(TRANSFER_PENSIONS_URL));
    }
  }, [history, transfer?.moves]);

  return (
    <section className="transfer-pension-intro-group-intro">
      <header className="transfer-pension-intro-group-into-header">
        <img src={ScienceImage} alt="Leaf" width="192px" height="auto" />
        <Typography type="display-small">
          <TranslatedText id="sweden.transfer-pension.group-intro.header" />
        </Typography>
        <Ingress>
          <TranslatedText id="sweden.transfer-pension.group-intro.ingress" />
        </Ingress>
        <Ingress>
          <TranslatedText id="sweden.transfer-pension.group-intro.ingress.second" />
        </Ingress>
      </header>
      <Button
        type="button"
        variant="primary"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.group-intro.button",
        })}
        block
        onClick={next}
      />
    </section>
  );
};

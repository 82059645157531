import { FC } from "react";
import cx from "classnames";
import { Icon, Typography } from "@lysaab/ui-2";

import "./FlatButton.scss";

// TODO: Improve and move this component to ui-2.

interface Props {
  title: string;
  value: string;
  icon?: React.ReactNode;
  onClick?: () => void;
}

export const FlatButton: FC<Props> = ({ title, value, icon, onClick }) => {
  return (
    <button
      className={cx("FlatButton", {
        "FlatButton--clickable": onClick !== undefined,
      })}
      onClick={onClick}
      type="button"
    >
      <div className="FlatButton__content">
        <Typography component="span" type="label" className="FlatButton__label">
          {title}
        </Typography>

        <Typography component="span" className="FlatButton__value">
          {value}
          {icon}
        </Typography>
        {onClick && <Icon.ChevronRight color="var(--lysa-icon-primary)" />}
      </div>
    </button>
  );
};

import { useContext, useEffect, useState } from "react";
import {
  Card,
  Button,
  ServerError,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
  TrackerEvent,
} from "../../../../../../data/dataCustomerTracking";
import { EventTracker } from "../../../../../../components/eventTracker/EventTracker";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { dataAutogiro } from "../../../../../../data/dataAutogiro";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { MonthlyContext } from "../MonthlyContext";
import { BASE_ROUTES } from "../MonthlyStory";
import { LocalizationContext } from "../../../../../../context/LocalizationContext";
import { getAccountAmount } from "../../../../../../data/dataAccounts";

import "./Confirm.scss";

const messages = defineMessages({
  perMonth: {
    id: "sweden.deposits.monthly.story.confirm.amountPerMonth",
  },
});

interface Props {
  next: () => void;
}

export const Confirm = ({ next }: Props) => {
  const monthlyContext = useContext(MonthlyContext);
  const localizationContext = useContext(LocalizationContext);
  const intl = useIntl();
  const history = useHistory();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      (typeof monthlyContext.state.externalAccount === "undefined" &&
        typeof monthlyContext.state.selectedKlarnaAccount === "undefined") ||
      typeof monthlyContext.state.selectedLysaAccount === "undefined" ||
      !monthlyContext.state.amount
    ) {
      return history.replace(getNavLink(BASE_ROUTES.INTRO));
    }
  }, [
    history,
    monthlyContext.state.amount,
    monthlyContext.state.externalAccount,
    monthlyContext.state.selectedKlarnaAccount,
    monthlyContext.state.selectedLysaAccount,
  ]);

  const getSender = () => {
    if (typeof monthlyContext.state.externalAccount !== "undefined") {
      return `${monthlyContext.state.externalAccount.externalBankAccount}, ${monthlyContext.state.externalAccount.bank}`;
    } else if (
      typeof monthlyContext.state.selectedKlarnaAccount !== "undefined" &&
      typeof monthlyContext.state.bank !== "undefined"
    ) {
      return `${monthlyContext.state.selectedKlarnaAccount.bankAccount}, ${
        monthlyContext.state.bank.displayName
      }${
        monthlyContext.state.selectedKlarnaAccount.name &&
        ` - ${monthlyContext.state.selectedKlarnaAccount.name}`
      }`;
    }
  };

  if (
    typeof monthlyContext.state.selectedLysaAccount === "undefined" ||
    typeof monthlyContext.state.amount === "undefined"
  ) {
    return null;
  }

  const confirmMonthlyPayment = () => {
    const externalBankAccount = monthlyContext.state.selectedKlarnaAccount
      ? monthlyContext.state.selectedKlarnaAccount.bankAccount
      : monthlyContext.state.externalAccount?.externalBankAccount;
    if (
      monthlyContext.state.amount &&
      typeof externalBankAccount !== "undefined" &&
      typeof monthlyContext.state.selectedLysaAccount !== "undefined" &&
      monthlyContext.state.withdrawalDay
    ) {
      setIsLoading(true);
      dataAutogiro
        .createNewPeriodicDeposit({
          amount: parseFloat(monthlyContext.state.amount),
          externalBankAccount: externalBankAccount,
          accountId: monthlyContext.state.selectedLysaAccount.accountId,
          depositDay: monthlyContext.state.withdrawalDay,
        })
        .then(() => {
          const from = getSender();
          const to = monthlyContext.state.selectedLysaAccount?.name;
          const floatAmount = parseFloat(monthlyContext.state.amount || "0");
          const amount = `${intl.formatNumber(floatAmount, {
            style: "currency",
            currency: localizationContext.state.currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`;
          const day = monthlyContext.state.withdrawalDay;

          EventTracker.track({
            event: TrackerEvent.ADD_MONTHLY,
            message: `From: ${from}, To: ${to}, Amount: ${amount}, Day: ${day}`,
          });
          dataCustomerTrackingService.postEvent({
            eventName: "monthlySuccess",
            domain: FeatureDomain.TRANSFERS,
            subDomain: SubDomain.MONTHLY,
          });
          next();
        })
        .catch((error: ServerError<unknown>) => {
          EventTracker.track({
            event: TrackerEvent.ERROR,
            message: error.message,
          });
          setError(true);
        })
        .finally(() => setIsLoading(false));
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="monthly-story-confirm">
      <h2>
        <TranslatedText id="sweden.deposits.monthly.story.confirm.header" />
      </h2>
      <Card>
        {error && (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="sweden.deposits.monthly.story.confirm.error" />
          </Snackbar>
        )}
        <dl>
          <div className="monthly-story-confirm__entry">
            <dt>
              <TranslatedText id="sweden.deposits.monthly.story.confirm.sender" />
            </dt>
            <dd>{getSender()}</dd>
          </div>
          <div className="monthly-story-confirm__entry">
            <dt>
              <TranslatedText id="sweden.deposits.monthly.story.confirm.receiver" />
            </dt>
            <dd>
              <span className="monthly-story-confirm__element--bold">
                {monthlyContext.state.selectedLysaAccount.name}
              </span>{" "}
              -{" "}
              {intl.formatNumber(
                getAccountAmount(monthlyContext.state.selectedLysaAccount),
                {
                  style: "currency",
                  currency: localizationContext.state.currency,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}
            </dd>
          </div>
          <div className="monthly-story-confirm__entry">
            <dt>
              <TranslatedText id="sweden.deposits.monthly.story.confirm.amount" />
            </dt>
            <dd>
              {`${intl.formatNumber(parseFloat(monthlyContext.state.amount), {
                style: "currency",
                currency: localizationContext.state.currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} / ${intl.formatMessage(messages.perMonth)}`}
            </dd>
          </div>
          <div className="monthly-story-confirm__entry">
            <dt>
              <TranslatedText id="sweden.deposits.monthly.story.confirm.day" />
            </dt>
            <dd>{monthlyContext.state.withdrawalDay}</dd>
          </div>
        </dl>
      </Card>
      <Button
        block
        onClick={confirmMonthlyPayment}
        label={
          <TranslatedText id="sweden.deposits.monthly.story.confirm.next" />
        }
      />
    </div>
  );
};

import { FunctionComponent, useEffect, useRef, useState } from "react";
import {
  Button,
  Form,
  LysaFormRef,
  RadioGroupCard,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Typography,
} from "@lysaab/ui-2";

import {
  dataWithdrawals,
  ExternalAccount,
  getBankName,
  getBankText,
} from "../../../../../data/dataWithdrawals";
import { dataBanks } from "../../../../../data/dataBanks";
import { BankLogo } from "../../../../../components/bankLogo/BankLogo";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { TranslatedText } from "../../../../../components/TranslatedText";

import "./Account.scss";
import { useWithdrawalPensions } from "../WithdrawalPensionsContext";
import { useLocation } from "react-router";
import { dataLifePensionMove } from "../../../../../data/dataLifePensionMove";
import { InvestmentAccountId } from "../../../../../data/dataAccounts";

const messages = defineMessages({
  externalAccountLabel: {
    id: "sweden.withdrawal-pension.account.label",
  },
  externalAccountRequired: {
    id: "sweden.withdrawal-pension.account.lysa.required",
  },
});

interface Props {
  toNext: (accountId: string) => void;
}

export const Account: FunctionComponent<Props> = ({ toNext }) => {
  const intl = useIntl();
  const formRef = useRef<LysaFormRef>();
  const [externalAccounts, setExternalAccounts] = useState<ExternalAccount[]>();
  const [selectedExternalAccount, setSelectedExternalAccount] =
    useState<ExternalAccount>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [withdrawalPensions, setWithdrawalPensions] = useWithdrawalPensions();

  const location = useLocation();
  const { pathname } = location;
  const pathnameParts = pathname.split("/");
  const accountId = pathnameParts[pathnameParts.length - 2];

  useEffect(() => {
    dataWithdrawals
      .getExternalAccounts()
      .then(setExternalAccounts)
      .catch((error) => {
        console.log(`Could not fetch external accounts. Error: ${error}`);
        throw error;
      });
  }, []);

  if (!externalAccounts) {
    return <Spinner />;
  }

  return (
    <article className="withdrawal-pension-account">
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid) {
            return;
          }
          if (selectedExternalAccount) {
            dataLifePensionMove
              .updateWithdrawalAccount(accountId as InvestmentAccountId, {
                account: selectedExternalAccount.externalBankAccount,
              })
              .then(() => {
                setWithdrawalPensions({
                  externalAccount: selectedExternalAccount,
                });
                toNext(accountId);
              })
              .catch((error) => {
                console.log(
                  `Could not update pension withdrawal account. Error: ${error}`
                );
                throw error;
              });
          }
        }}
      >
        <section>
          <Typography type="h1">
            <TranslatedText id="sweden.withdrawal-pension.account.header" />
          </Typography>
          <Typography type="body">
            <TranslatedText id="sweden.withdrawal-pension.account.ingress" />
          </Typography>
          {externalAccounts.length === 0 ? (
            <Snackbar type={SNACKBAR_TYPES.WARNING} icon textAlign="left">
              <FormattedMessage id="sweden.withdrawal-pension.account.no-accounts" />
            </Snackbar>
          ) : (
            <RadioGroupCard
              alternatives={externalAccounts.map((account) => {
                return {
                  header: account.externalBankAccount,
                  description: (
                    <Typography type="label" component="span">
                      {getBankName(account)}
                    </Typography>
                  ),
                  logo: <BankLogo bank={dataBanks.getBank(account.bank)} />,
                  value: {
                    text: getBankText(account),
                    value: account.externalBankAccount,
                  },
                };
              })}
              legend={intl.formatMessage(messages.externalAccountLabel)}
              onChange={(alt) => {
                setSelectedExternalAccount(
                  externalAccounts.find(
                    (account) => account.externalBankAccount === alt.value
                  )
                );
              }}
              value={
                selectedExternalAccount && {
                  text: getBankText(selectedExternalAccount),
                  value: selectedExternalAccount.externalBankAccount,
                }
              }
              validators={[
                new RequiredValidator(
                  intl.formatMessage(messages.externalAccountRequired)
                ),
              ]}
            />
          )}
        </section>
        <section>
          <Button
            className="next"
            type="submit"
            block
            variant="primary"
            label={intl.formatMessage({
              id: "sweden.withdrawal-pension.account.button",
            })}
          />
        </section>
      </Form>
    </article>
  );
};

import { FormattedMessage } from "react-intl";
import { Typography, Icon, Card } from "@lysaab/ui-2";
import { useAccounts } from "../../../hooks/useAccounts";
import { Amount } from "../../../components/amount/Amount";
import { InvestmentAccount } from "../../../data/dataAccounts";
import { FunctionComponent, useEffect, useState } from "react";
import { getDynamicLink } from "../../../hooks/useCountryUrls";
import { InvestmentAccountId } from "../../../data/dataAccounts";
import { TranslatedText } from "../../../components/TranslatedText";
import { useSafeNavigation } from "../../../hooks/useSafeNavigation";
import { dataLifePensionMove } from "../../../data/dataLifePensionMove";
import { ReactComponent as MonthlyDepositsIcon } from "./monthly-deposits.svg";
import { WITHDRAWAL_PENSIONS_URL } from "../../../countries/sweden/pages/withdrawalPensions/WithdrawalPensionsStory";

import "./PendingPensionWithdrawals.scss";

export const PendingPensionWithdrawals: FunctionComponent = () => {
  const [needUpdateAccountIds, setNeedUpdateAccountIds] = useState<
    InvestmentAccountId[]
  >([]);
  const { accounts } = useAccounts();
  const safeNavigation = useSafeNavigation();

  useEffect(() => {
    dataLifePensionMove
      .getPensionWithdrawalPlanStatus()
      .then((data) => {
        const { needUpdate } = data;
        setNeedUpdateAccountIds(needUpdate);
      })
      .catch((error) => {
        /**
         * Swallow the error. If not it will bubble up and block user. The component is not essential.
         *
         * TODO: Log the error
         */
        console.log(
          `Could not load pension withdrawal plan status. Error: ${error}`
        );
      });
  }, []);

  const needUpdate = needUpdateAccountIds.reduce((acc, accountId) => {
    if (accounts) {
      const account = accounts.investmentAccounts.find(
        (account) => account.accountId === accountId
      );
      if (account) {
        acc.push(account);
      }
    }
    return acc;
  }, [] as InvestmentAccount[]);

  if (needUpdate.length === 0) {
    return null;
  }

  return (
    <div className="pending-pension-withdrawal">
      <Typography type="h2">
        <FormattedMessage
          id="sweden.pending-pension-withdrawal.header"
          defaultMessage="Skapa utbetalningsplan"
        />
      </Typography>
      <ul className="list">
        {needUpdate.map((pension) => {
          return (
            <li key={pension.accountId} className="list-item">
              <button
                onClick={() => {
                  safeNavigation(
                    getDynamicLink(WITHDRAWAL_PENSIONS_URL, {
                      accountId: pension.accountId,
                    })
                  );
                }}
                className="card-button"
              >
                <Card className="card">
                  <div className="start">
                    <div className="dot" />
                    <div className="label">
                      <div>
                        <Typography component="div" className="ellipsis">
                          {pension.name}
                        </Typography>
                      </div>
                      <span className="byline">
                        <MonthlyDepositsIcon className="icon" />
                        <Typography
                          type="body"
                          variant="secondary"
                          component="div"
                          className="ellipsis"
                        >
                          <TranslatedText
                            id="sweden.pending-pension-withdrawal.byline"
                            defaultMessage="Förbered utbetalning"
                          />
                        </Typography>
                      </span>
                    </div>
                  </div>
                  <div className="end">
                    <Amount amount={pension.worth} />
                    <span className="chevron-right">
                      <Icon.ChevronRight />
                    </span>
                  </div>
                </Card>
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

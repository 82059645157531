import React, { useEffect, useState } from "react";
import { defineMessages, MessageDescriptor, useIntl } from "react-intl";
import {
  CardList,
  CircleButton,
  InvestmentType,
  LysaLinkButton,
  RiskIndicator,
  Typography,
} from "@lysaab/ui-2";
import { PensionAdviseAccount } from "../../../../data/dataInvestments";
import { GlidePathGraph } from "../../../../components/glidePathGraph/GlidePathGraph";
import {
  AllocationStage,
  usePensionAllocation,
} from "../../../../hooks/usePensionAllocation";
import { TranslatedText } from "../../../../components/TranslatedText";
import { Modal } from "../../../../components/modal/Modal";
import { CardBottom } from "../../CardBottom";
import { generatePath, Link } from "react-router-dom";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { UPDATE_PENSION_URL } from "../../../updatePensionStory/UpdatePensionStory";
import { InvestmentAccount } from "../../../../data/dataAccounts";
import { HideIfReadOnly } from "../../../../components/hideIfReadOnly/HideIfReadOnly";
import { dataLifePension } from "../../../../data/dataLifePension";

import "./PensionAccountFocus.scss";

interface Props {
  account: InvestmentAccount | undefined;
  adviseAccount: PensionAdviseAccount;
}

export const PensionAccountFocus: React.FC<Props> = ({
  account,
  adviseAccount,
}) => {
  const intl = useIntl();

  const [showModal, setShowModal] = useState<
    "REPAYMENT_PROTECTION" | "DECLARATION"
  >();
  const { allocation } = usePensionAllocation({
    age: adviseAccount.age,
    withdrawalAge: adviseAccount.withdrawalAge,
    withdrawalMonths: adviseAccount.withdrawalMonths,
    takenRiskDeviation: adviseAccount.takenRiskDeviation,
    lifeLong: adviseAccount.lifeLong,
  });
  const [repaymentProtection, setRepaymentProtection] = useState<boolean>();

  useEffect(() => {
    if (account && !account.isSharedAccount) {
      dataLifePension.getPensionDetails(account.accountId).then((response) => {
        setRepaymentProtection(response.repaymentProtection);
      });
    }
  }, [account]);

  if (!account || !allocation) {
    return null;
  }

  const allocationValues = {
    currentTakenRisk: intl.formatNumber(allocation.currentTakenRisk / 100, {
      style: "percent",
    }),
    finalTakenRisk: intl.formatNumber(allocation.finalTakenRisk / 100, {
      style: "percent",
    }),
    link: (text: React.ReactNode[]) => {
      return (
        <LysaLinkButton onClick={() => setShowModal("DECLARATION")}>
          {text}
        </LysaLinkButton>
      );
    },
  };

  return (
    <section className="PensionAccountFocus account-page-pension-account-focus">
      <Typography type="h2">
        <TranslatedText id="account.pension-account-focus.header" />
      </Typography>

      <CardList>
        <div className="account-page-card-body">
          <Typography type="label">
            <TranslatedText id="account.pension-account-focus.allocation.header" />
          </Typography>

          <Typography>
            {allocation.allocationStage === AllocationStage.GROWTH ? (
              <TranslatedText
                id="account.pension-account-focus.allocation.growth-stage"
                values={allocationValues}
              />
            ) : (
              <TranslatedText
                id="account.pension-account-focus.allocation.rebalance-stage"
                values={allocationValues}
              />
            )}
          </Typography>

          <GlidePathGraph
            glidePath={allocation.glidePath}
            reallocationAge={allocation.reallocationAge}
            withdrawalAge={allocation.withdrawalAge}
          />

          <div className="PensionAccountFocus__info-card">
            <dl>
              <Typography component="dt">
                <TranslatedText id="account.pension-account-focus.withdrawalPlan.title" />
              </Typography>
              <Typography component="dd">
                {adviseAccount.lifeLong ? (
                  <TranslatedText
                    id="account.pension-account-focus.withdrawalPlan.lifeLong"
                    values={{ age: adviseAccount.withdrawalAge }}
                  />
                ) : (
                  <TranslatedText
                    id="account.pension-account-focus.withdrawalPlan.duration"
                    values={{
                      startAge: adviseAccount.withdrawalAge,
                      finalAge:
                        adviseAccount.withdrawalAge +
                        adviseAccount.withdrawalMonths / 12,
                      duration: adviseAccount.withdrawalMonths / 12,
                    }}
                  />
                )}
              </Typography>
            </dl>

            {repaymentProtection !== undefined && (
              <dl>
                <dt className="repayment-information-dt">
                  <Typography component="span">
                    <TranslatedText id="account.pension-account-focus.repaymentCover.title" />
                  </Typography>
                  <CircleButton
                    className="repayment-information-button"
                    icon="InformationOutline"
                    onClick={() => {
                      setShowModal("REPAYMENT_PROTECTION");
                    }}
                  />
                </dt>
                <Typography component="dd">
                  {repaymentProtection ? (
                    <TranslatedText id="account.pension-account-focus.repaymentCover.yes" />
                  ) : (
                    <TranslatedText id="account.pension-account-focus.repaymentCover.no" />
                  )}
                </Typography>
              </dl>
            )}
          </div>

          <dl>
            <Typography component="dt">
              <TranslatedText id="account.pension-account-focus.type" />
            </Typography>
            <Typography component="dd" data-test-id="account-investment-type">
              {intl.formatMessage(
                investmentTypeMessages[adviseAccount.investmentType]
              )}
              <RiskIndicator
                size={20}
                risk={adviseAccount.takenRisk}
                investmentType={adviseAccount.investmentType}
              />
            </Typography>
          </dl>
        </div>
        <HideIfReadOnly>
          {!account.isSharedAccount && (
            <CardBottom>
              <Link
                to={{
                  pathname: generatePath(getNavLink(UPDATE_PENSION_URL), {
                    accountId: account.accountId,
                  }),
                }}
              >
                <TranslatedText id="account.pension-account-focus.cta.update-focus" />
              </Link>
            </CardBottom>
          )}
        </HideIfReadOnly>
      </CardList>

      <Modal
        header={intl.formatMessage({
          id: "account.pension-account-focus.declaration-modal.header",
        })}
        showModal={showModal === "DECLARATION"}
        onClose={() => setShowModal(undefined)}
        closeOnOverlayClick
      >
        <Typography>{adviseAccount.declaration}</Typography>
      </Modal>

      <Modal
        header={intl.formatMessage({
          id: "account.pension-account-focus.repayment.modal.header",
        })}
        showModal={showModal === "REPAYMENT_PROTECTION"}
        onClose={() => setShowModal(undefined)}
        closeOnOverlayClick
      >
        <Typography>
          <TranslatedText id="account.pension-account-focus.repayment.modal.body" />
        </Typography>
      </Modal>
    </section>
  );
};

const investmentTypeMessages = defineMessages<
  InvestmentType,
  MessageDescriptor,
  Record<InvestmentType, MessageDescriptor>
>({
  [InvestmentType.BROAD]: {
    id: "account.pension-account-focus.type.BROAD",
  },
  [InvestmentType.SUSTAINABLE]: {
    id: "account.pension-account-focus.type.SUSTAINABLE",
  },
});

import { Story } from "@lysaab/ui-2";
import { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import { useStoryValues } from "../../hooks/useStoryValues";
import { Switch } from "../../components/route/Switch";
import {
  generatePath,
  Redirect,
  Route,
  useHistory,
  useLocation,
  useParams,
} from "react-router";
import { getNavLink } from "../../hooks/useCountryUrls";
import { ACCOUNT_PAGE_URL } from "../account/AccountPage";
import { useSafeNavigation } from "../../hooks/useSafeNavigation";
import { useAdviseAccount } from "../../hooks/useAdviseAccount";
import { InvestmentAccountId } from "../../data/dataAccounts";
import {
  dataInvestments,
  isPensionAdviseAccount,
  SustainabilityImportance,
  SustainabilityPreference,
} from "../../data/dataInvestments";
import {
  UpdateSustainabilityPreferenceLocationState,
  UpdateSustainabilityPreferenceWrapper,
} from "./updateSustainabilityPreferenceWrapper/UpdateSustainabilityPreferenceWrapper";
import { UpdateSustainabilityWrapper } from "./updateSustainabilityWrapper/UpdateSustainabilityWrapper";
import {
  UpdateSustainabilityEsgLocationState,
  UpdateSustainabilityEsgWrapper,
} from "./updateSustainabilityEsgWrapper/UpdateSustainabilityEsgWrapper";
import {
  VerifyUpdate,
  VerifyUpdateLocationState,
} from "./verifyUpdate/VerifyUpdate";
import {
  ConfirmEsgUpdateLocationState,
  ConfirmEsgUpdateWrapper,
} from "./confirmEsgUpdateWrapper/ConfirmEsgUpdateWrapper";
import { UpdateRepaymentProtectionWrapper } from "./updateRepaymentProtectionWrapper/UpdateRepaymentProtectionWrapper";
import { UpdateWithdrawalPlanWrapper } from "./updateWithdrawalPlanWrapper/UpdateWithdrawalPlanWrapper";
import { UpdateTakenRiskDeviationWrapper } from "./updateTakenRiskDeviationWrapper/UpdateTakenRiskDeviationWrapper";
import { UpdateOptionsWrapper } from "./updateOptionsWrapper/UpdateOptionsWrapper";
import {
  SignRepaymentUpdate,
  SignRepaymentUpdateLocationState,
} from "./signRepaymentUpdate/SignRepaymentUpdate";
import { PageStripped } from "../PageStripped";

export const UPDATE_PENSION_URL = "/update-pension/:accountId";

// TODO: <Route> from react-router or components?
// TODO: Should be localized, Sustainability? and BankID are Sweden specific.

export interface Params {
  accountId: InvestmentAccountId;
}

const BASE_ROUTES = {
  SETTINGS: `${UPDATE_PENSION_URL}`,
  SUSTAINABILITY: `${UPDATE_PENSION_URL}/sustainability`,
  SUSTAINABILITY_PREFERENCE: `${UPDATE_PENSION_URL}/sustainability-preference`,
  SUSTAINABILITY_ESG: `${UPDATE_PENSION_URL}/sustainability-esg`,
  CONFIRM_ESG_UPDATE: `${UPDATE_PENSION_URL}/comfirm-esg-update`,
  TAKEN_RISK_DEVIATION: `${UPDATE_PENSION_URL}/taken-risk-deviation`,
  WITHDRAWAL_PLAN: `${UPDATE_PENSION_URL}/withdrawal-plan`,
  REPAYMENT_PROTECTION: `${UPDATE_PENSION_URL}/repayment-protection`,
  SIGN_REPAYMENT_UPDATE: `${UPDATE_PENSION_URL}/sign-repayment-update`,
  VERIFY_UPDATES: `${UPDATE_PENSION_URL}/verify-updates`,
};

export const UpdatePensionStory: FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const safeNavigation = useSafeNavigation();

  const { accountId } = useParams<Params>();
  const [advise, loadAdviseData] = useAdviseAccount(accountId);

  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);

  const navigateStory = useCallback(
    <T,>(key: keyof typeof ROUTES, state?: T) => {
      safeNavigation({
        pathname: generatePath(ROUTES[key], { accountId }),
        state,
      });
    },
    [ROUTES, accountId, safeNavigation]
  );

  if (!isPensionAdviseAccount(advise)) {
    return null;
  }

  return (
    <PageStripped>
      <Story
        transitionKey={currentIndex.toString()}
        ariaLabelProgress={() =>
          intl.formatMessage(
            { id: "updatePensionStory.ariaProgressLabel" },
            {
              current: currentIndex,
              total: storyLength,
            }
          )
        }
        header={intl.formatMessage({ id: "updatePensionStory.header" })}
        showBack={currentIndex > 0}
        showClose={true}
        progress={storyProgress}
        onBack={() => {
          history.goBack();
        }}
        onExit={() => {
          safeNavigation(
            generatePath(getNavLink(ACCOUNT_PAGE_URL), { accountId })
          );
        }}
      >
        <Switch
          location={location}
          {...{
            order: currentIndex,
          }}
        >
          <Route exact path={ROUTES.SETTINGS}>
            <UpdateOptionsWrapper
              advise={advise}
              toSustainability={() => {
                navigateStory("SUSTAINABILITY");
              }}
              toTakenRiskDeviation={() => {
                navigateStory("TAKEN_RISK_DEVIATION");
              }}
              toWithdrawalPlan={() => {
                navigateStory("WITHDRAWAL_PLAN");
              }}
              toRepaymentProtection={() => {
                navigateStory("REPAYMENT_PROTECTION");
              }}
              onClose={() => {
                safeNavigation(
                  generatePath(getNavLink(ACCOUNT_PAGE_URL), { accountId })
                );
              }}
            />
          </Route>

          <Route exact path={ROUTES.SUSTAINABILITY}>
            <UpdateSustainabilityWrapper
              advise={advise}
              onNext={(sustainability) => {
                if (sustainability === SustainabilityImportance.IMPORTANT) {
                  navigateStory<UpdateSustainabilityPreferenceLocationState>(
                    "SUSTAINABILITY_PREFERENCE",
                    { sustainability }
                  );
                } else {
                  navigateStory<VerifyUpdateLocationState>("VERIFY_UPDATES", {
                    type: "UPDATE_SUSTAINABILITY",
                    value: { sustainability },
                  });
                }
              }}
            />
          </Route>

          <Route exact path={ROUTES.SUSTAINABILITY_PREFERENCE}>
            <LocationStateGuard
              render={(
                locationState: UpdateSustainabilityPreferenceLocationState
              ) => (
                <UpdateSustainabilityPreferenceWrapper
                  locationState={locationState}
                  advise={advise}
                  onNext={(sustainabilityPreference, locationState) => {
                    if (
                      sustainabilityPreference ===
                      SustainabilityPreference.SPECIFIC
                    ) {
                      navigateStory<UpdateSustainabilityEsgLocationState>(
                        "SUSTAINABILITY_ESG",
                        { ...locationState, sustainabilityPreference }
                      );
                    } else {
                      navigateStory<VerifyUpdateLocationState>(
                        "VERIFY_UPDATES",
                        {
                          type: "UPDATE_SUSTAINABILITY",
                          value: { ...locationState, sustainabilityPreference },
                        }
                      );
                    }
                  }}
                />
              )}
            />
          </Route>

          <Route exact path={ROUTES.SUSTAINABILITY_ESG}>
            <LocationStateGuard
              render={(locationState: UpdateSustainabilityEsgLocationState) => (
                <UpdateSustainabilityEsgWrapper
                  locationState={locationState}
                  advise={advise}
                  onNext={(esgQuestions, locationState) => {
                    const sustainabilityQuestions = {
                      ...esgQuestions,
                      ...locationState,
                    };

                    dataInvestments
                      .recalculatePensionSustainability(sustainabilityQuestions)
                      .then(({ esgResult: { esgAnswers, esgBestMatch } }) => {
                        if (esgBestMatch !== undefined) {
                          navigateStory<ConfirmEsgUpdateLocationState>(
                            "CONFIRM_ESG_UPDATE",
                            {
                              esgAnswers,
                              esgBestMatch,
                              sustainabilityQuestions,
                            }
                          );
                        } else {
                          navigateStory<VerifyUpdateLocationState>(
                            "VERIFY_UPDATES",
                            {
                              type: "UPDATE_SUSTAINABILITY",
                              value: sustainabilityQuestions,
                            }
                          );
                        }
                      });
                  }}
                />
              )}
            />
          </Route>

          <Route exact path={ROUTES.CONFIRM_ESG_UPDATE}>
            <LocationStateGuard
              render={(locationState: ConfirmEsgUpdateLocationState) => (
                <ConfirmEsgUpdateWrapper
                  locationState={locationState}
                  onNext={(value) => {
                    navigateStory<VerifyUpdateLocationState>("VERIFY_UPDATES", {
                      type: "UPDATE_SUSTAINABILITY",
                      value,
                    });
                  }}
                />
              )}
            />
          </Route>

          <Route exact path={ROUTES.TAKEN_RISK_DEVIATION}>
            <UpdateTakenRiskDeviationWrapper
              advise={advise}
              onNext={(takenRiskDeviation) => {
                navigateStory<VerifyUpdateLocationState>("VERIFY_UPDATES", {
                  type: "UPDATE_RISK_DEVIATION",
                  value: { takenRiskDeviation },
                });
              }}
            />
          </Route>

          <Route exact path={ROUTES.WITHDRAWAL_PLAN}>
            <UpdateWithdrawalPlanWrapper
              advise={advise}
              toRepaymentProtection={() => {
                navigateStory("REPAYMENT_PROTECTION");
              }}
              onNext={(value) => {
                navigateStory<VerifyUpdateLocationState>("VERIFY_UPDATES", {
                  type: "UPDATE_WITHDRAWAL_PLAN",
                  value,
                });
              }}
            />
          </Route>

          <Route exact path={ROUTES.REPAYMENT_PROTECTION}>
            <UpdateRepaymentProtectionWrapper
              advise={advise}
              onNext={(beneficiary) => {
                navigateStory<SignRepaymentUpdateLocationState>(
                  "SIGN_REPAYMENT_UPDATE",
                  { beneficiary }
                );
              }}
            />
          </Route>

          <Route exact path={ROUTES.SIGN_REPAYMENT_UPDATE}>
            <LocationStateGuard
              render={(locationState: SignRepaymentUpdateLocationState) => (
                <SignRepaymentUpdate
                  locationState={locationState}
                  onSave={() => {
                    navigateStory("SETTINGS");
                  }}
                />
              )}
            />
          </Route>

          <Route exact path={ROUTES.VERIFY_UPDATES}>
            <LocationStateGuard
              render={(locationState: VerifyUpdateLocationState) => (
                <VerifyUpdate
                  locationState={locationState}
                  advise={advise}
                  onSave={() => {
                    loadAdviseData();
                    navigateStory("SETTINGS");
                  }}
                />
              )}
            />
          </Route>
        </Switch>
      </Story>
    </PageStripped>
  );
};

function LocationStateGuard<T>({
  render,
}: {
  render: (state: T) => JSX.Element;
}) {
  const locationState = useLocation<T | undefined>().state;
  const { accountId } = useParams<{ accountId: InvestmentAccountId }>();

  if (!locationState) {
    return (
      <Redirect
        to={generatePath(getNavLink(UPDATE_PENSION_URL), { accountId })}
      />
    );
  }

  return render(locationState);
}

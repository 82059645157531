import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { LysaCountry } from "@lysaab/shared";
import { DocModalLink, Typography } from "@lysaab/ui-2";
import {
  getLatestDocuments,
  DocumentInformation,
  dataDocuments,
} from "../../../../../data/dataDocuments";
import { UserState } from "../../../../../context/UserContext";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { getInvestmentTypeTextFromSustainabilityImportance } from "../../../../../texts/investmentTypeTexts";
import { SustainabilityImportance } from "../../../../../data/dataInvestments";

const guideLinesDoc: Record<
  LysaCountry,
  (information: DocumentInformation[]) => string[]
> = {
  [LysaCountry.DENMARK]: () => [],
  [LysaCountry.FINLAND]: () => [],
  [LysaCountry.GERMANY]: () => [],
  [LysaCountry.SPAIN]: () => [],
  [LysaCountry.SWEDEN]: (information) =>
    getLatestDocuments(information, [
      "legal/se/sv/placeringsriktlinjer-pensionsforsakring.md",
    ]),
};

interface Props {
  user: UserState;
  currentTakenRisk: number;
  sustainability: SustainabilityImportance;
}

export const GuideLinesText: FC<Props> = ({
  user: { name, tin, country },
  currentTakenRisk,
  sustainability,
}) => {
  const intl = useIntl();
  const [documents, setDocuments] = useState<DocumentInformation[]>();

  useEffect(() => {
    dataDocuments.getDocumentsInformation(true).then(setDocuments);
  }, []);

  if (!documents || !country) {
    return null;
  }

  return (
    <Typography>
      <TranslatedText
        id="updatePensionStory.verifyUpdate.components.guideLinesText.text"
        values={{
          link: (linkText) => {
            return (
              <DocModalLink
                document={guideLinesDoc[country](documents)[0]}
                modalAnnouncement={intl.formatMessage({
                  id: "updatePensionStory.verifyUpdate.components.guideLinesText.modal-announcement",
                })}
                variableValues={{
                  SIGNING_DATE: intl.formatDate(new Date()),
                  CUSTOMER_NAME: name ?? "",
                  CUSTOMER_ID: tin ?? "",
                  INVESTMENT_TYPE:
                    getInvestmentTypeTextFromSustainabilityImportance(
                      intl,
                      sustainability
                    ),
                  STOCKS: intl.formatNumber(currentTakenRisk / 100, {
                    style: "percent",
                  }),
                  BONDS: intl.formatNumber((100 - currentTakenRisk) / 100, {
                    style: "percent",
                  }),
                }}
              >
                {linkText}
              </DocModalLink>
            );
          },
        }}
      />
    </Typography>
  );
};

import { FunctionComponent, useEffect, useState } from "react";
import {
  Alternative,
  Button,
  CircleButton,
  NewIcon,
  Select,
  SelectableCard,
  Snackbar,
  SNACKBAR_TYPES,
  Typography,
} from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { defineMessages, useIntl } from "react-intl";
import {
  DEFAULT_PAYOUT_AGE,
  DEFAULT_WITHDRAWAL_MONTHS,
  useTransfer,
} from "../TransferContext";
import { useHistory } from "react-router";
import { useUser } from "../../../../../context/UserContext";
import { getUserAge } from "../utils/userAge";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { TRANSFER_PENSIONS_URL } from "../TransferPensionsStory";
import { GlidePathGraph } from "../../../../../components/glidePathGraph/GlidePathGraph";
import { usePensionAllocation } from "../../../../../hooks/usePensionAllocation";

import "./WithdrawalPlan.scss";
import { Modal } from "../../../../../components/modal/Modal";

const messages = defineMessages({
  customWithdrawalPeriodPreference: {
    id: "sweden.transfer-pension.withdrawal-plan.withdrawal-period.alternative",
  },
  customWithdrawalPeriodDefaultPreference: {
    id: "sweden.transfer-pension.withdrawal-plan.withdrawal-period.default-alternative",
  },
  lifeLong: {
    id: "sweden.transfer-pension.withdrawal-plan.withdrawal-period.alternative.life-long",
  },
  customWithdrawalAgePreference: {
    id: "sweden.transfer-pension.withdrawal-plan.withdrawal-age.alternative",
  },
  customWithdrawalAgeDefaultPreference: {
    id: "sweden.transfer-pension.withdrawal-plan.withdrawal-age.default-alternative",
  },
});

export const LIFE_LONG_ALTERNATIVE_VALUE = -1;
export const SHORTEST_WITHDRAWAL_PERIOD = 5;
export const LONGEST_WITHDRAWAL_PERIOD = 40;
export const UP_TO_MAX_AGE = 100;

interface Props {
  toRepayment: () => void;
}

export const WithdrawalPlan: FunctionComponent<Props> = ({ toRepayment }) => {
  const intl = useIntl();
  const [transfer, setTransfer] = useTransfer();
  const history = useHistory();
  const user = useUser();
  const age = getUserAge(user.tin);
  const { allocation } = usePensionAllocation({ ...transfer, age });
  const [showWithdrawalPeriodInformation, setShowWithdrawalPeriodInformation] =
    useState(false);

  /**
   * Safe guard in case we loose context. On refresh etc. Send user back to start of story.
   */
  useEffect(() => {
    if (typeof transfer?.moves[0]?.institute === "undefined") {
      history.replace(getNavLink(TRANSFER_PENSIONS_URL));
    }
  }, [history, transfer?.moves]);

  if (!age) {
    return null;
  }

  /**
   * It should be possible to select a withdrawal period ranging from 5 years up to 40 years, but with a maximum limit of reaching the age of 100.
   * For example, if the customer is 60 years old, they can choose a 40-year withdrawal period;
   * if they are 70, the maximum is 30 years;
   * if they are 80, the maximum is 20 years, and so on.
   */

  const selectedWithdrawalAge = transfer.withdrawalAge;
  const maxWithdrawalPeriod = Math.min(
    UP_TO_MAX_AGE - selectedWithdrawalAge,
    LONGEST_WITHDRAWAL_PERIOD
  );

  const withdrawalPeriodAlternatives = generateYearArray(
    SHORTEST_WITHDRAWAL_PERIOD,
    maxWithdrawalPeriod
  ).map(
    (year): Alternative<number> => ({
      text: intl.formatMessage(
        year === DEFAULT_WITHDRAWAL_MONTHS / 12
          ? messages.customWithdrawalPeriodDefaultPreference
          : messages.customWithdrawalPeriodPreference,
        {
          years: year,
        }
      ),
      value: year,
    })
  );

  const warning = transfer.repayment === true;

  withdrawalPeriodAlternatives.push({
    text: intl.formatMessage(messages.lifeLong),
    value: LIFE_LONG_ALTERNATIVE_VALUE,
    disabled: warning ? true : false,
  });

  const withdrawalAgeEnd = Math.min(100 - transfer.withdrawalMonths / 12, 80);

  const withdrawalAgeAlternatives = generateYearArray(
    Math.max(age, 55),
    withdrawalAgeEnd
  ).map(
    (age): Alternative<number> => ({
      text: intl.formatMessage(
        age === DEFAULT_PAYOUT_AGE
          ? messages.customWithdrawalAgeDefaultPreference
          : messages.customWithdrawalAgePreference,
        {
          age: age,
        }
      ),
      value: age,
    })
  );

  return (
    <article className="transfer-pension-story-withdrawal-plan">
      <Typography type="h3" className="header">
        <TranslatedText id="sweden.transfer-pension.withdrawal-plan.header" />
      </Typography>
      <section className="glide-path">
        {allocation && (
          <GlidePathGraph
            glidePath={allocation.glidePath}
            reallocationAge={allocation.reallocationAge}
            withdrawalAge={allocation.withdrawalAge}
          />
        )}
      </section>
      <Typography type="body">
        <TranslatedText id="sweden.transfer-pension.withdrawal-plan.body" />
      </Typography>
      <Typography type="h4" className="heading">
        <TranslatedText id="sweden.transfer-pension.withdrawal-age.heading" />
      </Typography>
      <Select
        alternatives={withdrawalAgeAlternatives}
        label=" "
        placeholder={intl.formatMessage({
          id: "sweden.transfer-pension.withdrawal-age.select.placeholder",
        })}
        value={withdrawalAgeAlternatives.find(
          (alternative) => alternative.value === transfer.withdrawalAge
        )}
        onChange={(newValue) => setTransfer({ withdrawalAge: newValue.value })}
      />
      <Typography type="h4" className="heading withdrawal-heading">
        <TranslatedText id="sweden.transfer-pension.withdrawal-period.heading" />
        <CircleButton
          className="withdrawal-information-button"
          icon="InformationOutline"
          onClick={() => {
            setShowWithdrawalPeriodInformation(true);
          }}
        />
      </Typography>
      {showWithdrawalPeriodInformation && (
        <Modal
          header={intl.formatMessage({
            id: "sweden.transfer-pension.withdrawal-period.information.modal.heading",
          })}
          showModal={!!showWithdrawalPeriodInformation}
          onClose={() => setShowWithdrawalPeriodInformation(false)}
          closeOnOverlayClick
        >
          <Typography type="body">
            <TranslatedText id="sweden.transfer-pension.withdrawal-period.information.modal.body.one" />
          </Typography>
          <Typography type="body">
            <TranslatedText id="sweden.transfer-pension.withdrawal-period.information.modal.body.two" />
          </Typography>
        </Modal>
      )}
      <Select
        alternatives={withdrawalPeriodAlternatives}
        label=" "
        placeholder={intl.formatMessage({
          id: "sweden.transfer-pension.advice.payout-period.select.placeholder",
        })}
        value={withdrawalPeriodAlternatives.find(
          (alternative) => alternative.value === transfer.withdrawalMonths / 12
        )}
        onChange={(newValue) =>
          setTransfer({
            withdrawalMonths: newValue.value * 12,
            lifeLong:
              newValue.value === LIFE_LONG_ALTERNATIVE_VALUE ? true : false,
          })
        }
      />
      <SelectableCard
        type="button"
        className="selectable-card repayment"
        onClick={toRepayment}
      >
        <header className="selectable-card-header">
          <section className="start">
            <Typography type="label" className="label">
              <TranslatedText
                id={"sweden.transfer-pension.pension-settings.repayment.label"}
              />
            </Typography>
          </section>
          <section className="end">
            {`${
              transfer.repayment
                ? intl.formatMessage({
                    id: "sweden.transfer-pension.pension-settings.repayment.yes",
                  })
                : intl.formatMessage({
                    id: "sweden.transfer-pension.pension-settings.repayment.no",
                  })
            }`}
            <NewIcon.ChevronRight />
          </section>
        </header>
      </SelectableCard>
      {warning && (
        <Snackbar type={SNACKBAR_TYPES.INFO} icon textAlign="left">
          <TranslatedText id="sweden.transfer-pension.withdrawal-period.life-long-and-repayment.snackbar" />
        </Snackbar>
      )}
      <Button
        className="save-button"
        block
        type="button"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.withdrawal-period.save",
        })}
        onClick={history.goBack}
      />
    </article>
  );
};

function generateYearArray(start: number, end: number) {
  const years = [];
  for (let i = start; i <= end; i++) {
    years.push(i);
  }
  return years;
}

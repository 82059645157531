import { FunctionComponent, ReactNode } from "react";
import { DocModalLink, LysaLink } from "@lysaab/ui-2";
import { PersonalSecurity } from "./PersonalSecurity";
import { FormattedMessage, useIntl } from "react-intl";

import "./Footer.scss";

interface Props {
  children?: ReactNode;
}

export const Footer: FunctionComponent<Props> = ({ children }) => {
  const intl = useIntl();

  return (
    <footer className="transfer-pension-story-footer">
      <hr className="hr" />
      {children}
      <address className="address">
        <FormattedMessage
          id="sweden.transfer-pension.footer.contact"
          values={{
            break: () => <br />,
            phone: () => {
              const phoneNumber = intl.formatMessage({
                id: "contact.phone.se",
              });
              return (
                <LysaLink href={`tel:${phoneNumber.replace(/\s+/g, "")}`}>
                  {phoneNumber}
                </LysaLink>
              );
            },
            email: () => {
              const email = intl.formatMessage({ id: "contact.email.se" });
              return <LysaLink href={`mailto:${email}`}>{email}</LysaLink>;
            },
          }}
        />
      </address>
      <div className="privacy-policy">
        <PersonalSecurity primaryColor="var(--lysa-text-secondary)" />
        <FormattedMessage
          id="sweden.transfer-pension.footer.privacy-policy"
          values={{
            a: (linkText) => {
              return (
                <DocModalLink
                  document="legal/se/sv/personuppgiftspolicy.md"
                  modalAnnouncement={intl.formatMessage({
                    id: "sweden.transfer-pension.footer.privacy-policy.modal-announcement",
                  })}
                  key="element-key-pp"
                >
                  {linkText}
                </DocModalLink>
              );
            },
          }}
        />
      </div>
    </footer>
  );
};

import type { FunctionComponent, ReactNode } from "react";
import { useCallback, useEffect, useState } from "react";
import { useAccounts } from "../../../hooks/useAccounts";
import {
  ExternalPendingSavingsWithdrawalResponse,
  Withdrawal,
  dataWithdrawals,
} from "../../../data/dataWithdrawals";
import { DateTime } from "luxon";
import {
  AccountType,
  AllAccountResponse,
  InvestmentAccount,
  SavingsAccount,
} from "../../../data/dataAccounts";
import { Spinner, Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../../components/TranslatedText";
import { CardButton } from "./CardButton";
import { FormattedDate, FormattedTime, useIntl } from "react-intl";
import { useCurrency } from "../../../context/LocalizationContext";
import { Modal } from "../../../components/modal/Modal";
import { banks, dataBanks } from "../../../data/dataBanks";
import { CancelPendingInvestmentWithdrawal } from "./CancelPendingInvestmentWithdrawal";
import { CancelPendingSavingsWithdrawal } from "./CancelPendingSavingsWithdrawal";

const excludePendingKFWithdrawals = (
  pendingWithdrawals: Withdrawal[],
  accounts: InvestmentAccount[]
) =>
  pendingWithdrawals.filter((pendingItem) => {
    const account = accounts.find(
      (accountItem) => accountItem.accountId === pendingItem.accountId
    );
    return account?.type !== AccountType.DANICA_KF;
  });

const sortWithdrawals = (
  withdrawalA: { requested: string },
  withdrawalB: { requested: string }
) =>
  DateTime.fromISO(withdrawalA.requested)
    .diff(DateTime.fromISO(withdrawalB.requested))
    .toMillis();

interface MergedInvestmentWithdrawal {
  requested: string;
  withdrawalType: "INVESTMENT";
  withdrawal: Withdrawal;
  account: InvestmentAccount;
}

interface MergedSavingsWithdrawal {
  requested: string;
  withdrawalType: "SAVINGS";
  withdrawal: ExternalPendingSavingsWithdrawalResponse;
  account: SavingsAccount;
}

type MergedWithdrawal = MergedInvestmentWithdrawal | MergedSavingsWithdrawal;

const isInvestmentWithdrawal = (
  withdrawal: MergedWithdrawal
): withdrawal is MergedInvestmentWithdrawal =>
  withdrawal.withdrawalType === "INVESTMENT";

const isSavingsWithdrawal = (
  withdrawal: MergedWithdrawal
): withdrawal is MergedSavingsWithdrawal =>
  withdrawal.withdrawalType === "SAVINGS";

const mergeWithdrawals = (
  investmentWithdrawals: Withdrawal[],
  savingsWithdrawals: ExternalPendingSavingsWithdrawalResponse[],
  accounts: AllAccountResponse
): MergedWithdrawal[] => {
  const investments: MergedInvestmentWithdrawal[] = [];
  investmentWithdrawals.forEach((withdrawal) => {
    const account = accounts.investmentAccounts.find(
      (account) => account.accountId === withdrawal.accountId
    );
    if (account) {
      investments.push({
        requested: withdrawal.requested,
        withdrawalType: "INVESTMENT",
        withdrawal,
        account,
      });
    }
  });

  const savings: MergedSavingsWithdrawal[] = [];
  savingsWithdrawals.forEach((withdrawal) => {
    const account = accounts.savingsAccounts.find(
      (account) => account.accountId === withdrawal.accountId
    );
    if (account) {
      savings.push({
        requested: withdrawal.requested,
        withdrawalType: "SAVINGS",
        withdrawal,
        account,
      });
    }
  });

  return [...investments, ...savings].sort(sortWithdrawals);
};

// FIXME: Add KF withdrawals
export const PendingWithdrawals: FunctionComponent = () => {
  const intl = useIntl();
  const currency = useCurrency();
  const { accounts } = useAccounts();
  const [pendingWithdrawals, setPendingWithdrawals] = useState<Withdrawal[]>(
    []
  );
  const [
    pendingSavingsAccountWithdrawals,
    setPendingSavingsAccountWithdrawals,
  ] = useState<ExternalPendingSavingsWithdrawalResponse[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [focusedWithdrawal, setFocusedWithdrawal] =
    useState<MergedWithdrawal>();

  const loadPendingAccountWithdrawals = useCallback(() => {
    Promise.all([
      dataWithdrawals.getPendingWithdrawals(),
      dataWithdrawals.getPendingSavingsAccountWithdrawals(),
    ]).then(([pendingWithdrawals, pendingSavingsWithdrawals]) => {
      setPendingWithdrawals(pendingWithdrawals);
      setPendingSavingsAccountWithdrawals(pendingSavingsWithdrawals);
    });
  }, []);

  useEffect(() => {
    loadPendingAccountWithdrawals();
  }, [loadPendingAccountWithdrawals]);

  if (
    typeof accounts === "undefined" ||
    (pendingWithdrawals.length === 0 &&
      pendingSavingsAccountWithdrawals.length === 0)
  ) {
    return null;
  }

  // Yes, I know, KF logic in a generic component. Both
  // /withdrawal/pending and /danica/withdrawal/pending might
  // contain the same withdrawal. Unfortunately we'll need to
  // display the duplicates from the /danica/withdrawal/pending
  // - it has the information we need.
  const filteredPendingWithdrawals = excludePendingKFWithdrawals(
    pendingWithdrawals,
    accounts.investmentAccounts
  );
  const withdrawals = mergeWithdrawals(
    filteredPendingWithdrawals,
    pendingSavingsAccountWithdrawals,
    accounts
  );

  return (
    <div className="overview-pending">
      <div>
        <Typography type="h2">
          <TranslatedText id="pending-withdrawals.header" />
        </Typography>
        <div className="list">
          {withdrawals.map((withdrawal) => {
            if (isInvestmentWithdrawal(withdrawal)) {
              return (
                <CardButton
                  key={
                    withdrawal.withdrawal.accountId +
                    withdrawal.withdrawal.externalBankAccount +
                    withdrawal.withdrawal.requested
                  }
                  onClick={() => {
                    setFocusedWithdrawal({
                      requested: withdrawal.requested,
                      withdrawalType: "INVESTMENT",
                      withdrawal: withdrawal.withdrawal,
                      account: withdrawal.account,
                    });
                    setShowModal(true);
                  }}
                  text={withdrawal.account.name}
                  secondaryText={intl.formatNumber(
                    withdrawal.withdrawal.drain
                      ? -withdrawal.account.worth
                      : -withdrawal.withdrawal.amount,
                    {
                      currency,
                      signDisplay: "exceptZero",
                      style: "currency",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                />
              );
            }
            if (isSavingsWithdrawal(withdrawal)) {
              return (
                <CardButton
                  key={
                    withdrawal.withdrawal.accountId +
                    withdrawal.withdrawal.externalBankAccount +
                    withdrawal.withdrawal.requested
                  }
                  onClick={() => {
                    setFocusedWithdrawal({
                      requested: withdrawal.requested,
                      withdrawalType: "SAVINGS",
                      withdrawal: withdrawal.withdrawal,
                      account: withdrawal.account,
                    });
                    setShowModal(true);
                  }}
                  text={withdrawal.account.name}
                  secondaryText={intl.formatNumber(
                    -withdrawal.withdrawal.amount,
                    {
                      currency,
                      signDisplay: "exceptZero",
                      style: "currency",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                />
              );
            }
            return null;
          })}
        </div>
      </div>

      <Modal
        header={intl.formatMessage({ id: "pending-withdrawals.modal.header" })}
        showModal={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        closeOnOverlayClick
        className="overview-pending-modal"
      >
        {focusedWithdrawal ? (
          focusedWithdrawal.withdrawalType === "INVESTMENT" ? (
            <PendingInvestmentWithdrawalModal
              withdrawal={focusedWithdrawal.withdrawal}
              account={focusedWithdrawal.account}
              onWithdrawalCancelled={loadPendingAccountWithdrawals}
            />
          ) : (
            <PendingSavingsWithdrawalModal
              withdrawal={focusedWithdrawal.withdrawal}
              account={focusedWithdrawal.account}
              onWithdrawalCancelled={loadPendingAccountWithdrawals}
            />
          )
        ) : (
          <Spinner />
        )}
      </Modal>
    </div>
  );
};

interface PendingWithdrawalModalProps {
  amount: number;
  fromAccountName: string;
  toAccountName: string;
  bankId: keyof typeof banks;
  requestedAt: string;
  renderCancelWithdrawal: () => ReactNode;
}
const PendingWithdrawalModal: FunctionComponent<
  PendingWithdrawalModalProps
> = ({
  amount,
  fromAccountName,
  toAccountName,
  bankId,
  requestedAt,
  renderCancelWithdrawal,
}) => {
  const intl = useIntl();
  const currency = useCurrency();
  const bank = dataBanks.getBank(bankId);
  const bankName = bank.short !== "Unknown" ? bank.long : null;

  return (
    <div className="modal-content">
      <div className="row">
        <Typography type="label">
          <TranslatedText id="pending-withdrawals.amount" />
        </Typography>
        <Typography className="text" component="span">
          {intl.formatNumber(-amount, {
            currency,
            signDisplay: "exceptZero",
            style: "currency",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </div>

      <div className="row">
        <Typography type="label">
          <TranslatedText id="pending-withdrawals.fromAccount" />
        </Typography>
        <Typography className="text" component="span">
          {fromAccountName}
        </Typography>
      </div>

      <div className="row">
        <Typography type="label">
          <TranslatedText id="pending-withdrawals.toAccount" />
        </Typography>
        <Typography className="text" component="span">
          {toAccountName}
          {bankName && `, ${bankName}`}
        </Typography>
      </div>

      <div className="row">
        <Typography type="label">
          <TranslatedText id="pending-withdrawals.created" />
        </Typography>
        <Typography className="text" component="span">
          <FormattedDate value={requestedAt} />
          {", "}
          <FormattedTime value={requestedAt} />
        </Typography>
      </div>

      {renderCancelWithdrawal()}
    </div>
  );
};

interface PendingInvestmentWithdrawalModalProps {
  withdrawal: Withdrawal;
  account: InvestmentAccount;
  onWithdrawalCancelled: () => void;
}
const PendingInvestmentWithdrawalModal: FunctionComponent<
  PendingInvestmentWithdrawalModalProps
> = ({ withdrawal, account, onWithdrawalCancelled }) => {
  return (
    <PendingWithdrawalModal
      amount={withdrawal.drain ? account.worth : withdrawal.amount}
      fromAccountName={account.name}
      toAccountName={withdrawal.externalBankAccount}
      bankId={withdrawal.bank}
      requestedAt={withdrawal.requested}
      renderCancelWithdrawal={() => (
        <CancelPendingInvestmentWithdrawal
          withdrawal={withdrawal}
          onWithdrawalCancelled={onWithdrawalCancelled}
        />
      )}
    />
  );
};

interface PendingSavingsWithdrawalModalProps {
  withdrawal: ExternalPendingSavingsWithdrawalResponse;
  account: SavingsAccount;
  onWithdrawalCancelled: () => void;
}
const PendingSavingsWithdrawalModal: FunctionComponent<
  PendingSavingsWithdrawalModalProps
> = ({ withdrawal, account, onWithdrawalCancelled }) => {
  return (
    <PendingWithdrawalModal
      amount={withdrawal.amount}
      fromAccountName={account.name}
      toAccountName={withdrawal.externalBankAccount}
      bankId={withdrawal.bank}
      requestedAt={withdrawal.requested}
      renderCancelWithdrawal={() => (
        <CancelPendingSavingsWithdrawal
          withdrawal={withdrawal}
          onWithdrawalCancelled={onWithdrawalCancelled}
        />
      )}
    />
  );
};

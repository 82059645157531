import { FC, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Form, LysaFormRef } from "@lysaab/ui-2";
import {
  PensionAdviseAccount,
  SustainabilityImportance,
  SustainabilityPreference,
} from "../../../data/dataInvestments";
import { SustainabilityPreferenceQuestion } from "../../../pageComponents/sustainability/SustainabilityPreferenceQuestion";

export interface UpdateSustainabilityPreferenceLocationState {
  sustainability: SustainabilityImportance.IMPORTANT;
}

interface Props {
  locationState: UpdateSustainabilityPreferenceLocationState;
  advise: PensionAdviseAccount;
  onNext: (
    value: SustainabilityPreference,
    locationState: UpdateSustainabilityPreferenceLocationState
  ) => void;
}

export const UpdateSustainabilityPreferenceWrapper: FC<Props> = ({
  locationState,
  advise,
  onNext,
}) => {
  const intl = useIntl();
  const formRef = useRef<LysaFormRef>();

  const currentSustainabilityPreference =
    advise.sustainability === SustainabilityImportance.IMPORTANT
      ? advise.sustainabilityPreference
      : undefined;

  const [sustainabilityPreference, setSustainabilityPreference] = useState(
    currentSustainabilityPreference
  );

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={() => {
        if (formRef.current?.isValid && sustainabilityPreference) {
          onNext(sustainabilityPreference, locationState);
        }
      }}
    >
      <SustainabilityPreferenceQuestion
        sustainabilityPreference={sustainabilityPreference}
        setSustainabilityPreference={(sustainabilityPreference) =>
          setSustainabilityPreference(sustainabilityPreference)
        }
      />

      <Button
        block
        type="submit"
        label={intl.formatMessage({
          id: "updatePensionStory.updateSustainabilityPreferenceWrapper.next-button",
        })}
      />
    </Form>
  );
};

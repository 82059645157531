import { NewIcon, Typography } from "@lysaab/ui-2";
import { FunctionComponent, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { TranslatedText } from "../../../../../../components/TranslatedText";

import "./FAQ.scss";

export interface FaqItemProps {
  title: string;
  content: string;
}

interface Props {
  faqItems: FaqItemProps[];
}

export const FAQ: FunctionComponent<Props> = ({ faqItems }) => {
  return (
    <section className="transfer-pension-intro-faq">
      <Typography type="h3">
        <TranslatedText id="sweden.transfer-pension.intro.faq.header" />
      </Typography>
      {faqItems.map((item, index) => (
        <AccordionItem key={index} title={item.title} content={item.content} />
      ))}
    </section>
  );
};

const AccordionItem: FunctionComponent<FaqItemProps> = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-item">
      <button onClick={toggleOpen} className="faq-button">
        <Typography type="label" className="title">
          {title}
        </Typography>
        <span
          className={isOpen ? "chevron chevron-open" : "chevron chevron-closed"}
        >
          <NewIcon.ChevronRight />
        </span>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{
              opacity: 0,
              height: 0,
            }}
            transition={{ duration: 0.3 }}
          >
            <Typography type="body" className="faq-content">
              {content}
            </Typography>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

import { FunctionComponent, memo } from "react";
import { PageStripped } from "../../../../pages/PageStripped";
import { Story } from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { useSafeNavigation } from "../../../../hooks/useSafeNavigation";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "../../../../pages/overview/OverviewPage";
import { useStoryValues } from "../../../../hooks/useStoryValues";
import { Route } from "../../../../components/route/Route";
import { Intro } from "./intro/Intro";
import { generatePath, useHistory, useLocation } from "react-router";
import { Switch } from "../../../../components/route/Switch";
import { AgeAndLength } from "./ageAndLength/AgeAndLength";
import { Account } from "./account/Account";
import { DoneWithoutChange } from "./done/DoneWithoutChange";
import { WithdrawalPensionsContextProvider } from "./WithdrawalPensionsContext";
import { DoneWithChange } from "./done/DoneWithChange";

const messages = defineMessages({
  header: {
    id: "withdrawalPensions.header",
    defaultMessage: "Pensionsutbetalning",
  },
  ariaProgressLabel: {
    id: "withdrawalPensions.ariaProgressLabel",
    defaultMessage: "Steg {current} av {total} i pensionsutbetalning",
  },
});

export const WITHDRAWAL_PENSIONS_URL = "/withdrawal-pensions/:accountId";

/**
 * Separate url paths to be able to show different content on done step.
 */

export const BASE_ROUTES = {
  INTRO: `${WITHDRAWAL_PENSIONS_URL}/`,
  AGE_AND_LENGTH: `${WITHDRAWAL_PENSIONS_URL}/age-and-length`,
  ACCOUNT_WITHOUT_CHANGE: `${WITHDRAWAL_PENSIONS_URL}/account-without-change`,
  ACCOUNT_WITH_CHANGE: `${WITHDRAWAL_PENSIONS_URL}/account-with-change`,
  DONE_WITHOUT_CHANGE: `${WITHDRAWAL_PENSIONS_URL}/done-without-change`,
  DONE_WITH_CHANGE: `${WITHDRAWAL_PENSIONS_URL}/done-with-change`,
};

const InnerWithdrawalPensionsStory: FunctionComponent = () => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const safeNavigation = useSafeNavigation();
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);

  return (
    <PageStripped>
      <Story
        ariaLabelProgress={() =>
          intl.formatMessage(messages.ariaProgressLabel, {
            current: currentIndex + 1,
            total: storyLength,
          })
        }
        header={intl.formatMessage(messages.header)}
        progress={
          // This works around a bug in the Story component where it renders 0 as a string,
          // we should fix
          storyProgress === 0 ? undefined : storyProgress
        }
        showBack={
          currentIndex > 0 && currentIndex < Object.values(ROUTES).length - 2
        }
        showClose={true}
        onBack={history.goBack}
        transitionKey={currentIndex.toString()}
        onExit={() => safeNavigation(getNavLink(OVERVIEW_PAGE_URL))}
      >
        <Switch
          location={location}
          {...{
            order: currentIndex,
          }}
        >
          <Route exact path={ROUTES.INTRO}>
            <Intro
              toNext={(accountId) =>
                safeNavigation(
                  generatePath(ROUTES.AGE_AND_LENGTH, { accountId })
                )
              }
              toOverview={() => safeNavigation(getNavLink(OVERVIEW_PAGE_URL))}
            />
          </Route>
          <Route exact path={ROUTES.AGE_AND_LENGTH}>
            <AgeAndLength
              toAccountWithoutChange={(accountId) =>
                safeNavigation(
                  generatePath(ROUTES.ACCOUNT_WITHOUT_CHANGE, { accountId })
                )
              }
              toAccountWithChange={(accountId) =>
                safeNavigation(
                  generatePath(ROUTES.ACCOUNT_WITH_CHANGE, { accountId })
                )
              }
            />
          </Route>
          <Route exact path={ROUTES.ACCOUNT_WITHOUT_CHANGE}>
            <Account
              toNext={(accountId) =>
                safeNavigation(
                  generatePath(ROUTES.DONE_WITHOUT_CHANGE, { accountId })
                )
              }
            />
          </Route>
          <Route exact path={ROUTES.ACCOUNT_WITH_CHANGE}>
            <Account
              toNext={(accountId) =>
                safeNavigation(
                  generatePath(ROUTES.DONE_WITH_CHANGE, { accountId })
                )
              }
            />
          </Route>
          <Route exact path={ROUTES.DONE_WITHOUT_CHANGE}>
            <DoneWithoutChange
              toNext={() => safeNavigation(getNavLink(OVERVIEW_PAGE_URL))}
            />
          </Route>
          <Route exact path={ROUTES.DONE_WITH_CHANGE}>
            <DoneWithChange
              toNext={() => safeNavigation(getNavLink(OVERVIEW_PAGE_URL))}
            />
          </Route>
        </Switch>
      </Story>
    </PageStripped>
  );
};

export const WithdrawalPensionsStory: FunctionComponent = memo(() => {
  return (
    <WithdrawalPensionsContextProvider>
      <InnerWithdrawalPensionsStory />
    </WithdrawalPensionsContextProvider>
  );
});

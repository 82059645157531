import { FC } from "react";
import { useIntl } from "react-intl";
import { NewIcon, Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { FlatButton } from "../../../components/flatButton/FlatButton";
import { SustainabilityImportance } from "../../../data/dataInvestments";
import { getInvestmentTypeTextFromSustainabilityImportance } from "../../../texts/investmentTypeTexts";
import { TranslatedText } from "../../../components/TranslatedText";

import "./UpdateOptions.scss";

// TODO: Does it make sense to update with PensionQuestions here?

interface Props {
  sustainabilityImportance: SustainabilityImportance;
  takenRiskDeviation: number;
  withdrawalAge: number;
  withdrawalMonths: number | undefined;
  lifeLong: boolean;
  repaymentProtection: boolean;
  toSustainability: () => void;
  toTakenRiskDeviation: () => void;
  toWithdrawalPlan?: () => void;
  toRepaymentProtection: () => void;
}

export const UpdateOptions: FC<Props> = ({
  sustainabilityImportance,
  takenRiskDeviation,
  withdrawalAge,
  withdrawalMonths,
  lifeLong,
  repaymentProtection,
  toTakenRiskDeviation,
  toSustainability,
  toWithdrawalPlan,
  toRepaymentProtection,
}) => {
  const intl = useIntl();

  return (
    <section className="UpdateOptions">
      <nav className="option-items">
        <FlatButton
          title={intl.formatMessage({
            id: "storyComponents.updatePension.updateOptions.risk-deviation",
          })}
          value={intl.formatNumber(takenRiskDeviation / 100, {
            style: "percent",
          })}
          onClick={toTakenRiskDeviation}
        />
        <FlatButton
          title={intl.formatMessage({
            id: "storyComponents.updatePension.updateOptions.withdrawal-plan",
          })}
          value={
            withdrawalMonths
              ? intl.formatMessage(
                  {
                    id: "storyComponents.updatePension.updateOptions.withdrawal-plan.duration",
                  },
                  {
                    startAge: withdrawalAge,
                    finalAge: withdrawalAge + withdrawalMonths / 12,
                    duration: withdrawalMonths / 12,
                  }
                )
              : lifeLong
              ? intl.formatMessage(
                  {
                    id: "storyComponents.updatePension.updateOptions.withdrawal-plan.life-long",
                  },
                  { age: withdrawalAge }
                )
              : "-"
          }
          onClick={toWithdrawalPlan}
        />
        <FlatButton
          title={intl.formatMessage({
            id: "storyComponents.updatePension.updateOptions.sustainability",
          })}
          value={getInvestmentTypeTextFromSustainabilityImportance(
            intl,
            sustainabilityImportance
          )}
          icon={sustainabilityIcons[sustainabilityImportance]}
          onClick={toSustainability}
        />
        <FlatButton
          title={intl.formatMessage({
            id: "storyComponents.updatePension.updateOptions.repayment-protection",
          })}
          value={
            repaymentProtection
              ? intl.formatMessage({
                  id: "storyComponents.updatePension.updateOptions.repayment-protection.yes",
                })
              : intl.formatMessage({
                  id: "storyComponents.updatePension.updateOptions.repayment-protection.no",
                })
          }
          onClick={toRepaymentProtection}
        />
      </nav>

      <Snackbar type={SNACKBAR_TYPES.INFO} icon textAlign="left">
        <TranslatedText id="storyComponents.updatePension.updateOptions.adjustment" />
      </Snackbar>
    </section>
  );
};

const sustainabilityIcons: Record<
  SustainabilityImportance,
  React.ReactElement
> = {
  [SustainabilityImportance.NOT_IMPORTANT]: <NewIcon.Earth size={16} />,
  [SustainabilityImportance.IMPORTANT]: <NewIcon.Sustainable size={16} />,
};

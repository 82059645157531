import { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Alternative,
  Card,
  Form,
  LysaFormRef,
  Button,
  RadioGroup,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Typography,
} from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router";
import { EventTracker } from "../../../../../../components/eventTracker/EventTracker";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { Consent, dataAutogiro } from "../../../../../../data/dataAutogiro";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { MonthlyContext } from "../MonthlyContext";
import { BASE_ROUTES } from "../MonthlyStory";
import { MONTHLY_DEPOSITS_URL } from "../../../../../../pages/deposits/monthly/create/MonthlyStory";
import { AutogiroConditions } from "../../../../components/autogiroConditions/AutogiroConditions";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
  TrackerEvent,
} from "../../../../../../data/dataCustomerTracking";
import { Link } from "react-router-dom";
import { TinkAccount } from "../../../../../../data/dataTink";

interface Props {
  next: () => void;
}

const messages = defineMessages({
  accountLabel: {
    id: "sweden.deposits.monthly.story.account-selection.account.label",
  },
  accountRequired: {
    id: "sweden.deposits.monthly.story.account-selection.account.required",
  },
});

const getAccountLabel = (account: TinkAccount) => {
  if (account.name) {
    return `${account.name} - ${account.accountNumber}`;
  }

  return account.accountNumber;
};

export function TinkAccountSelection({ next }: Props) {
  const monthlyContext = useContext(MonthlyContext);
  const [consents, setConsents] = useState<Consent[]>([]);
  const [showConsentConditions, setShowConsentConditions] = useState(false);
  const history = useHistory();
  const intl = useIntl();
  const selectedTinkAccount = monthlyContext.state.selectedTinkAccount;
  const formRef = useRef<LysaFormRef>();

  const sortedTinkAccounts = useMemo((): TinkAccount[] => {
    return (
      monthlyContext.state.tinkAccounts?.sort((a, b) => {
        return a.name.localeCompare(b.name);
      }) || []
    );
  }, [monthlyContext.state.tinkAccounts]);

  useEffect(() => {
    if (!monthlyContext.state.tinkAccounts) {
      history.push(getNavLink(MONTHLY_DEPOSITS_URL));
    }
    dataAutogiro.getConsents().then((consents) => {
      setConsents(consents);
    });
  }, [history, monthlyContext.state.tinkAccounts]);

  useEffect(() => {
    if (
      typeof monthlyContext.state.selectedTinkAccount !== "undefined" &&
      !consents.some(
        (consent) =>
          consent.externalBankAccount ===
          monthlyContext.state.selectedTinkAccount?.accountNumber
      )
    ) {
      setShowConsentConditions(true);
    } else {
      setShowConsentConditions(false);
    }
  }, [consents, monthlyContext.state.selectedTinkAccount]);

  useEffect(() => {
    if (monthlyContext.state.tinkAccounts) {
      dataCustomerTrackingService.postEvent({
        domain: FeatureDomain.TRANSFERS,
        subDomain: SubDomain.MONTHLY,
        eventName: "monthlyAccountSelection",
      });
    }
  }, [monthlyContext.state.tinkAccounts]);

  if (!monthlyContext.state.tinkAccounts) {
    return <Spinner />;
  }

  if (monthlyContext.state.tinkAccounts.length === 0) {
    return (
      <div>
        <h2>
          <TranslatedText id="sweden.deposits.monthly.story.account-selection.no-accounts.header" />
        </h2>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <Typography component="span">
            <TranslatedText id="sweden.deposits.monthly.story.account-selection.no-accounts.text" />
          </Typography>
        </Snackbar>
        <Button
          component={Link}
          to={getNavLink(BASE_ROUTES.BANK_SELECTION)}
          block
          label={
            <TranslatedText id="sweden.deposits.monthly.story.account-selection.no-accounts.button" />
          }
        />
      </div>
    );
  }

  return (
    <div>
      <h2>
        <TranslatedText id="sweden.deposits.monthly.story.account-selection.header" />
      </h2>
      <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
        <TranslatedText id="sweden.deposits.monthly.story.account-selection.information" />
      </Snackbar>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid) {
            return;
          }
          EventTracker.track({
            event: TrackerEvent.ADD_MONTHLY_KLARNA_ACCOUNT,
            message: monthlyContext.state.selectedTinkAccount?.accountNumber,
          });
          next();
        }}
      >
        <Card>
          <RadioGroup
            alternatives={sortedTinkAccounts.map(
              (account): Alternative<string> => {
                return {
                  text: getAccountLabel(account),
                  value: account.accountNumber,
                };
              }
            )}
            value={
              selectedTinkAccount
                ? {
                    text: getAccountLabel(selectedTinkAccount),
                    value: selectedTinkAccount.accountNumber,
                  }
                : undefined
            }
            header={intl.formatMessage(messages.accountLabel)}
            onChange={(alt) =>
              monthlyContext.setState({
                selectedTinkAccount: monthlyContext.state.tinkAccounts?.find(
                  (account) => account.accountNumber === alt.value
                ),
              })
            }
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.accountRequired)
              ),
            ]}
          />
          <AutogiroConditions showConsentConditions={showConsentConditions} />
        </Card>
        <Button
          type="submit"
          block
          label={
            <TranslatedText id="sweden.deposits.monthly.story.account-selection.button" />
          }
        />
      </Form>
    </div>
  );
}

import type { FunctionComponent } from "react";
import { useCallback, useState } from "react";
import { EventTracker } from "../../../components/eventTracker/EventTracker";
import { TrackerEvent } from "../../../data/dataCustomerTracking";
import { SNACKBAR_TYPES, useFlash } from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import {
  dataWithdrawals,
  Withdrawal,
  isCancellable,
} from "../../../data/dataWithdrawals";
import { CancelPendingTransaction } from "../../../components/pendingTransactions/CancelPendingTransaction";

interface Props {
  withdrawal: Withdrawal;
  onWithdrawalCancelled: () => void;
}

export const CancelPendingInvestmentWithdrawal: FunctionComponent<Props> = ({
  withdrawal,
  onWithdrawalCancelled,
}) => {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();
  const pushFlash = useFlash();

  const cancelWithdrawal = useCallback(() => {
    setHasError(false);
    setIsLoading(true);

    dataWithdrawals
      .deleteWithdrawal(withdrawal.accountId)
      .then(() => {
        EventTracker.track({
          event: TrackerEvent.DELETE_WITHDRAWAL,
          message: `Cancelled withdrawal for account ${withdrawal.accountId}`,
        });
        pushFlash({
          text: intl.formatMessage({
            id: "withdrawals.pending.cancelSuccess",
          }),
          type: SNACKBAR_TYPES.SUCCESS,
        });
        onWithdrawalCancelled();
        // We deliberately don't set isLoading to false here, as the component will flicker
        // with the "confirm"-part of <CancelPendingTransaction> component before closing.
      })
      .catch(() => {
        setHasError(true);
      });
  }, [intl, onWithdrawalCancelled, withdrawal, pushFlash]);

  return (
    <CancelPendingTransaction
      isCancellable={isCancellable(withdrawal)}
      onCancelTransaction={cancelWithdrawal}
      isLoading={isLoading}
      hasError={hasError}
      cancelMessage={intl.formatMessage({
        id: "withdrawals.pending.cancel",
      })}
      errorMessage={intl.formatMessage({
        id: "withdrawals.pending.cancelError",
      })}
      notCancellableMessage={intl.formatMessage({
        id: "withdrawals.pending.notCancellable",
      })}
      confirmCancelMessage={intl.formatMessage({
        id: "withdrawals.pending.confirmCancel",
      })}
      denyCancelMessage={intl.formatMessage({
        id: "withdrawals.pending.denyCancel",
      })}
    />
  );
};

import { FC, useEffect, useState } from "react";
import { generatePath, Redirect, useParams } from "react-router";
import { Spinner } from "@lysaab/ui-2";
import {
  dataInvestments,
  PensionAdviseAccount,
  PensionQuestions,
  PensionWithdrawalParametersResponse,
} from "../../../data/dataInvestments";
import { dataLifePension } from "../../../data/dataLifePension";
import { UpdateWithdrawalPlan } from "../../../storyComponents/updatePension/updateWithdrawalPlan/UpdateWithdrawalPlan";
import { Params, UPDATE_PENSION_URL } from "../UpdatePensionStory";
import { getNavLink } from "../../../hooks/useCountryUrls";

interface Props {
  advise: PensionAdviseAccount;
  toRepaymentProtection: () => void;
  onNext: (value: PensionQuestions) => void;
}

export const UpdateWithdrawalPlanWrapper: FC<Props> = ({
  advise,
  onNext,
  toRepaymentProtection,
}) => {
  const { accountId } = useParams<Params>();
  const [repaymentProtection, setRepaymentProtection] = useState<boolean>();
  const [withdrawalParameters, setWithdrawalParameters] =
    useState<PensionWithdrawalParametersResponse>();

  useEffect(() => {
    dataLifePension.getPensionDetails(accountId).then((response) => {
      setRepaymentProtection(response.repaymentProtection);
    });
    dataInvestments
      .getPensionWithdrawalParameters(accountId)
      .then(setWithdrawalParameters);
  }, [accountId]);

  if (repaymentProtection === undefined || withdrawalParameters === undefined) {
    return <Spinner />;
  }

  if (!!withdrawalParameters.lockedUntil) {
    return (
      <Redirect
        to={generatePath(getNavLink(UPDATE_PENSION_URL), { accountId })}
      />
    );
  }

  // TODO: Ask TypeScript what's up.
  const withdrawalDuration = advise.lifeLong
    ? { withdrawalMonths: advise.withdrawalMonths, lifeLong: advise.lifeLong }
    : { withdrawalMonths: advise.withdrawalMonths, lifeLong: advise.lifeLong };

  return (
    <UpdateWithdrawalPlan
      withdrawalAge={advise.withdrawalAge}
      takenRiskDeviation={advise.takenRiskDeviation}
      withdrawalDuration={withdrawalDuration}
      minWithdrawalAge={withdrawalParameters.minWithdrawalAge}
      repaymentProtection={repaymentProtection}
      action="next"
      toRepaymentProtection={toRepaymentProtection}
      onNext={onNext}
    />
  );
};

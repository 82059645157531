import { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { getNavLink } from "../../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "../../pages/overview/OverviewPage";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { CreateAccountLink } from "../createAccountLink/CreateAccountLink";
import { BurgerMenuContext } from "../burgerMenu/BurgerMenuContext";
import "./Header.scss";
import { RouteAwareToggle } from "../route/RouteAwareToggle";
import { UserContext } from "../../context/UserContext";
import { LegalEntityType } from "../../data/dataLogin";
import { WITHDRAWAL_PAGE_URL } from "../../pages/withdrawal/overview/WithdrawalPage";
import { MONTHLY_OVERVIEW_PAGE } from "../../pages/deposits/monthly/overview/MonthlyOverview";
import { NotificationDot } from "../notificationDot/notificationDot";
import { useNotifications } from "../../hooks/useNotifications";
import { DEPOSITS_OVERVIEW_URL } from "../../pages/deposits/overview/Recommendation";
import { Icon, NewIcon, StatusLabel, STATUS_TYPE } from "@lysaab/ui-2";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
} from "../../data/dataCustomerTracking";
import { BURGER_MENU_ARIA_ID } from "../burgerMenu/BurgerMenu";
import { CREATE_ACCOUNT_CORPORATION_PAGE_URL } from "../../countries/sweden/pages/corporation/kf/CreateAccountCorporationPage";
import { CREATE_ACCOUNT_INTRO_URL } from "../../countries/sweden/pages/createAccount/createAccountIntroStory/CreateAccountIntroStory";

const messages = defineMessages({
  deposits: {
    id: "header.deposits",
  },
  withdrawal: {
    id: "header.withdrawals",
  },
  monthly: { id: "header.monthly" },
  openAccount: { id: "header.openAccount" },
});

export function Header() {
  const burgerMenuContext = useContext(BurgerMenuContext);
  const userContext = useContext(UserContext);
  const intl = useIntl();
  const { unreadMessages } = useNotifications();
  const { readOnly } = userContext.state;

  return (
    <header className="main-header">
      <div className="left-header-wrapper">
        <button
          className="transparent-button open-burger-menu"
          onClick={() => {
            burgerMenuContext.setState({ isOpen: true });
          }}
          aria-expanded={burgerMenuContext.state.isOpen}
          aria-controls={BURGER_MENU_ARIA_ID}
        >
          <NewIcon.Menu />
          {unreadMessages > 0 && <NotificationDot />}
        </button>
        <NavLink to={getNavLink(OVERVIEW_PAGE_URL)}>
          <Icon.Logo />
        </NavLink>
        {readOnly && (
          <StatusLabel
            className="read-only-warning"
            type={STATUS_TYPE.WARNING}
            icon={false}
          >
            <Icon.LockClosed color="black" size={12} />
            <span className="read-only-warning-text">
              <FormattedMessage id={"header.readOnly"} />
            </span>
          </StatusLabel>
        )}
        <div className="center-spacer"></div>
      </div>

      <nav>
        <ul>
          <RouteAwareToggle path={getNavLink(DEPOSITS_OVERVIEW_URL)}>
            <li>
              <Link
                to={getNavLink(DEPOSITS_OVERVIEW_URL)}
                onClick={() => {
                  dataCustomerTrackingService.postEvent({
                    eventName: "clickedOverviewDepositButton",
                    domain: FeatureDomain.GENERAL,
                    subDomain: SubDomain.OVERVIEW,
                  });
                }}
              >
                {intl.formatMessage(messages.deposits)}
              </Link>
            </li>
          </RouteAwareToggle>
          <RouteAwareToggle path={getNavLink(WITHDRAWAL_PAGE_URL)}>
            <li className="tablet-up-only">
              <Link
                to={getNavLink(WITHDRAWAL_PAGE_URL)}
                onClick={() => {
                  dataCustomerTrackingService.postEvent({
                    eventName: "clickedOverviewWithdrawalButton",
                    domain: FeatureDomain.GENERAL,
                    subDomain: SubDomain.OVERVIEW,
                  });
                }}
              >
                {intl.formatMessage(messages.withdrawal)}
              </Link>
            </li>
          </RouteAwareToggle>
          <RouteAwareToggle path={getNavLink(MONTHLY_OVERVIEW_PAGE)}>
            <li className="tablet-up-only">
              <Link to={getNavLink(MONTHLY_OVERVIEW_PAGE)}>
                {intl.formatMessage(messages.monthly)}
              </Link>
            </li>
          </RouteAwareToggle>
          <RouteAwareToggle
            path={getNavLink(
              userContext.state.legalEntityType === LegalEntityType.CORPORATION
                ? CREATE_ACCOUNT_CORPORATION_PAGE_URL
                : CREATE_ACCOUNT_INTRO_URL
            )}
          >
            <li className="tablet-up-only">
              <CreateAccountLink>
                {intl.formatMessage(messages.openAccount)}
              </CreateAccountLink>
            </li>
          </RouteAwareToggle>
        </ul>
      </nav>
    </header>
  );
}

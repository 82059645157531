import { FunctionComponent, useRef, useState } from "react";
import {
  Button,
  Form,
  LysaFormRef,
  RadioGroupCard,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Typography,
} from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { Beneficiary, useTransfer } from "../TransferContext";
import { AnimatePresence, motion } from "framer-motion";
import { Modal } from "../../../../../components/modal/Modal";
import { TranslatedText } from "../../../../../components/TranslatedText";

import "./Repayment.scss";

export const Repayment: FunctionComponent = () => {
  const intl = useIntl();
  const history = useHistory();
  const formRef = useRef<LysaFormRef>();
  const [transfer, setTransfer] = useTransfer();

  const [showModal, setShowModal] = useState(false);

  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  const hasLifeLongWithdrawal = transfer.lifeLong;

  return (
    <section className="transfer-pension-repayment">
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (formRef.current?.isValid) {
            history.goBack();
          }
        }}
      >
        <Typography type="h3">
          <TranslatedText id="sweden.transfer-pension.repayment.header" />
        </Typography>
        <Typography type="body">
          <TranslatedText
            id="sweden.transfer-pension.repayment.body"
            values={{
              readMore: (text) => {
                return (
                  <Button
                    inline
                    variant="outlined"
                    size="small"
                    label={text}
                    className="read-more"
                    onClick={handleModalOpen}
                  />
                );
              },
            }}
          />
        </Typography>
        {showModal && (
          <Modal
            header={intl.formatMessage({
              id: "sweden.transfer-pension.repayment.read-more.modal.header",
            })}
            showModal={!!showModal}
            onClose={handleModalClose}
            closeOnOverlayClick
          >
            <Typography type="body">
              <TranslatedText id="sweden.transfer-pension.repayment.read-more.modal.first-paragraph" />
            </Typography>
            <Typography type="body">
              <TranslatedText id="sweden.transfer-pension.repayment.read-more.modal.second-paragraph" />
            </Typography>
          </Modal>
        )}
        <RadioGroupCard
          alternatives={[
            {
              header: intl.formatMessage({
                id: "sweden.transfer-pension.repayment.choice.yes",
              }),
              description: intl.formatMessage({
                id: "sweden.transfer-pension.repayment.choice.yes.description",
              }),
              value: true,
              disabled: hasLifeLongWithdrawal,
            },
            {
              header: intl.formatMessage({
                id: "sweden.transfer-pension.repayment.choice.no",
              }),
              description: intl.formatMessage({
                id: "sweden.transfer-pension.repayment.choice.no.description",
              }),
              value: false,
            },
          ]}
          legend=""
          onChange={(newValue) => {
            setTransfer({ repayment: newValue });
          }}
          validators={[
            new RequiredValidator(
              intl.formatMessage({
                id: "sweden.transfer-pension.pension-type.required",
              })
            ),
          ]}
          value={transfer.repayment}
        />
        {hasLifeLongWithdrawal ? (
          <div className="snack-bar">
            <Snackbar type={SNACKBAR_TYPES.INFO} icon textAlign="left">
              <TranslatedText id="sweden.transfer-pension.repayment.life-long-and-repayment.snackbar" />
            </Snackbar>
          </div>
        ) : (
          <AnimatePresence>
            {transfer.repayment === true && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{
                  opacity: 0,
                  height: 0,
                }}
                transition={{ duration: 0.4 }}
              >
                <Typography type="h3" className="beneficiary-header">
                  <TranslatedText id="sweden.transfer-pension.beneficiary.header" />
                </Typography>
                <RadioGroupCard
                  alternatives={[
                    {
                      header: intl.formatMessage({
                        id: "sweden.transfer-pension.beneficiary.spouse-children",
                      }),
                      description: (
                        <TranslatedText id="sweden.transfer-pension.beneficiary.spouse-children.description" />
                      ),
                      value: "SPOUSE_CHILDREN",
                    },
                    {
                      header: intl.formatMessage({
                        id: "sweden.transfer-pension.beneficiary.other",
                      }),
                      description: intl.formatMessage({
                        id: "sweden.transfer-pension.beneficiary.other.description",
                      }),
                      value: "OTHER", // Not super intiutive but decided. This alternative mean we send home a paper form. When we get that back we update the option on the move.
                      expandableContent: (
                        <section className="download">
                          <Typography type="body" variant="secondary">
                            <TranslatedText id="sweden.transfer-pension.beneficiary.other.download.info" />
                          </Typography>
                          <Button
                            component="a"
                            type="button"
                            download
                            variant="outlined"
                            icon="Download"
                            target="_blank"
                            block
                            label={intl.formatMessage({
                              id: "sweden.transfer-pension.beneficiary.other.download.button",
                            })}
                            href="/docs/pension-account/Blankett_formanstagare_4.pdf"
                          />
                        </section>
                      ),
                    },
                  ]}
                  legend=""
                  onChange={(newValue: Beneficiary) => {
                    setTransfer({ beneficiary: newValue });
                  }}
                  validators={[
                    new RequiredValidator(
                      intl.formatMessage({
                        id: "sweden.transfer-pension.pension-type.required",
                      })
                    ),
                  ]}
                  value={transfer.beneficiary}
                />
              </motion.div>
            )}
          </AnimatePresence>
        )}
        {transfer.repayment === false && (
          <Snackbar type={SNACKBAR_TYPES.INFO} icon textAlign="left">
            <TranslatedText id="sweden.transfer-pension.repayment.no-repayment.snackbar" />
          </Snackbar>
        )}
        <Button
          variant="primary"
          type="submit"
          block
          label={intl.formatMessage({
            id: "sweden.transfer-pension.repayment.button",
          })}
        />
      </Form>
    </section>
  );
};

import { Typography } from "@lysaab/ui-2";
import { FunctionComponent } from "react";
import { TranslatedText } from "../../../../../components/TranslatedText";

import "./SignStepIndicator.scss";

interface Props {
  step: number;
}

export const SignStepIndicator: FunctionComponent<Props> = ({ step }) => {
  const stepLabel = (
    <TranslatedText id="sweden.transfer-pension.digital-signing.step-indicator.step" />
  );

  const ofLabel = (
    <TranslatedText id="sweden.transfer-pension.digital-signing.step-indicator.of" />
  );

  return (
    <span className="sign-step-indicator">
      <Typography type="label-small">
        {stepLabel} {step} {ofLabel}
      </Typography>
    </span>
  );
};

import { FunctionComponent, useEffect, useState } from "react";
import { getDynamicLink } from "../../../../hooks/useCountryUrls";
import { NewIcon, SelectableCard, Typography } from "@lysaab/ui-2";
import { InvestmentAccountId } from "../../../../data/dataAccounts";
import { TranslatedText } from "../../../../components/TranslatedText";
import { ReactComponent as MonthlyDepositsIcon } from "./monthly-deposits.svg";
import { useSafeNavigation } from "../../../../hooks/useSafeNavigation";
import { dataLifePensionStorage } from "../../../../data/dataLifePensionStorage";
import { WITHDRAWAL_PENSIONS_URL } from "../../../../countries/sweden/pages/withdrawalPensions/WithdrawalPensionsStory";

import "./PensionWithdrawalInfo.scss";

interface Props {
  accountId?: InvestmentAccountId;
}

export const PensionWithdrawalInfo: FunctionComponent<Props> = ({
  accountId,
}) => {
  const safeNavigation = useSafeNavigation();
  const [match, setMatch] = useState(false);

  useEffect(() => {
    dataLifePensionStorage
      .getPensionWithdrawalPlanStatus()
      .then((res) => {
        const match = res.needUpdate.find((id) => id === accountId);
        if (match) {
          setMatch(true);
        }
      })
      .catch((error) => {
        /**
         * Swallow the error. If not it will bubble up and block user. The component is not essential.
         *
         * TODO: Log the error
         */
        console.log(
          `Could not load pension withdrawal plan status. Error: ${error}`
        );
      });
  }, [accountId]);

  if (!accountId || !match) {
    return null;
  }

  return (
    <SelectableCard
      className="pension-withdrawal-info-card"
      onClick={() => {
        safeNavigation(
          getDynamicLink(WITHDRAWAL_PENSIONS_URL, {
            accountId: accountId,
          })
        );
      }}
    >
      <MonthlyDepositsIcon />
      <span className="content">
        <Typography type="label-large">
          <TranslatedText
            id="pension-withdrawal-info-card.label"
            defaultMessage="Skapa utbetalningsplan"
          />
        </Typography>
        <Typography type="body" component="div">
          <TranslatedText
            id="pension-withdrawal-info-card.body"
            defaultMessage="Se över dina utbetalningsinställningar och välj när och hur du vill ta ut din pension."
          />
        </Typography>
      </span>
      <NewIcon.ChevronRight />
    </SelectableCard>
  );
};

import { Spinner, Snackbar, SNACKBAR_TYPES, Button } from "@lysaab/ui-2";
import { useEffect, useCallback, useRef, useState } from "react";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  dataKlarna,
  KlarnaState,
  KlarnaAccount,
  AvailableBank,
  AccountTypes,
} from "../../../data/dataKlarna";
import { KlarnaIntegrationClient } from "../../../utils/KlarnaIntegrationClient";
import { EventTracker } from "../../eventTracker/EventTracker";
import { TranslatedText } from "../../TranslatedText";
import * as H from "history";
import {
  dataCustomerTrackingService,
  TrackerEvent,
} from "../../../data/dataCustomerTracking";
import { featureTrackingManualBackup } from "../../../utils/featureTrackingPath";

const POLL_TIMER = 3 * 1000; // 3 Seconds

interface Props {
  bank: AvailableBank;
  onComplete: (accounts: KlarnaAccount[]) => void;
  fallbackOptions?: {
    locationDescriptor: H.LocationDescriptor;
    buttonText: string | React.ReactNode;
  };
  accountTypes?: AccountTypes[];
}

export function AccountLoading({
  bank,
  onComplete,
  fallbackOptions,
  accountTypes,
}: Props) {
  const [clientToken, setClientToken] = useState<string>();
  const [klarnaState, setKlarnaState] = useState<KlarnaState>(
    KlarnaState.WAITING_TO_START
  );
  const timer = useRef<NodeJS.Timeout>();
  const [klarnaIntegrationClient] = useState(new KlarnaIntegrationClient());
  const location = useLocation();

  const poll = useCallback(() => {
    dataKlarna
      .pollAccountInformationSession()
      .then((resp) => {
        setKlarnaState(resp.state);
        setClientToken(resp.clientToken);
        if (resp.state === KlarnaState.COMPLETE) {
          EventTracker.track({
            event: TrackerEvent.LOADED_ACCOUNTS,
            message: `Found ${resp.accounts?.length ?? 0} accounts`,
          });
          onComplete(resp.accounts ?? []);
        } else if (resp.state !== KlarnaState.ERROR) {
          timer.current = setTimeout(poll, POLL_TIMER);
        }
      })
      .catch(() => {
        EventTracker.track({
          event: TrackerEvent.ERROR,
          message: "Kunde inte hämta konton från Klarna",
        });
        setKlarnaState(KlarnaState.ERROR);
      });
  }, [onComplete]);

  useEffect(() => {
    if (!clientToken) {
      return;
    }
    klarnaIntegrationClient.start(
      clientToken,
      () => {
        setKlarnaState(KlarnaState.ERROR);
        if (timer.current) {
          clearTimeout(timer.current);
        }
      },
      () => {
        setKlarnaState(KlarnaState.ERROR);
        if (timer.current) {
          clearTimeout(timer.current);
        }
      }
    );
  }, [clientToken, klarnaIntegrationClient]);

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    dataKlarna
      .initAccountInformationSession(bank.bankCode, accountTypes)
      .then(() => {
        timer.current = setTimeout(poll, POLL_TIMER);
      })
      .catch(() => {
        EventTracker.track({
          event: TrackerEvent.ERROR,
          message: "Kunde inte hämta konton från Klarna",
        });
        setKlarnaState(KlarnaState.ERROR);
      });
    /* stringify in dep array to not rerender and initiate multiple Klarna sessions */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bank, poll, JSON.stringify(accountTypes)]);

  if (klarnaState === KlarnaState.ERROR) {
    dataCustomerTrackingService.postEvent(
      featureTrackingManualBackup(location.pathname)
    );

    return (
      <div>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <div>
            <h2>
              <TranslatedText id="klarna.account-loading.error-header" />
            </h2>
            <p>
              <TranslatedText id="klarna.account-loading.error-body" />
            </p>
          </div>
        </Snackbar>
        {fallbackOptions && (
          <Button
            variant="primary"
            block
            component={Link}
            to={fallbackOptions.locationDescriptor}
            label={fallbackOptions.buttonText}
          />
        )}
      </div>
    );
  }

  return (
    <div>
      <Spinner />
    </div>
  );
}

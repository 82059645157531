import { Story } from "@lysaab/ui-2";
import { useContext, useEffect, VoidFunctionComponent } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useLocation, Switch, useHistory } from "react-router";
import { Route } from "../../../../components/route/Route";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../../../hooks/useSafeNavigation";
import { OVERVIEW_PAGE_URL } from "../../../../pages/overview/OverviewPage";
import { PageStripped } from "../../../../pages//PageStripped";
import {
  CreateAccountContext,
  CreateAccountContextProvider,
} from "../../../../pages/createAccount/CreateAccountContext";
import { AdviseWrapper } from "../../../../storyComponents/createAccount/advise/AdviseWrapper";
import { Done } from "../../../../storyComponents/createAccount/done/Done";
import { HorizonWrapper } from "../../../../storyComponents/createAccount/horizon/HorizonWrapper";
import { Kyc } from "../../../../storyComponents/createAccount/kyc/Kyc";
import { RiskWarningWrapper } from "../../../../storyComponents/createAccount/riskWarning/RiskWarningWrapper";
import { ConfirmationWrapper } from "../../../../storyComponents/createAccount/confirmation/ConfirmationWrapper";
import { EditAllocationWrapper } from "../../../../storyComponents/createAccount/editAllocation/EditAllocationWrapper";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import { LegalEntityType } from "../../../../data/dataLogin";
import { AccountType } from "../../../../data/dataAccounts";
import { UserContext } from "../../../../context/UserContext";
import { UpdateCrs } from "./updateCrs/UpdateCrs";
import { CrsContextProvider } from "../updateCrsStory/context/CrsContext";
import { useStoryValues } from "../../../../hooks/useStoryValues";
import { LysaCountry } from "@lysaab/shared";
import { Sustainability } from "./account/sustainability/Sustainability";
import { SustainabilityImportantQuestions } from "./account/sustainabilityImportantQuestions/SustainabilityImportantQuestions";
import {
  dataInvestments,
  getAccountQuestions,
  GetSuitabilityAssessmentRequest,
  isSustainabilityImportantSpecific,
  isValidGetSuitabilityAssessmentRequest,
  SustainabilityImportance,
} from "../../../../data/dataInvestments";
import { Preference } from "./account/preference/Preference";
import { ConfirmEsgUpdateWrapper } from "./account/confirmEsgUpdateWrapper/ConfirmEsgUpdateWrapper";
import { SuitabilityDownloadLazy } from "../../../../pageComponents/advise/SuitabilityDownload";

export const CREATE_INVESTMENT_ACCOUNT_URL = "/create-account";

export const BASE_ROUTES = {
  HORIZON: `${CREATE_INVESTMENT_ACCOUNT_URL}/`,
  KYC: `${CREATE_INVESTMENT_ACCOUNT_URL}/kyc`,
  UPDATE_CRS: `${CREATE_INVESTMENT_ACCOUNT_URL}/crs`,
  SUSTAINABILITY: `${CREATE_INVESTMENT_ACCOUNT_URL}/sustainability`,
  PREFERENCE: `${CREATE_INVESTMENT_ACCOUNT_URL}/preference`,
  SUSTAINABILITY_QUESTIONS: `${CREATE_INVESTMENT_ACCOUNT_URL}/sustainability-questions`,
  CONFIRM_ESG_UPDATE: `${CREATE_INVESTMENT_ACCOUNT_URL}/confirm-esg-update`,
  ADVICE: `${CREATE_INVESTMENT_ACCOUNT_URL}/advice`,
  EDIT_ALLOCATION: `${CREATE_INVESTMENT_ACCOUNT_URL}/edit-allocation`,
  RISK_WARNING: `${CREATE_INVESTMENT_ACCOUNT_URL}/risk-warning`,
  CONFIRM: `${CREATE_INVESTMENT_ACCOUNT_URL}/confirm`,
  DONE: `${CREATE_INVESTMENT_ACCOUNT_URL}/done`,
};

const messages = defineMessages({
  header: { id: "sweden.create-account.story.header" },
  accountNameIsk: { id: "sweden.create-account.story.account-name.isk" },
  accountNameVp: { id: "sweden.create-account.story.account-name.vp" },
  accountNameVpSwe: { id: "sweden.create-account.story.account-name.vp_swe" },
  ariaProgressLabel: { id: "sweden.create-account.story.ariaProgressLabel" },
});

const CreateAccountStoryInstance: VoidFunctionComponent = () => {
  const location = useLocation();
  const safeNavigation = useSafeNavigation();
  const intl = useIntl();
  const history = useHistory();
  const userContext = useContext(UserContext);
  const createAccountContext = useContext(CreateAccountContext);
  const localizationContext = useContext(LocalizationContext);
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);

  const onBack = () => {
    history.goBack();
  };

  if (
    !userContext.state.legalEntityType ||
    !localizationContext.state.country
  ) {
    return null;
  }

  return (
    <PageStripped>
      <div className="create-account-story">
        <Story
          ariaLabelProgress={() =>
            intl.formatMessage(messages.ariaProgressLabel, {
              current: currentIndex + 1,
              total: storyLength,
            })
          }
          header={intl.formatMessage(messages.header)}
          progress={storyProgress}
          showBack={
            currentIndex > 0 && currentIndex < Object.values(ROUTES).length - 1
          }
          showClose={true}
          transitionKey={currentIndex.toString()}
          onExit={() => {
            safeNavigation(getNavLink(OVERVIEW_PAGE_URL));
          }}
          onBack={onBack}
        >
          <Switch
            location={location}
            {...{
              order: currentIndex,
            }}
          >
            <Route exact path={ROUTES.HORIZON}>
              <HorizonWrapper next={() => safeNavigation(ROUTES.KYC)} />
            </Route>
            <Route exact path={ROUTES.KYC}>
              <Kyc
                next={() => {
                  if (
                    userContext.state.legalEntityType === LegalEntityType.PERSON
                  ) {
                    safeNavigation(ROUTES.UPDATE_CRS);
                  } else {
                    safeNavigation(ROUTES.SUSTAINABILITY);
                  }
                }}
              />
            </Route>
            <Route exact path={ROUTES.UPDATE_CRS}>
              <UpdateCrs next={() => safeNavigation(ROUTES.SUSTAINABILITY)} />
            </Route>
            <Route exact path={ROUTES.SUSTAINABILITY}>
              <Sustainability
                next={() => {
                  if (
                    createAccountContext.state.sustainability ===
                    SustainabilityImportance.IMPORTANT
                  ) {
                    safeNavigation(ROUTES.PREFERENCE);
                  } else {
                    safeNavigation(ROUTES.ADVICE);
                  }
                }}
              />
            </Route>
            <Route exact path={ROUTES.PREFERENCE}>
              <Preference
                next={() => {
                  if (
                    isSustainabilityImportantSpecific(
                      createAccountContext.state
                    )
                  ) {
                    safeNavigation(ROUTES.SUSTAINABILITY_QUESTIONS);
                  } else {
                    safeNavigation(ROUTES.ADVICE);
                  }
                }}
              />
            </Route>
            <Route exact path={ROUTES.SUSTAINABILITY_QUESTIONS}>
              <SustainabilityImportantQuestions
                next={() => {
                  const data: Partial<GetSuitabilityAssessmentRequest> = {
                    language: localizationContext.state.language,
                    ...getAccountQuestions(createAccountContext.state),
                  };

                  if (!isValidGetSuitabilityAssessmentRequest(data)) {
                    throw new Error("AdviceWrapper - Missing data object");
                  }

                  dataInvestments
                    .getNewAccountSuitability(data)
                    .then((advise) => {
                      if (advise.esgResult.esgBestMatch) {
                        safeNavigation(ROUTES.CONFIRM_ESG_UPDATE);
                      } else {
                        safeNavigation(ROUTES.ADVICE);
                      }
                    });
                }}
              />
            </Route>
            <Route exact path={ROUTES.CONFIRM_ESG_UPDATE}>
              <ConfirmEsgUpdateWrapper
                next={() => safeNavigation(ROUTES.ADVICE)}
              />
            </Route>
            <Route exact path={ROUTES.ADVICE}>
              <AdviseWrapper
                next={() => safeNavigation(ROUTES.CONFIRM)}
                navigateToEditAllocation={() =>
                  safeNavigation(ROUTES.EDIT_ALLOCATION)
                }
                navigateToFees={() =>
                  safeNavigation(`${ROUTES.EDIT_ALLOCATION}?fees`)
                }
              />
            </Route>
            <Route exact path={ROUTES.EDIT_ALLOCATION}>
              <EditAllocationWrapper
                next={() => safeNavigation(ROUTES.CONFIRM)}
                nextRiskWarning={() => safeNavigation(ROUTES.RISK_WARNING)}
              />
            </Route>
            <Route exact path={ROUTES.RISK_WARNING}>
              <RiskWarningWrapper next={() => safeNavigation(ROUTES.CONFIRM)} />
            </Route>
            <Route exact path={ROUTES.CONFIRM}>
              <ConfirmationWrapper
                showInvestmentType={true}
                next={() => safeNavigation(ROUTES.DONE)}
              />
            </Route>
            <Route exact path={ROUTES.DONE}>
              <Done />
            </Route>
          </Switch>
        </Story>
      </div>
    </PageStripped>
  );
};

export const CreateAccountStory: VoidFunctionComponent = () => {
  const intl = useIntl();
  const userContext = useContext(UserContext);
  const localizationContext = useContext(LocalizationContext);

  useEffect(() => {
    // Preload the suitability PDF component early since it may reload the page which would result in loss of state
    SuitabilityDownloadLazy.preload();
  }, []);

  if (
    !userContext.state.legalEntityType ||
    !localizationContext.state.country
  ) {
    return null;
  }

  const accountName: Record<
    AccountType.VP | AccountType.ISK_SWE | AccountType.VP_SWE,
    string
  > = {
    [AccountType.VP]: intl.formatMessage(messages.accountNameVp),
    [AccountType.VP_SWE]: intl.formatMessage(messages.accountNameVpSwe),
    [AccountType.ISK_SWE]: intl.formatMessage(messages.accountNameIsk),
  };
  const accountType = getAccountType(
    userContext.state.legalEntityType,
    localizationContext.state.country
  );

  return (
    <CreateAccountContextProvider
      accountName={accountName[accountType]}
      accountType={accountType}
    >
      <CrsContextProvider>
        <CreateAccountStoryInstance />
      </CrsContextProvider>
    </CreateAccountContextProvider>
  );
};

const accountTypes: Record<LysaCountry, AccountType.VP | AccountType.ISK_SWE> =
  {
    [LysaCountry.DENMARK]: AccountType.VP,
    [LysaCountry.FINLAND]: AccountType.VP,
    [LysaCountry.SWEDEN]: AccountType.ISK_SWE,
    [LysaCountry.GERMANY]: AccountType.VP,
    [LysaCountry.SPAIN]: AccountType.VP,
  };

function getAccountType(
  legalEntityType: LegalEntityType,
  country: LysaCountry
) {
  if (legalEntityType === LegalEntityType.CORPORATION) {
    return AccountType.VP_SWE;
  }
  return accountTypes[country];
}

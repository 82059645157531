import { FC, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Form, LysaFormRef } from "@lysaab/ui-2";
import {
  EsgQuestions,
  PensionAdviseAccount,
  SustainabilityPreference,
} from "../../../data/dataInvestments";
import { UpdateSustainabilityPreferenceLocationState } from "../updateSustainabilityPreferenceWrapper/UpdateSustainabilityPreferenceWrapper";
import { SFDRQuestion } from "../../../pageComponents/sustainability/SFDRQuestion";
import { TaxonomyQuestion } from "../../../pageComponents/sustainability/TaxonomyQuestion";
import { PAIQuestion } from "../../../pageComponents/sustainability/PAIQuestion";

export interface UpdateSustainabilityEsgLocationState
  extends UpdateSustainabilityPreferenceLocationState {
  sustainabilityPreference: SustainabilityPreference.SPECIFIC;
}

interface Props {
  locationState: UpdateSustainabilityEsgLocationState;
  advise: PensionAdviseAccount;
  onNext: (
    value: EsgQuestions,
    locationState: UpdateSustainabilityEsgLocationState
  ) => void;
}

export const UpdateSustainabilityEsgWrapper: FC<Props> = ({
  locationState,
  advise: { esgResult },
  onNext,
}) => {
  const intl = useIntl();
  const formRef = useRef<LysaFormRef>();

  const [sfdr, setSfdr] = useState(esgResult?.esgAnswers.sfdr);
  const [taxonomy, setTaxonomy] = useState(esgResult?.esgAnswers.taxonomy);
  const [pai, setPai] = useState(esgResult?.esgAnswers?.pai);

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={() => {
        if (formRef.current?.isValid && sfdr && taxonomy && pai) {
          onNext({ sfdr, taxonomy, pai }, locationState);
        }
      }}
    >
      <SFDRQuestion
        sfdr={sfdr}
        setSfdr={({ value }) => {
          setSfdr(value);
        }}
      />
      <TaxonomyQuestion
        taxonomy={taxonomy}
        setTaxonomy={({ value }) => {
          setTaxonomy(value);
        }}
      />
      <PAIQuestion
        pai={pai}
        setPai={({ value }) => {
          setPai(value);
        }}
      />

      <Button
        block
        type="submit"
        label={intl.formatMessage({
          id: "updatePensionStory.updateSustainabilityEsgWrapper.next-button",
        })}
      />
    </Form>
  );
};

import type { FunctionComponent } from "react";
import { WithdrawalItem } from "../../../../../components/withdrawalItem/WithdrawalItem";
import {
  getAccountAmount,
  InvestmentAccount,
} from "../../../../../data/dataAccounts";
import { Withdrawal } from "../../../../../data/dataWithdrawals";
import { CancelPendingInvestmentWithdrawal } from "../../../../overview/pendingTransactions/CancelPendingInvestmentWithdrawal";

interface Props {
  account: InvestmentAccount;
  withdrawal: Withdrawal;
  onWithdrawalCancelled: () => void;
}

export const PendingInvestmentWithdrawal: FunctionComponent<Props> = ({
  withdrawal,
  account,
  onWithdrawalCancelled,
}) => {
  return (
    <div className="pending-withdrawal">
      <WithdrawalItem
        date={new Date(withdrawal.requested)}
        name={account.name}
        moneyOnAccount={getAccountAmount(account)}
        externalBank={withdrawal.bank}
        externalBankAccount={withdrawal.externalBankAccount}
        withdrawalAmount={
          withdrawal.drain
            ? getAccountAmount(account).toString()
            : withdrawal.amount.toString()
        }
      />
      <CancelPendingInvestmentWithdrawal
        withdrawal={withdrawal}
        onWithdrawalCancelled={onWithdrawalCancelled}
      />
    </div>
  );
};

import { FC, useRef, useState } from "react";
import { useIntl } from "react-intl";
import {
  Alternative,
  Button,
  CircleButton,
  Form,
  LysaFormRef,
  Select,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Typography,
} from "@lysaab/ui-2";
import { FlatButton } from "../../../components/flatButton/FlatButton";
import { usePensionAllocation } from "../../../hooks/usePensionAllocation";
import { getUserAge } from "../../../utils/userAge";
import { useUser } from "../../../context/UserContext";
import { GlidePathGraph } from "../../../components/glidePathGraph/GlidePathGraph";
import { TranslatedText } from "../../../components/TranslatedText";
import {
  PensionQuestions,
  PensionWithdrawalDuration,
} from "../../../data/dataInvestments";
import { Modal } from "../../../components/modal/Modal";

import "./UpdateWithdrawalPlan.scss";

// TODO: Move consts to central config.
// TODO: Select does not support designed header.

const UP_TO_MAX_AGE = 100; // Withdrawal age + withdrawal years can not exceed this value.

const DEFAULT_WITHDRAWAL_YEARS = 10;
const MIN_WITHDRAWAL_YEARS = 5;
const MAX_WITHDRAWAL_YEARS = 40;
const LIFE_LONG_WITHDRAWAL = MAX_WITHDRAWAL_YEARS + 1;

const DEFAULT_WITHDRAWAL_AGE = 65;
const MAX_WITHDRAWAL_AGE = 80;

interface Props {
  withdrawalAge: number;
  withdrawalDuration: PensionWithdrawalDuration;
  takenRiskDeviation: number;
  repaymentProtection: boolean;
  minWithdrawalAge: number;
  action: "next" | "save";
  toRepaymentProtection: () => void;
  onNext: (value: PensionQuestions) => void;
}

export const UpdateWithdrawalPlan: FC<Props> = ({
  withdrawalAge: currentWithdrawalAge,
  withdrawalDuration: currentWithdrawalDuration,
  takenRiskDeviation,
  repaymentProtection,
  minWithdrawalAge,
  action,
  toRepaymentProtection,
  onNext,
}) => {
  const intl = useIntl();
  const formRef = useRef<LysaFormRef>();

  const [showDurationModal, setShowDurationModal] = useState(false);

  const [withdrawalAge, setWithdrawalAge] = useState(currentWithdrawalAge);
  const [withdrawalDuration, setWithdrawalDuration] = useState(
    currentWithdrawalDuration
  );

  const user = useUser();
  const age = getUserAge(user.tin, user.country);
  const { allocation, status } = usePensionAllocation({
    age,
    withdrawalAge,
    takenRiskDeviation,
    ...withdrawalDuration,
  });

  const withdrawalYearsAlternatives = generateYearArray(
    MIN_WITHDRAWAL_YEARS,
    MAX_WITHDRAWAL_YEARS
  ).map(
    (value): Alternative<number> => ({
      disabled: value > UP_TO_MAX_AGE - withdrawalAge,
      text:
        value === DEFAULT_WITHDRAWAL_YEARS
          ? intl.formatMessage(
              {
                id: "storyComponents.updatePension.updateWithdrawalPlan.select.option-default",
              },
              { value }
            )
          : intl.formatMessage(
              {
                id: "storyComponents.updatePension.updateWithdrawalPlan.select.option",
              },
              { value }
            ),
      value,
    })
  );
  withdrawalYearsAlternatives.push({
    text: intl.formatMessage({
      id: "storyComponents.updatePension.updateWithdrawalPlan.select.option-life-long",
    }),
    value: LIFE_LONG_WITHDRAWAL,
    disabled: repaymentProtection,
  });

  const withdrawalAgeAlternatives = generateYearArray(
    minWithdrawalAge,
    MAX_WITHDRAWAL_AGE
  ).map(
    (value): Alternative<number> => ({
      disabled: withdrawalDuration.lifeLong
        ? false
        : value > UP_TO_MAX_AGE - withdrawalDuration.withdrawalMonths / 12,
      text:
        value === DEFAULT_WITHDRAWAL_AGE
          ? intl.formatMessage(
              {
                id: "storyComponents.updatePension.updateWithdrawalPlan.select.option-default",
              },
              { value }
            )
          : intl.formatMessage(
              {
                id: "storyComponents.updatePension.updateWithdrawalPlan.select.option",
              },
              { value }
            ),
      value,
    })
  );

  if (status !== "success") {
    return <Spinner />;
  }

  return (
    <section className="UpdateWithdrawalPlan">
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (formRef.current?.isValid) {
            onNext({ withdrawalAge, ...withdrawalDuration });
          }
        }}
      >
        <div className="graph-wrapper">
          <GlidePathGraph
            glidePath={allocation.glidePath}
            reallocationAge={allocation.reallocationAge}
            withdrawalAge={allocation.withdrawalAge}
          />
        </div>
        <Typography>
          <TranslatedText id="storyComponents.updatePension.updateWithdrawalPlan.body" />
        </Typography>

        <Typography type="h4">
          <TranslatedText id="storyComponents.updatePension.updateWithdrawalPlan.age.header" />
        </Typography>
        <Select
          label=""
          placeholder={intl.formatMessage({
            id: "storyComponents.updatePension.updateWithdrawalPlan.age.placeholder",
          })}
          alternatives={withdrawalAgeAlternatives}
          value={withdrawalAgeAlternatives.find(
            ({ value }) => value === withdrawalAge
          )}
          onChange={({ value }) => {
            setWithdrawalAge(value);
          }}
        />

        <Typography type="h4" className="withdrawal-duration-header">
          <TranslatedText id="storyComponents.updatePension.updateWithdrawalPlan.period.header" />
          <CircleButton
            icon="InformationOutline"
            onClick={() => {
              setShowDurationModal(true);
            }}
          />
        </Typography>
        <Select
          label=""
          placeholder={intl.formatMessage({
            id: "storyComponents.updatePension.updateWithdrawalPlan.period.placeholder",
          })}
          alternatives={withdrawalYearsAlternatives}
          value={withdrawalYearsAlternatives.find(({ value }) => {
            const comparisonValue = withdrawalDuration.lifeLong
              ? LIFE_LONG_WITHDRAWAL
              : withdrawalDuration.withdrawalMonths / 12;

            return value === comparisonValue;
          })}
          onChange={({ value }) => {
            if (value === LIFE_LONG_WITHDRAWAL) {
              setWithdrawalDuration({
                withdrawalMonths: undefined,
                lifeLong: true,
              });
            } else {
              setWithdrawalDuration({
                withdrawalMonths: value * 12,
                lifeLong: false,
              });
            }
          }}
        />

        <FlatButton
          title={intl.formatMessage({
            id: "storyComponents.updatePension.updateWithdrawalPlan.repaymentProtection",
          })}
          value={
            repaymentProtection
              ? intl.formatMessage({
                  id: "storyComponents.updatePension.updateWithdrawalPlan.repaymentProtection.yes",
                })
              : intl.formatMessage({
                  id: "storyComponents.updatePension.updateWithdrawalPlan.repaymentProtection.no",
                })
          }
          onClick={() => {
            toRepaymentProtection();
          }}
        />

        {repaymentProtection && (
          <div className="repayment-disclaimer">
            <Snackbar type={SNACKBAR_TYPES.INFO} icon textAlign="left">
              <TranslatedText id="storyComponents.updatePension.updateWithdrawalPlan.repayment-protection-disclaimer" />
            </Snackbar>
          </div>
        )}

        <div className="submit-button-container">
          <Button
            block
            type="submit"
            label={
              action === "save"
                ? intl.formatMessage({
                    id: "storyComponents.updatePension.updateWithdrawalPlan.button-save",
                  })
                : intl.formatMessage({
                    id: "storyComponents.updatePension.updateWithdrawalPlan.button-next",
                  })
            }
          />
        </div>
      </Form>

      <Modal
        header={intl.formatMessage({
          id: "storyComponents.updatePension.updateWithdrawalPlan.durationModal.header",
        })}
        showModal={showDurationModal}
        closeOnOverlayClick
        onClose={() => {
          setShowDurationModal(false);
        }}
      >
        <Typography>
          <TranslatedText id="storyComponents.updatePension.updateWithdrawalPlan.durationModal.body" />
        </Typography>
      </Modal>
    </section>
  );
};

function generateYearArray(start: number, end: number): number[] {
  const years = [];
  for (let i = start; i <= end; i++) {
    years.push(i);
  }
  return years;
}

import { useEffect, useCallback, FC } from "react";
import { useLocation } from "react-router";
import { Modal } from "../../modal/Modal";
import { Language, LysaCountry } from "@lysaab/shared";
import { useUser } from "../../../context/UserContext";
import { dataTink, accountVerificationReportId } from "../../../data/dataTink";
import { AUTOGIRO_DEPOSIT_PAGE_URL } from "../../../countries/sweden/pages/deposits/Autogiro/deposit/AutogiroDepositPage";
import { MONTHLY_DEPOSITS_URL } from "../../../pages/deposits/monthly/create/MonthlyStory";

import "./TinkIntegrationClient.scss";
import { ADD_EXTERNAL_KLARNA_URL } from "../../../pages/withdrawal/addAccountKlarna/AddAccountKlarnaStory";
import { EventTracker } from "../../eventTracker/EventTracker";
import { TrackerEvent } from "../../../data/dataCustomerTracking";

interface TinkIntegrationClientProps {
  clientToken: string;
  sessionId: string;
  providerName: string;
  onSuccess: (result: any, accountVerificationReportId: string) => void;
  onError: (error: any) => void;
}

const lysaCountryToTinkMarket: Record<LysaCountry, string> = {
  [LysaCountry.DENMARK]: "DK",
  [LysaCountry.FINLAND]: "FI",
  [LysaCountry.GERMANY]: "DE",
  [LysaCountry.SWEDEN]: "SE",
  [LysaCountry.SPAIN]: "ES",
};

const lysaCountryToTinkLocale: Record<Language, string> = {
  [Language.ENGLISH]: "en_US",
  [Language.GERMAN]: "de_DE",
  [Language.SWEDISH]: "sv_SE",
};

type REFRESHABLE_ITEMS = "CHECKING_ACCOUNTS" | "SAVING_ACCOUNTS";
type ACCOUNT_PURPOSE = "DEPOSIT" | "MONTHLY_SAVINGS" | "WITHDRAWAL";

// todo: use pre-defined routes
const parseAccountPurposeFromLocation = (pathname: string): ACCOUNT_PURPOSE => {
  if (pathname.includes(AUTOGIRO_DEPOSIT_PAGE_URL)) return "DEPOSIT";
  if (pathname.includes(MONTHLY_DEPOSITS_URL)) return "MONTHLY_SAVINGS";
  if (pathname.includes(ADD_EXTERNAL_KLARNA_URL)) return "WITHDRAWAL";
  return "DEPOSIT";
};

function getRefreshableItems(
  country: LysaCountry,
  purpose: ACCOUNT_PURPOSE
): REFRESHABLE_ITEMS[] {
  if (country === LysaCountry.SWEDEN) {
    if (purpose === "DEPOSIT" || purpose === "MONTHLY_SAVINGS") {
      return ["CHECKING_ACCOUNTS"];
    }
    if (purpose === "WITHDRAWAL") {
      return ["CHECKING_ACCOUNTS", "SAVING_ACCOUNTS"];
    }
  }

  if (country === LysaCountry.FINLAND || country === LysaCountry.GERMANY) {
    if (purpose === "MONTHLY_SAVINGS" || purpose === "WITHDRAWAL") {
      return ["CHECKING_ACCOUNTS", "SAVING_ACCOUNTS"];
    }
  }

  return ["CHECKING_ACCOUNTS"];
}

const tinkLinkBuilder = (
  clientToken: string,
  sessionId: string,
  providerName: string,
  market: string,
  locale: string,
  refreshableItems: REFRESHABLE_ITEMS[]
) => {
  const baseUrl = "https://link.tink.com/1.0/reports/create-report";
  const params = new URLSearchParams({
    client_id: clientToken,
    session_id: sessionId,
    input_provider: providerName,
    iframe: "true",
    redirect_uri: process.env.REACT_APP_TINK_REDIRECT_URI || "",
    market,
    locale,
    report_types: "ACCOUNT_VERIFICATION_REPORT",
    account_dialog_type: "NONE",
  });

  if (refreshableItems.length > 0) {
    params.append("refreshable_items", refreshableItems.join(","));
  }

  return `${baseUrl}?${params.toString()}`;
};

const TinkIntegrationClient: FC<TinkIntegrationClientProps> = ({
  clientToken,
  sessionId,
  providerName,
  onSuccess,
  onError,
}) => {
  const user = useUser();
  const location = useLocation();

  const accountPurpose = parseAccountPurposeFromLocation(location.pathname);
  const market = lysaCountryToTinkMarket[user?.country as LysaCountry];
  const locale = lysaCountryToTinkLocale[user?.language as Language];
  const refreshableItems = getRefreshableItems(
    user?.country as LysaCountry,
    accountPurpose
  );

  const getAccounts = useCallback(
    (accountVerificationReportId: accountVerificationReportId) => {
      dataTink
        .getAccounts(accountVerificationReportId)
        .then((resp) => {
          onSuccess(resp, accountVerificationReportId);
        })
        .catch((err) => {
          onError(err);
        });
    },
    [onSuccess, onError]
  );

  const handleMessage = useCallback(
    (event: MessageEvent) => {
      const data =
        typeof event.data === "string" ? JSON.parse(event.data) : event.data;

      const { type, data: messageData, error } = data;

      if (
        type === "reports" &&
        messageData &&
        messageData.account_verification_report_id
      ) {
        getAccounts(messageData.account_verification_report_id);
      } else if (error) {
        EventTracker.track({
          event: TrackerEvent.TINK_ERROR,
          message: error,
        });
        onError(error);
      }
    },
    [getAccounts, onError]
  );

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [clientToken, sessionId, providerName, handleMessage]);

  return (
    <Modal showModal={true} hideHeader className="tink-modal">
      <iframe
        className="tink-modal__iframe"
        src={tinkLinkBuilder(
          clientToken,
          sessionId,
          providerName,
          market,
          locale,
          refreshableItems
        )}
        sandbox="allow-scripts allow-same-origin allow-popups allow-forms allow-popups-to-escape-sandbox allow-top-navigation allow-top-navigation-by-user-activation"
        title="Tink Link"
      />
    </Modal>
  );
};

export default TinkIntegrationClient;

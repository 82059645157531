import { FunctionComponent, useCallback } from "react";

import "./DigitalSigningInstructions.scss";
import {
  UiScriveStatus,
  useScriveStatusForCase,
} from "../../digitalSigning/useScrive";
import { SignAction } from "../../digitalSigning/SignMove";
import { ActivePensionMoveInResponse } from "../../../../../../data/dataLifePensionMove";
import { Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../../components/TranslatedText";

interface Props {
  move: ActivePensionMoveInResponse;
}

export const DigitalSigningInstructions: FunctionComponent<Props> = ({
  move,
}) => {
  const onSigningStarted = useCallback((status: UiScriveStatus) => {
    window.location.href = status.signingUrl;
  }, []);

  const { data: scriveStatus, startSigning } = useScriveStatusForCase({
    caseId: move.caseId,
    onSigningStarted,
  });

  const status = scriveStatus?.find((status) => status.accountId === move.id);

  if (!status || status.uiStatus === "SIGNED") {
    return null;
  }

  return (
    <section className="DigitalSigningInstructions">
      <Typography type="body">
        <TranslatedText
          id={
            "sweden.transfer-pension.digital-signing.digital-signing-instructions.information"
          }
        />
      </Typography>
      <SignAction
        move={move}
        status={status}
        startSigning={startSigning.mutate}
        isNextToSign={true}
      />
    </section>
  );
};

import {
  Alternative,
  Card,
  Form,
  LysaFormRef,
  Button,
  RadioGroup,
  RequiredValidator,
  ServerError,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { useContext, useEffect, useRef, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import { useHistory } from "react-router";
import { TranslatedText } from "../../../../components/TranslatedText";
import { KlarnaAccount } from "../../../../data/dataKlarna";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { AddAccountKlarnaContext } from "../AddAccountKlarnaContext";
import { ADD_EXTERNAL_KLARNA_URL, BASE_ROUTES } from "../AddAccountKlarnaStory";
import { dataWithdrawals } from "../../../../data/dataWithdrawals";
import {
  Currency,
  LocalizationContext,
} from "../../../../context/LocalizationContext";
import { AccountSelectionError } from "./components/AccountSelectionError";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
} from "../../../../data/dataCustomerTracking";

interface Props {
  next: () => void;
}

export function AccountSelection({ next }: Props) {
  const klarnaContext = useContext(AddAccountKlarnaContext);
  const history = useHistory();
  const intl = useIntl();
  const selectedKlarnaAccount = klarnaContext.state.selectedAccount;
  const formRef = useRef<LysaFormRef>();
  const [isLoading, setIsLoading] = useState(false);
  const localizationContext = useContext(LocalizationContext);
  const [error, setError] = useState<ServerError<unknown>>();

  useEffect(() => {
    if (!klarnaContext.state.klarnaAccounts) {
      history.push(getNavLink(ADD_EXTERNAL_KLARNA_URL));
    }
  }, [history, klarnaContext.state.klarnaAccounts]);

  useEffect(() => {
    if (klarnaContext.state.klarnaAccounts) {
      dataCustomerTrackingService.postEvent({
        domain: FeatureDomain.TRANSFERS,
        subDomain: SubDomain.WITHDRAWAL,
        eventName: "withdrawalAccountSelection",
      });
    }
  }, [klarnaContext.state.klarnaAccounts]);

  if (!klarnaContext.state.klarnaAccounts || isLoading) {
    return <Spinner />;
  }

  if (klarnaContext.state.klarnaAccounts.length === 0) {
    return (
      <div>
        <h2>
          <TranslatedText id="widthdrawals.account.klarna.story.account-selection.no-accounts.header" />
        </h2>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <p>
            <TranslatedText id="widthdrawals.account.klarna.story.account-selection.no-accounts.text" />
          </p>
        </Snackbar>
        <Button
          block
          onClick={() => history.push(getNavLink(BASE_ROUTES.BANK_SELECTION))}
          label={
            <TranslatedText id="widthdrawals.account.klarna.story.account-selection.no-accounts.button" />
          }
        />
      </div>
    );
  }

  return (
    <div>
      <h2>
        <TranslatedText id="widthdrawals.account.klarna.story.account-selection.header" />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (
            !formRef.current?.isValid ||
            !selectedKlarnaAccount ||
            typeof error !== "undefined"
          ) {
            return;
          }

          setIsLoading(true);

          dataWithdrawals
            .addWithdrawalAccountKlarna([selectedKlarnaAccount.accountJWT])
            .then(() => {
              setIsLoading(false);
              next();
            })
            .catch((error: ServerError<unknown>) => {
              setError(error);
              setIsLoading(false);
            });
        }}
      >
        <Card>
          {error && <AccountSelectionError error={error} />}

          <RadioGroup
            alternatives={klarnaContext.state.klarnaAccounts.map(
              (account): Alternative<string> => {
                return {
                  text: getAccountLabel(
                    account,
                    intl,
                    localizationContext.state.currency
                  ),
                  value: account.id,
                };
              }
            )}
            value={
              selectedKlarnaAccount
                ? {
                    text: getAccountLabel(
                      selectedKlarnaAccount,
                      intl,
                      localizationContext.state.currency
                    ),
                    value: selectedKlarnaAccount.id,
                  }
                : undefined
            }
            header={intl.formatMessage({
              id: "widthdrawals.account.klarna.story.account-selection.account.label",
            })}
            onChange={(alt) => {
              if (error) {
                setError(undefined);
              }
              klarnaContext.setState({
                selectedAccount: klarnaContext.state.klarnaAccounts?.find(
                  (account) => account.id === alt.value
                ),
              });
            }}
            validators={[
              new RequiredValidator(
                intl.formatMessage({
                  id: "widthdrawals.account.klarna.story.account-selection.account.required",
                })
              ),
            ]}
          />
        </Card>
        <Button
          type="submit"
          block
          label={
            <TranslatedText id="widthdrawals.account.klarna.story.account-selection.button" />
          }
        />
      </Form>
    </div>
  );
}

function getAccountLabel(
  account: KlarnaAccount,
  intl: IntlShape,
  currency: Currency
) {
  return account.balance === 0
    ? account.name
    : `${account.name} - ${intl.formatNumber(account.balance, {
        currency: currency,
        style: "currency",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
}

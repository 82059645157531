import { useCallback, useEffect, useState } from "react";
import {
  AccountType,
  AllAccountResponse,
  InvestmentAccount,
  SavingsAccount,
} from "../../../../../data/dataAccounts";
import {
  dataWithdrawals,
  ExternalPendingSavingsWithdrawalResponse,
  Withdrawal,
} from "../../../../../data/dataWithdrawals";
import { useAccounts } from "../../../../../hooks/useAccounts";
import { PendingInvestmentWithdrawal } from "./PendingInvestmentWithdrawal";
import { PendingSavingsWithdrawal } from "./PendingSavingsWithdrawal";
import { DateTime } from "luxon";

const excludePendingKFWithdrawals = (
  pendingWithdrawals: Withdrawal[],
  accounts: InvestmentAccount[]
) =>
  pendingWithdrawals.filter((pendingItem) => {
    const account = accounts.find(
      (accountItem) => accountItem.accountId === pendingItem.accountId
    );
    return account?.type !== AccountType.DANICA_KF;
  });

const sortWithdrawals = (
  withdrawalA: { requested: string },
  withdrawalB: { requested: string }
) =>
  DateTime.fromISO(withdrawalA.requested)
    .diff(DateTime.fromISO(withdrawalB.requested))
    .toMillis();

interface MergedInvestmentWithdrawal {
  requested: string;
  withdrawalType: "INVESTMENT";
  withdrawal: Withdrawal;
  account: InvestmentAccount;
}

interface MergedSavingsWithdrawal {
  requested: string;
  withdrawalType: "SAVINGS";
  withdrawal: ExternalPendingSavingsWithdrawalResponse;
  account: SavingsAccount;
}

type MergedWithdrawal = MergedInvestmentWithdrawal | MergedSavingsWithdrawal;

const isInvestmentWithdrawal = (
  withdrawal: MergedWithdrawal
): withdrawal is MergedInvestmentWithdrawal =>
  withdrawal.withdrawalType === "INVESTMENT";

const isSavingsWithdrawal = (
  withdrawal: MergedWithdrawal
): withdrawal is MergedSavingsWithdrawal =>
  withdrawal.withdrawalType === "SAVINGS";

const mergeWithdrawals = (
  investmentWithdrawals: Withdrawal[],
  savingsWithdrawals: ExternalPendingSavingsWithdrawalResponse[],
  accounts: AllAccountResponse
): MergedWithdrawal[] => {
  const investments: MergedInvestmentWithdrawal[] = [];
  investmentWithdrawals.forEach((withdrawal) => {
    const account = accounts.investmentAccounts.find(
      (account) => account.accountId === withdrawal.accountId
    );
    if (account) {
      investments.push({
        requested: withdrawal.requested,
        withdrawalType: "INVESTMENT",
        withdrawal,
        account,
      });
    }
  });

  const savings: MergedSavingsWithdrawal[] = [];
  savingsWithdrawals.forEach((withdrawal) => {
    const account = accounts.savingsAccounts.find(
      (account) => account.accountId === withdrawal.accountId
    );
    if (account) {
      savings.push({
        requested: withdrawal.requested,
        withdrawalType: "SAVINGS",
        withdrawal,
        account,
      });
    }
  });

  return [...investments, ...savings].sort(sortWithdrawals);
};

export const PendingAccountWithdrawals = () => {
  const { accounts } = useAccounts();
  const [pendingWithdrawals, setPendingWithdrawals] = useState<Withdrawal[]>(
    []
  );
  const [
    pendingSavingsAccountWithdrawals,
    setPendingSavingsAccountWithdrawals,
  ] = useState<ExternalPendingSavingsWithdrawalResponse[]>([]);

  const loadPendingAccountWithdrawals = useCallback(() => {
    Promise.all([
      dataWithdrawals.getPendingWithdrawals(),
      dataWithdrawals.getPendingSavingsAccountWithdrawals(),
    ]).then(([pendingWithdrawals, pendingSavingsWithdrawals]) => {
      setPendingWithdrawals(pendingWithdrawals);
      setPendingSavingsAccountWithdrawals(pendingSavingsWithdrawals);
    });
  }, []);

  useEffect(() => {
    loadPendingAccountWithdrawals();
  }, [loadPendingAccountWithdrawals]);

  if (typeof accounts === "undefined") {
    return null;
  }

  // Yes, I know, KF logic in a generic component. Both
  // /withdrawal/pending and /danica/withdrawal/pending might
  // contain the same withdrawal. Unfortunately we'll need to
  // display the duplicates from the /danica/withdrawal/pending
  // - it has the information we need.
  const filteredPendingWithdrawals = excludePendingKFWithdrawals(
    pendingWithdrawals,
    accounts.investmentAccounts
  );
  const withdrawals = mergeWithdrawals(
    filteredPendingWithdrawals,
    pendingSavingsAccountWithdrawals,
    accounts
  );

  return (
    <div className="withdrawal-page-pending-single">
      <div>
        {withdrawals.map((withdrawal) => {
          if (isInvestmentWithdrawal(withdrawal)) {
            return (
              <PendingInvestmentWithdrawal
                key={
                  withdrawal.withdrawal.accountId +
                  withdrawal.withdrawal.externalBankAccount
                }
                account={withdrawal.account}
                withdrawal={withdrawal.withdrawal}
                onWithdrawalCancelled={loadPendingAccountWithdrawals}
              />
            );
          }
          if (isSavingsWithdrawal(withdrawal)) {
            return (
              <PendingSavingsWithdrawal
                key={
                  withdrawal.withdrawal.accountId +
                  withdrawal.withdrawal.externalBankAccount
                }
                account={withdrawal.account}
                withdrawal={withdrawal.withdrawal}
                onWithdrawalCancelled={loadPendingAccountWithdrawals}
              />
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

import { FC } from "react";
import {
  EsgQuestions,
  SustainabilityQuestions,
} from "../../../data/dataInvestments";
import { ConfirmEsgUpdate } from "../../../pageComponents/confirmEsgUpdate/ConfirmEsgUpdate";

export interface ConfirmEsgUpdateLocationState {
  sustainabilityQuestions: SustainabilityQuestions;
  esgAnswers: EsgQuestions;
  esgBestMatch: EsgQuestions;
}

interface Props {
  locationState: ConfirmEsgUpdateLocationState;
  onNext: (value: SustainabilityQuestions) => void;
}

export const ConfirmEsgUpdateWrapper: FC<Props> = ({
  locationState,
  onNext,
}) => {
  return (
    <ConfirmEsgUpdate
      esg={locationState.esgAnswers}
      updatedEsg={locationState.esgBestMatch!}
      next={() => {
        onNext(locationState.sustainabilityQuestions);
      }}
    />
  );
};

import React from "react";
import AnimationData from "./moneyAnimation.json";
import Lottie from "react-lottie-player";
import "./MoneyAnimation.scss";
import { DEFAULT_ICON_SIZE } from "@lysaab/ui-2/components/icons/Icon";

const HEIGHT = 298;
const WIDTH = 319;
const RATIO = HEIGHT / WIDTH;

interface Props {
  size?: number;
}

export const MoneyAnimation: React.FunctionComponent<Props> = ({
  size = DEFAULT_ICON_SIZE,
}) => {
  return (
    <div
      className="moneyAnimationContainer"
      style={{ height: size, width: RATIO * size }}
    >
      <Lottie
        animationData={AnimationData}
        loop={true}
        play
        className="lottie"
      />
    </div>
  );
};

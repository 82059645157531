import { API, cache, encode } from "@lysaab/ui-2";
import { AccountType, InvestmentAccountId } from "./dataAccounts";

export interface PensionWithdrawalPlanStatusResponse {
  needUpdate: InvestmentAccountId[];
  ready: InvestmentAccountId[];
}

/**
 * length and expectedEnd won't be returned in case the withdrawal plan is "livsvarig"
 */
export interface PensionWithdrawalPlanSummaryResponse {
  startAge: number;
  expectedStart: string;
  withdrawalAccount?: InvestmentAccountId;
  length?: number;
  expectedEnd?: string;
}

interface PensionResponse {
  employer: string;
  lifeLong: boolean;
  privateProperty: boolean;
  product: AccountType;
  repaymentProtection: boolean;
  withdrawalLength: number;
  withdrawalStartAge: number;
}

export const dataLifePensionStorage = {
  getPension: (accountId: InvestmentAccountId) => {
    return API.get<PensionResponse>(encode`/life/pension/${accountId}`);
  },

  /**
   * Pension withdrawals
   */

  getWithdrawalAccount: (accountId: InvestmentAccountId) => {
    return API.get<unknown>(
      encode`/life/pension/${accountId}/withdrawalAccount`
    );
  },

  updateWithdrawalAccount: (
    accountId: InvestmentAccountId,
    body: { account: string }
  ) => {
    return API.post<void>(
      encode`/life/pension/${accountId}/withdrawalAccount`,
      body
    );
  },

  getPensionWithdrawalPlanStatus: () => {
    return API.get<PensionWithdrawalPlanStatusResponse>(
      "/life/pension/withdrawal-plan-status"
    ).then((response) => {
      cache.delete(`/life/pension/withdrawal-plan-status`);
      return response;
    });
  },

  getPensionWithdrawalPlanSummary: (accountId: InvestmentAccountId) => {
    return API.get<PensionWithdrawalPlanSummaryResponse>(
      `/life/pension/${accountId}/withdrawal/plan-summary`
    ).then((response) => {
      cache.delete(`/life/pension/${accountId}/withdrawal/plan-summary`);
      return response;
    });
  },
};

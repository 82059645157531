import { useCallback, useContext, useEffect } from "react";
import * as H from "history";
import { TranslatedText } from "../../../../components/TranslatedText";
import { TinkAvailableBank } from "../../../../data/dataTink";
import { BankSelection } from "../../../../components/tink/BankSelection";
import { AddAccountKlarnaContext } from "../AddAccountKlarnaContext";
import { EventTracker } from "../../../../components/eventTracker/EventTracker";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
  TrackerEvent,
} from "../../../../data/dataCustomerTracking";

interface Props {
  next: () => void;
  missingBank?: H.LocationDescriptor<H.LocationState>;
}

export function TinkBankSelectionWrapper({ next, missingBank }: Props) {
  const klarnaContext = useContext(AddAccountKlarnaContext);
  const setState = klarnaContext.setState;

  const onComplete = useCallback(
    (bank: TinkAvailableBank) => {
      setState({ tinkBank: bank });
      EventTracker.track({
        event: TrackerEvent.TINK_SELECTED_BANK,
        message: bank.name,
      });
      dataCustomerTrackingService.postEvent({
        eventName: "withdrawalBankSelected",
        domain: FeatureDomain.TRANSFERS,
        subDomain: SubDomain.WITHDRAWAL,
      });
      setTimeout(() => {
        next();
      }, 0);
    },
    [next, setState]
  );

  useEffect(() => {
    if (!klarnaContext.state.tinkBank) {
      dataCustomerTrackingService.postEvent({
        eventName: "withdrawalInitBankSelection",
        domain: FeatureDomain.TRANSFERS,
        subDomain: SubDomain.WITHDRAWAL,
      });
    }
  }, [klarnaContext.state.tinkBank]);

  return (
    <BankSelection
      onComplete={onComplete}
      missingBank={missingBank}
      missingBankHeader={
        <TranslatedText id="klarna.bank-selection.missing-bank.header" />
      }
      missingBankDescription={
        <TranslatedText id="klarna.bank-selection.missing-bank.description" />
      }
    />
  );
}

import { FC } from "react";
import { PensionAdviseAccount } from "../../../data/dataInvestments";
import { UpdateTakenRiskDeviation } from "../../../storyComponents/updatePension/updateTakenRiskDeviation/UpdateTakenRiskDeviation";

interface Props {
  advise: PensionAdviseAccount;
  onNext: (value: number) => void;
}

export const UpdateTakenRiskDeviationWrapper: FC<Props> = ({
  advise,
  onNext,
}) => {
  return (
    <UpdateTakenRiskDeviation
      withdrawalAge={advise.withdrawalAge}
      withdrawalMonths={advise.withdrawalMonths}
      takenRiskDeviation={advise.takenRiskDeviation}
      lifeLong={advise.lifeLong}
      action="next"
      onNext={onNext}
    />
  );
};

import type { FunctionComponent } from "react";
import { defineMessages, useIntl } from "react-intl";
import { StepIndicator } from "../../../../../../components/stepIndicator/StepIndicator";
import { AccountType } from "../../../../../../data/dataAccounts";
import { PensionMoveStatus } from "../../../../../../data/dataLifePensionMove";

import "./MoveSteps.scss";

interface Props {
  accountTypes: AccountType[];
  pensionMoveStatuses: PensionMoveStatus[];
  // TODO: This is a bit shoehorned in here. We need to refactor this
  justSignedMove?: boolean;
}

// TOOD: Moved these to defineMessages becuase we will have to start
// picking contextual messages depending on the status of the pension move.
// But we need log of state changes from backend before we can do that.
const stepMessages = defineMessages({
  initiatedText: {
    id: "sweden.move-steps.step.initiated.text",
  },
  initiatedActiveText: {
    id: "sweden.move-steps.step.initiated.active-text",
  },
  awaitingSignatureText: {
    id: "sweden.move-steps.step.signed.text",
  },
  awitingSignatureActiveText: {
    id: "sweden.move-steps.step.signed.active-text",
  },
  employerText: {
    id: "sweden.move-steps.step.employer.text",
  },
  employerActiveText: {
    id: "sweden.move-steps.step.employer.active-text",
  },
  insuranceCompanyText: {
    id: "sweden.move-steps.step.insurence-company.text",
  },
  insuranceCompanyActiveText: {
    id: "sweden.move-steps.step.insurence-company.active-text",
  },
  lysaText: {
    id: "sweden.move-steps.step.lysa.text",
  },
  lysaActiveText: {
    id: "sweden.move-steps.step.lysa.active-text",
  },
});

export const MoveSteps: FunctionComponent<Props> = ({
  accountTypes,
  pensionMoveStatuses,
  justSignedMove,
}) => {
  const intl = useIntl();

  const includeEmployerStep = accountTypes.some(
    (accountType) => accountType !== AccountType.LYSA_PPF
  );
  const allStepsDone = pensionMoveStatuses.map((pensionMoveStatus) =>
    getStepsDone(pensionMoveStatus, includeEmployerStep, justSignedMove)
  );

  const stepsDone = allStepsDone.length > 0 ? Math.min(...allStepsDone) : 0;

  return (
    <div className="MoveSteps">
      <StepIndicator
        stepsDone={stepsDone}
        steps={[
          {
            text: intl.formatMessage(stepMessages.initiatedText),
            activeText: intl.formatMessage(stepMessages.initiatedActiveText),
          },
          {
            text: intl.formatMessage(stepMessages.awaitingSignatureText),
            activeText: intl.formatMessage(
              stepMessages.awitingSignatureActiveText
            ),
          },
          ...(includeEmployerStep
            ? [
                {
                  text: intl.formatMessage(stepMessages.employerText),
                  activeText: intl.formatMessage(
                    stepMessages.employerActiveText
                  ),
                },
              ]
            : []),
          {
            text: intl.formatMessage(stepMessages.insuranceCompanyText),
            activeText: intl.formatMessage(
              stepMessages.insuranceCompanyActiveText
            ),
          },
          {
            text: intl.formatMessage(stepMessages.lysaText),
            activeText: intl.formatMessage(stepMessages.lysaActiveText),
          },
        ]}
      />
    </div>
  );
};

function getStepsDone(
  pensionMoveStatus: PensionMoveStatus,
  includeEmployerStep: boolean,
  // TODO: This is a bit shoehorned in here. We need to refactor this
  justSignedMove: boolean | undefined
): number {
  // If we just got sent back from a successful scrive, the backend might not be in sync yet.
  // Emulate WAITING_SEND_EMPLOYER in the meantime.
  // TODO: Remove this when we have a better way of handling this.
  if (justSignedMove) {
    return includeEmployerStep ? 3 : 2;
  }

  switch (pensionMoveStatus) {
    case PensionMoveStatus.REJECTED_CUSTOMER:
    case PensionMoveStatus.REJECTED_EMPLOYER:
    case PensionMoveStatus.REJECTED_INSTITUTE:
    case PensionMoveStatus.REJECTED_LYSA:
      return 0;

    case PensionMoveStatus.CREATED:
    case PensionMoveStatus.WAITING_SEND_INSURED:
    case PensionMoveStatus.SIGNING_INSURED:
      return 1;

    case PensionMoveStatus.WAITING_SEND_EMPLOYER:
    case PensionMoveStatus.WAITING_FOR_EMPLOYER:
      return 2;

    case PensionMoveStatus.WAITING_SIGNING_ADMIN:
    case PensionMoveStatus.WAITING_SEND_INSTITUTE:
    case PensionMoveStatus.WAITING_FOR_INSTITUTE:
    case PensionMoveStatus.ADDITIONAL_INFO_INSTITUTE:
      return includeEmployerStep ? 3 : 2;

    case PensionMoveStatus.APPROVED_INSTITUTE:
      return includeEmployerStep ? 4 : 3;

    case PensionMoveStatus.DONE:
      return includeEmployerStep ? 5 : 4;
  }
}

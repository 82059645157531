import { Button, Typography } from "@lysaab/ui-2";
import { useState } from "react";
import { MoneyAnimation } from "./MoneyAnimation";
import "./PensionModalEntryPoint.scss";
import { Modal } from "../../../../../components/modal/Modal";
import { TRANSFER_PENSIONS_URL } from "../../../../../countries/sweden/pages/transferPensions/TransferPensionsStory";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { useIntl } from "react-intl";

export const PensionModalEntryPoint: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true);
  const intl = useIntl();

  if (localStorage.getItem("pensionModalEntryPointClosed")) {
    return null;
  }

  const close = () => {
    setIsOpen(false);
    localStorage.setItem("pensionModalEntryPointClosed", "true");
  };

  return (
    <Modal
      showModal={isOpen}
      closeOnOverlayClick
      header={intl.formatMessage({ id: "pension.modal.entryPoint.header" })}
      onClose={() => close()}
      width={460}
      hideScrollTopButton={true}
      data-test-id="pension-modal-entry-point"
    >
      <div className="pensionModalEntryPointContainer">
        <div className="lottieWrapper">
          <MoneyAnimation size={320} />
        </div>
        <Typography>
          <TranslatedText id="pension.modal.entryPoint.body" />
        </Typography>
        <Button
          component={Link}
          label={intl.formatMessage({ id: "pension.modal.entryPoint.button" })}
          to={getNavLink(TRANSFER_PENSIONS_URL)}
          onClick={() => {
            close();
          }}
          block
        />
      </div>
    </Modal>
  );
};

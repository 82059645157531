import { LysaCountry } from "@lysaab/shared";
import {
  DanishTin,
  FinnishTin,
  GermanTin,
  SpanishTin,
  SwedishTin,
} from "@lysaab/ui-2";
import { DateTime } from "luxon";

export function getUserAge(
  tin: string | undefined,
  country: LysaCountry | undefined
): number | undefined {
  if (!tin || !country) {
    return undefined;
  }

  const birthDate = getBirthDate(tin, country);

  if (!birthDate) {
    return;
  }

  return Math.floor(
    DateTime.now().diff(DateTime.fromFormat(birthDate, "yyyy-MM-dd"), "years")
      .years
  );
}

function getBirthDate(tin: string, country: LysaCountry): string | null {
  switch (country) {
    case LysaCountry.SWEDEN:
      return new SwedishTin(tin).getBirthDate() ?? null;

    case LysaCountry.DENMARK:
      return new DanishTin(tin).getBirthDate() ?? null;

    case LysaCountry.FINLAND:
      return new FinnishTin(tin).getBirthDate() ?? null;

    case LysaCountry.GERMANY:
      return new GermanTin(tin).getBirthDate() ?? null;

    case LysaCountry.SPAIN:
      return new SpanishTin(tin).getBirthDate() ?? null;
  }
}

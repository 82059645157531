import { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Spinner } from "@lysaab/ui-2";
import { UpdateRepaymentProtection } from "../../../storyComponents/updatePension/updateRepaymentProtection/UpdateRepaymentProtection";
import { Params } from "../UpdatePensionStory";
import {
  AvailableBeneficiaryAlternative,
  BeneficiaryAlternative,
  dataLifePension,
} from "../../../data/dataLifePension";
import { PensionAdviseAccount } from "../../../data/dataInvestments";

interface Props {
  advise: PensionAdviseAccount;
  onNext: (value: AvailableBeneficiaryAlternative) => void;
}

export const UpdateRepaymentProtectionWrapper: FC<Props> = ({
  advise,
  onNext,
}) => {
  const { accountId } = useParams<Params>();
  const [repaymentProtection, setRepaymentProtection] = useState<boolean>();
  const [beneficiary, setBeneficiary] = useState<BeneficiaryAlternative>();

  useEffect(() => {
    dataLifePension.getPensionDetails(accountId).then((response) => {
      setRepaymentProtection(response.repaymentProtection);
    });
    dataLifePension.getBeneficiaryAlternative(accountId).then(setBeneficiary);
  }, [accountId]);

  if (repaymentProtection === undefined || beneficiary === undefined) {
    return <Spinner />;
  }

  return (
    <UpdateRepaymentProtection
      action="next"
      onNext={onNext}
      repaymentProtection={repaymentProtection}
      beneficiary={beneficiary}
      lifeLong={advise.lifeLong}
    />
  );
};

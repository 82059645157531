import { banks } from "./dataBanks";
import { API, cache, encode } from "@lysaab/ui-2";
import { InvestmentAccountId, SavingsAccountId } from "./dataAccounts";
import { AccountJWT } from "./dataKlarna";
import { DateTime } from "luxon";

export interface ExternalAccount {
  externalBankAccount: string;
  registered: string;
  delayed: boolean;
  bank: keyof typeof banks;
  accountName: string;
}

export function isExternalAccount(data: unknown): data is ExternalAccount {
  return (
    typeof data === "object" &&
    data !== null &&
    "externalBankAccount" in data &&
    typeof data.externalBankAccount === "string" &&
    "registered" in data &&
    typeof data.registered === "string" &&
    "delayed" in data &&
    typeof data.delayed === "boolean" &&
    "bank" in data &&
    typeof data.bank === "string" &&
    "accountName" in data &&
    typeof data.accountName === "string"
  );
}

export enum PERIODIC_TYPE {
  FIXED_AMOUNT = "FIXED_AMOUNT",
  TARGET_DATE = "TARGET_DATE",
  PERCENTAGE = "PERCENTAGE",
  PROFIT = "PROFIT",
}

export interface Withdrawal {
  accountId: InvestmentAccountId;
  amount: number;
  bank: keyof typeof banks;
  drain: boolean;
  externalBankAccount: string;
  requested: string;
  cancellableUntil?: string;
}

export interface ExternalPendingSavingsWithdrawalResponse {
  accountId: SavingsAccountId;
  requested: string;
  externalBankAccount: string;
  amount: number;
  bank: keyof typeof banks;
  cancellableUntil?: string;
}

export interface PeriodicWithdrawal {
  type: PERIODIC_TYPE;
  amount: number;
  nextDeduction: string;
  paymentDay: number;
  endDate?: string;
  accountId: InvestmentAccountId;
  externalBankAccount: string;
  bank: keyof typeof banks;
  updated: string;
}

export interface PutPeriodicWithdrawalRequest {
  type: PERIODIC_TYPE;
  externalBankAccount: string;
  accountId: string;
  paymentDay: number;
  amount?: string;
  endDate?: string;
}

export interface WithdrawalAccount {
  accountId: InvestmentAccountId;
  pendingWithdrawal: boolean;
  pendingOrder: boolean;
  worth: number;
  maxTransfer: number;
  minKeepAmount: number;
}

export function isWithdrawalAccount(
  account: unknown
): account is WithdrawalAccount {
  if (typeof account !== "object" || account === null) {
    return false;
  }

  return (
    "accountId" in account &&
    typeof account.accountId === "string" &&
    "pendingWithdrawal" in account &&
    typeof account.pendingWithdrawal === "boolean" &&
    "pendingOrder" in account &&
    typeof account.pendingOrder === "boolean" &&
    "worth" in account &&
    typeof account.worth === "number" &&
    "maxTransfer" in account &&
    typeof account.maxTransfer === "number" &&
    "minKeepAmount" in account &&
    typeof account.minKeepAmount === "number"
  );
}

export const isCancellable = (pendingWithdrawal: {
  cancellableUntil?: string;
}) => {
  return Boolean(
    pendingWithdrawal.cancellableUntil &&
      DateTime.fromISO(pendingWithdrawal.cancellableUntil).diffNow(
        "milliseconds"
      ).milliseconds > 0
  );
};

interface PutWithdrawalRequest {
  from: WithdrawalAccount;
  to: ExternalAccount;
  amount: number;
  complete: boolean;
}

export enum WithdrawableSavingsAccountStatus {
  OK = "OK",
  PENDING_WITHDRAWAL = "PENDING_WITHDRAWAL",
  PENDING_INTERNAL_TRANSFER = "PENDING_INTERNAL_TRANSFER",
  PENDING_REBALANCING = "PENDING_REBALANCING",
}

export function isWithdrawableSavingsAccountStatus(
  value: unknown
): value is WithdrawableSavingsAccountStatus {
  return (
    typeof value === "string" &&
    Object.values(WithdrawableSavingsAccountStatus).some((v) => value === v)
  );
}

export interface WithdrawableSavingsAccount {
  accountId: SavingsAccountId;
  totalBalance: number;
  status: WithdrawableSavingsAccountStatus;
  withdrawalAllowed: boolean;
}

export function isWithdrawableSavingsAccount(
  account: unknown
): account is WithdrawableSavingsAccount {
  if (typeof account !== "object" || account === null) {
    return false;
  }

  return (
    "accountId" in account &&
    typeof account.accountId === "string" &&
    "totalBalance" in account &&
    typeof account.totalBalance === "number" &&
    "status" in account &&
    isWithdrawableSavingsAccountStatus(account.status) &&
    "withdrawalAllowed" in account &&
    typeof account.withdrawalAllowed === "boolean"
  );
}

export const GET_BASE_WITHDRAWAL_URL = "/withdrawal/external-accounts";
const GET_PENDING_WITHDRAWALS_URL = "/withdrawal/pending";
const GET_PERIODIC_WITHDRAWAL_URL = "/withdrawal/periodic";
const GET_WITHDRAWAL_ACCOUNTS_URL = "/withdrawal/accounts";

export const dataWithdrawals = {
  getExternalAccounts: () => {
    return API.get<ExternalAccount[]>(GET_BASE_WITHDRAWAL_URL);
  },

  deleteExternalAccount: (externalBankAccount: string) => {
    return API.delete<ExternalAccount[]>(
      `/withdrawal/external-accounts/${externalBankAccount}`
    ).then((response) => {
      cache.delete(GET_BASE_WITHDRAWAL_URL);
      return response;
    });
  },

  getPendingWithdrawals: () => {
    return API.get<Withdrawal[]>(GET_PENDING_WITHDRAWALS_URL);
  },

  clearPendingWithdrawals: () => {
    cache.delete(GET_PENDING_WITHDRAWALS_URL);
  },

  deleteWithdrawal: (accountId: InvestmentAccountId) => {
    return API.delete<Withdrawal[]>(`/withdrawal/pending/${accountId}`).then(
      (response) => {
        cache.delete("/withdrawal");
        return response;
      }
    );
  },

  getPendingPeriodicWithdrawals: () => {
    return API.get<PeriodicWithdrawal[]>(GET_PERIODIC_WITHDRAWAL_URL);
  },

  putPeriodicWithdrawal: function (withdrawal: PutPeriodicWithdrawalRequest) {
    return API.post("/withdrawal/periodic", withdrawal).then(() =>
      cache.delete(GET_PERIODIC_WITHDRAWAL_URL)
    );
  },

  deletePeriodicWithdrawal: (accountId: InvestmentAccountId) => {
    return API.delete(`/withdrawal/periodic/${accountId}`);
  },

  clearGetPendingPeriodicWithdrawals: () => {
    cache.delete(GET_PERIODIC_WITHDRAWAL_URL);
  },

  getWithdrawalAccounts: () => {
    return API.get<WithdrawalAccount[]>(GET_WITHDRAWAL_ACCOUNTS_URL);
  },

  clearGetWithdrawalAccounts: () => {
    cache.delete(GET_WITHDRAWAL_ACCOUNTS_URL);
  },

  putWithdrawal: ({ from, to, amount, complete }: PutWithdrawalRequest) => {
    return API.put(
      `/withdrawal/${from.accountId}${complete ? "/complete" : ""}`,
      {
        amount: complete ? from.worth : amount,
        externalBankAccount: to.externalBankAccount,
      }
    ).then((resp) => {
      cache.delete(`/withdrawal`);
      cache.delete("/transfer");
      return resp;
    });
  },

  addWithdrawalAccountKlarna: (klarnaJwts: AccountJWT[]) => {
    return API.put(`/withdrawal/external-accounts/klarna`, { klarnaJwts }).then(
      (response) => {
        cache.delete(GET_BASE_WITHDRAWAL_URL);
        return response;
      }
    );
  },

  addWithdrawalAccountTink: (
    iban: string,
    accountVerificationReportId: string
  ) => {
    return API.put(`/withdrawal/external-accounts/tink`, {
      iban,
      accountVerificationReportId,
    }).then((response) => {
      cache.delete(GET_BASE_WITHDRAWAL_URL);
      return response;
    });
  },

  getWithdrawableSavingsAccounts: () => {
    return API.get<WithdrawableSavingsAccount[]>(
      `/withdrawal/savings-account/accounts`
    );
  },

  postSavingsAccountWithdrawal: (
    accountId: SavingsAccountId,
    amount: number,
    externalAccount: ExternalAccount
  ) => {
    return API.post<ExternalPendingSavingsWithdrawalResponse[]>(
      encode`/withdrawal/savings-account/${accountId}`,
      {
        amount: amount,
        externalAccount: externalAccount.externalBankAccount,
      }
    ).then((response) => {
      cache.delete(`/withdrawal`);
      cache.delete("/transfer");
      return response;
    });
  },

  getPendingSavingsAccountWithdrawals: () => {
    return API.get<ExternalPendingSavingsWithdrawalResponse[]>(
      encode`/withdrawal/savings-account/pending`
    );
  },

  deletePendingSavingsAccountWithdrawals: (accountId: SavingsAccountId) => {
    return API.delete<ExternalPendingSavingsWithdrawalResponse[]>(
      encode`/withdrawal/savings-account/pending/${accountId}`
    ).then((response) => {
      cache.delete(`/withdrawal`);
      return response;
    });
  },
};

export function getBankText(account: ExternalAccount) {
  if (account.bank === "UNKNOWN" || !((account.bank as string) in banks)) {
    return account.externalBankAccount;
  }
  return `${account.externalBankAccount} - ${banks[account.bank].long}`;
}

export function getBankName(account: ExternalAccount) {
  if (account.bank === "UNKNOWN" || !((account.bank as string) in banks)) {
    return null;
  }
  return `${banks[account.bank].long}`;
}

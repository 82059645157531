import { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Button, Spinner } from "@lysaab/ui-2";
import {
  dataInvestments,
  PensionAdviseAccount,
} from "../../../data/dataInvestments";
import { dataLifePension } from "../../../data/dataLifePension";
import { UpdateOptions } from "../../../storyComponents/updatePension/updateOptions/UpdateOptions";
import { Params } from "../UpdatePensionStory";
import { TranslatedText } from "../../../components/TranslatedText";

interface Props {
  advise: PensionAdviseAccount;
  toSustainability: () => void;
  toTakenRiskDeviation: () => void;
  toWithdrawalPlan: () => void;
  toRepaymentProtection: () => void;
  onClose: () => void;
}

export const UpdateOptionsWrapper: FC<Props> = ({
  advise,
  toSustainability,
  toTakenRiskDeviation,
  toWithdrawalPlan,
  toRepaymentProtection,
  onClose,
}) => {
  const { accountId } = useParams<Params>();
  const [repaymentProtection, setRepaymentProtection] = useState<boolean>();
  const [lockedWithdrawalPlan, setLockedWithdrawalPlan] = useState<boolean>();

  useEffect(() => {
    dataLifePension.getPensionDetails(accountId).then((response) => {
      setRepaymentProtection(response.repaymentProtection);
    });
    dataInvestments
      .getPensionWithdrawalParameters(accountId)
      .then((response) => {
        setLockedWithdrawalPlan(!!response.lockedUntil);
      });
  }, [accountId]);

  if (repaymentProtection === undefined || lockedWithdrawalPlan === undefined) {
    return <Spinner />;
  }

  return (
    <>
      <UpdateOptions
        sustainabilityImportance={advise.sustainability}
        takenRiskDeviation={advise.takenRiskDeviation}
        withdrawalAge={advise.withdrawalAge}
        withdrawalMonths={advise.withdrawalMonths}
        lifeLong={advise.lifeLong}
        repaymentProtection={repaymentProtection}
        toSustainability={toSustainability}
        toTakenRiskDeviation={toTakenRiskDeviation}
        toWithdrawalPlan={lockedWithdrawalPlan ? undefined : toWithdrawalPlan}
        toRepaymentProtection={toRepaymentProtection}
      />
      <Button
        block
        variant="secondary"
        label={
          <TranslatedText id="updatePensionStory.updateOptionsWrapper.close" />
        }
        onClick={onClose}
      />
    </>
  );
};

import { FC, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Button, Form, LysaFormRef } from "@lysaab/ui-2";
import {
  PensionAdviseAccount,
  SustainabilityImportance,
} from "../../../data/dataInvestments";
import { SustainabilityQuestion } from "../../../pageComponents/sustainability/SustainabilityQuestion";

interface Props {
  advise: PensionAdviseAccount;
  onNext: (value: SustainabilityImportance) => void;
}

export const UpdateSustainabilityWrapper: FC<Props> = ({ advise, onNext }) => {
  const intl = useIntl();
  const formRef = useRef<LysaFormRef>();

  const [sustainability, setSustainability] = useState(advise.sustainability);

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={() => {
        if (formRef.current?.isValid) {
          onNext(sustainability);
        }
      }}
    >
      <SustainabilityQuestion
        sustainability={sustainability}
        setSustainability={(sustainability) => {
          setSustainability(sustainability);
        }}
      />

      <Button
        block
        type="submit"
        label={intl.formatMessage({
          id: "updatePensionStory.updateSustainabilityWrapper.next-button",
        })}
      />
    </Form>
  );
};

import { Button } from "@lysaab/ui-2";
import { TranslatedText } from "../../../../components/TranslatedText";

import "./PensionOnlyShortcuts.scss";
import { Link } from "react-router-dom";
import { getNavLink } from "../../../../hooks/useCountryUrls";

import { SUITABILITY_ASSESSMENT_EDIT_PAGE } from "../../../suitabilityAssessment/SuitabilityAssessmentStory";

export const PensionOnlyShortcuts = () => {
  return (
    <div className="pension-only-shortcuts-wrapper">
      <div>
        <TranslatedText id="pensionOnly.shortcuts.text" />
      </div>
      <Button
        component={Link}
        to={getNavLink(SUITABILITY_ASSESSMENT_EDIT_PAGE)}
        variant="secondary"
        label={<TranslatedText id="pensionOnly.shortcuts.button.label" />}
      />
    </div>
  );
};

import { defineMessages, IntlShape } from "react-intl";
import { InvestmentType } from "@lysaab/ui-2";
import { SustainabilityImportance } from "../data/dataInvestments";

const investmentTypeText = defineMessages<InvestmentType>({
  [InvestmentType.BROAD]: { id: "investment-type.BROAD" },
  [InvestmentType.SUSTAINABLE]: { id: "investment-type.SUSTAINABLE" },
});

export function getInvestmentTypeText(
  intl: IntlShape,
  key: InvestmentType
): string {
  return intl.formatMessage(investmentTypeText[key]);
}

export function getInvestmentTypeTextFromSustainabilityImportance(
  intl: IntlShape,
  key: SustainabilityImportance
): string {
  switch (key) {
    case SustainabilityImportance.IMPORTANT:
      return getInvestmentTypeText(intl, InvestmentType.SUSTAINABLE);

    case SustainabilityImportance.NOT_IMPORTANT:
      return getInvestmentTypeText(intl, InvestmentType.BROAD);
  }
}

import * as React from "react";
import { generatePath, Link } from "react-router-dom";
import {
  CardList,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Typography,
} from "@lysaab/ui-2";
import { CardBottom } from "../../CardBottom";
import { TranslatedText } from "../../../../components/TranslatedText";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { HideIfReadOnly } from "../../../../components/hideIfReadOnly/HideIfReadOnly";
import {
  Beneficiary,
  BeneficiaryType,
  dataLifePension,
  isStandardBeneficiaries,
  Pension,
  StandardBeneficiaryPriority,
} from "../../../../data/dataLifePension";
import { InvestmentAccountId } from "../../../../data/dataAccounts";
import { defineMessages, MessageDescriptor, useIntl } from "react-intl";
import { UPDATE_PENSION_URL } from "../../../updatePensionStory/UpdatePensionStory";

import "./PensionBeneficiary.scss";

type State =
  | "LOADING"
  | "ERROR"
  | {
      details: Pension;
      beneficiaries: Beneficiary[];
    };

interface Props {
  accountId: InvestmentAccountId | undefined;
}

export const PensionBeneficiary: React.FC<Props> = ({ accountId }) => {
  const intl = useIntl();
  const [state, setState] = React.useState<State>("LOADING");

  React.useEffect(() => {
    if (accountId) {
      Promise.all([
        dataLifePension.getPensionDetails(accountId),
        dataLifePension.getBeneficiaries(accountId),
      ])
        .then(([details, beneficiaries]) => {
          setState({ details, beneficiaries });
        })
        .catch(() => {
          setState("ERROR");
        });
    }
  }, [accountId]);

  if (state === "LOADING") {
    return <Spinner />;
  }

  if (state === "ERROR") {
    return (
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon textAlign="left">
        <TranslatedText id="account-page-pension-beneficiary.error" />
      </Snackbar>
    );
  }

  return (
    <section className="account-page-pension-beneficiary">
      <Typography type="h2">
        <TranslatedText id="account.pension-beneficiary.header" />
      </Typography>
      <CardList>
        <div className="account-page-card-body">
          {state.details.repaymentProtection ? (
            <>
              <Typography>
                <TranslatedText id="account.pension-beneficiary.repayment-protection.true" />
              </Typography>
              <Typography type="label" className="margin-0">
                <TranslatedText id="account.pension-beneficiary.repayment-protection.true.header" />
              </Typography>
              <div>
                {isStandardBeneficiaries(state.beneficiaries) ? (
                  state.beneficiaries.map((beneficiary, index) => {
                    return (
                      <Typography type="body" className="margin-0" key={index}>
                        {`${intl.formatMessage(
                          beneficiaryPriorityMessages[beneficiary.priority]
                        )}: ${intl.formatMessage(
                          beneficiaryTypeMessages[beneficiary.type]
                        )}
                        ${index < state.beneficiaries.length - 1 ? "\n" : ""}`}
                      </Typography>
                    );
                  })
                ) : (
                  <Typography type="body" className="marign-0">
                    {intl.formatMessage(
                      beneficiaryTypeMessages[BeneficiaryType.PRIVATE]
                    )}
                  </Typography>
                )}
              </div>
            </>
          ) : (
            <Typography>
              <TranslatedText id="account.pension-beneficiary.repayment-protection.no" />
            </Typography>
          )}
          <Typography>
            <TranslatedText id="account.pension-beneficiary.change" />
          </Typography>
        </div>

        <HideIfReadOnly>
          <CardBottom>
            <Link
              to={{
                pathname: generatePath(getNavLink(UPDATE_PENSION_URL), {
                  accountId,
                }),
              }}
            >
              <TranslatedText id="account.pension-beneficiary.change-button" />
            </Link>
          </CardBottom>
        </HideIfReadOnly>
      </CardList>
    </section>
  );
};

const beneficiaryPriorityMessages = defineMessages<StandardBeneficiaryPriority>(
  {
    1: {
      id: "account-page-pension-beneficiary.beneficiaries.priority.first",
    },
    2: {
      id: "account-page-pension-beneficiary.beneficiaries.priority.second",
    },
  }
);

const beneficiaryTypeMessages = defineMessages<
  Exclude<BeneficiaryType, BeneficiaryType.NONE>,
  MessageDescriptor,
  Record<Exclude<BeneficiaryType, BeneficiaryType.NONE>, MessageDescriptor>
>({
  [BeneficiaryType.SPOUSE]: {
    id: "account-page-pension-beneficiary.beneficiaries.SPOUSE",
  },
  [BeneficiaryType.CHILD]: {
    id: "account-page-pension-beneficiary.beneficiaries.CHILD",
  },
  [BeneficiaryType.PRIVATE]: {
    id: "account-page-pension-beneficiary.beneficiaries.PRIVATE",
  },
});

import { FC } from "react";
import { PensionAllocation } from "../../../../../hooks/usePensionAllocation";
import { Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { GlidePathGraph } from "../../../../../components/glidePathGraph/GlidePathGraph";
import { useIntl } from "react-intl";

import "./TargetAllocation.scss";

interface Props {
  allocation: PensionAllocation;
}

export const TargetAllocation: FC<Props> = ({
  allocation: {
    glidePath,
    reallocationAge,
    withdrawalAge,
    currentTakenRisk,
    finalTakenRisk,
  },
}) => {
  const intl = useIntl();

  return (
    <div className="TargetAllocation">
      <div className="graph-wrapper">
        <GlidePathGraph
          glidePath={glidePath}
          reallocationAge={reallocationAge}
          withdrawalAge={withdrawalAge}
        />
      </div>

      <Typography type="h3">
        <TranslatedText id="updatePensionStory.verifyUpdate.components.targetAllocation.header" />
      </Typography>
      <Typography>
        <TranslatedText
          id="updatePensionStory.verifyUpdate.components.targetAllocation.body"
          values={{
            currentTakenRisk: intl.formatNumber(currentTakenRisk / 100, {
              style: "percent",
            }),
            finalTakenRisk: intl.formatNumber(finalTakenRisk / 100, {
              style: "percent",
            }),
          }}
        />
      </Typography>
    </div>
  );
};

import { Link, useLocation } from "react-router-dom";
import * as H from "history";
import { featureTrackingMissingBank } from "../../utils/featureTrackingPath";
import { dataCustomerTrackingService } from "../../data/dataCustomerTracking";

interface Props {
  missingBank: H.LocationDescriptor<H.LocationState>;
  header: JSX.Element | string;
  description: JSX.Element | string;
}

export const MissingBankCard: React.FC<Props> = ({
  missingBank,
  header,
  description,
}) => {
  const location = useLocation();

  const postFeatureTrackingBasedOnPath = () => {
    dataCustomerTrackingService.postEvent(
      featureTrackingMissingBank(location.pathname)
    );
  };
  return (
    <Link
      className="transparent-button card-list-item missing-bank-link"
      to={missingBank}
      onClick={postFeatureTrackingBasedOnPath}
    >
      <span className="missing-bank-text">
        <span className="content-wrapper">
          <span className="missing-bank-text-header">{header}</span>{" "}
          <span className="missing-bank-text-description">{description}</span>
        </span>
      </span>
    </Link>
  );
};

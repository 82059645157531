import { useIntl } from "react-intl";
import { FunctionComponent, useEffect, useState } from "react";
import {
  Button,
  CircleButton,
  Spinner,
  Success,
  Typography,
} from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { useWithdrawalPensions } from "../WithdrawalPensionsContext";
import { BankLogo } from "../../../../../components/bankLogo/BankLogo";
import { dataBanks } from "../../../../../data/dataBanks";
import {
  dataWithdrawals,
  ExternalAccount,
  getBankName,
} from "../../../../../data/dataWithdrawals";
import { FAQWithoutChange as FAQ } from "./FAQWithoutChange";
import { Help } from "./Help";
import { Modal } from "../../../../../components/modal/Modal";
import { useLocation } from "react-router";
import {
  dataLifePensionMove,
  PensionWithdrawalPlanSummaryResponse,
} from "../../../../../data/dataLifePensionMove";
import { InvestmentAccountId } from "../../../../../data/dataAccounts";

import "./Done.scss";

interface Props {
  toNext: () => void;
}

export const DoneWithoutChange: FunctionComponent<Props> = ({ toNext }) => {
  const intl = useIntl();
  const [withdrawalPensions] = useWithdrawalPensions();
  const [showModal, setShowModal] = useState(false);
  const [summary, setSummary] =
    useState<PensionWithdrawalPlanSummaryResponse>();
  const [externalAccounts, setExternalAccounts] = useState<ExternalAccount[]>();

  const location = useLocation();
  const { pathname } = location;
  const pathnameParts = pathname.split("/");
  const accountId = pathnameParts[pathnameParts.length - 2];

  useEffect(() => {
    dataLifePensionMove
      .getPensionWithdrawalPlanSummary(accountId as InvestmentAccountId)
      .then((res) => {
        setSummary(res);
      })
      .catch((error) => {
        console.log(
          `Could not load pension withdrawal plan summary. Error: ${error}`
        );
        throw error;
      });
  }, [accountId]);

  useEffect(() => {
    dataWithdrawals.getExternalAccounts().then(setExternalAccounts);
  }, []);

  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  /**
   * Get externalAccount from context if it exists for a smoother experience.
   * Get from backend otherwise (reload etc.)
   */
  const externalAccount = withdrawalPensions.externalAccount
    ? withdrawalPensions.externalAccount
    : externalAccounts?.find((account) => {
        return account.externalBankAccount === summary?.withdrawalAccount;
      });

  if (!externalAccount || !summary) {
    return <Spinner />;
  }

  return (
    <article className="withdrawal-pension-done">
      <section>
        <Success />
        <Typography type="h1" className="header">
          <TranslatedText id="sweden.withdrawal-pension.done.without-change.header" />
        </Typography>
        <Typography type="body" className="ingress">
          <TranslatedText id="sweden.withdrawal-pension.done.without-change.ingress" />
        </Typography>
        <hr className="divider" />
      </section>
      <section className="withdrawal-plan">
        <Typography type="label">
          <TranslatedText id="sweden.withdrawal-pension.done.withdrawal-plan.header" />
        </Typography>
        <dl>
          <dt>
            {intl.formatMessage({
              id: "sweden.withdrawal-pension.done.withdrawal-plan.withdrawal-age.label",
            })}
          </dt>
          <dd>
            {`${summary.expectedStart}, ${summary.startAge} `}
            {intl.formatMessage({
              id: "sweden.withdrawal-pension.done.withdrawal-plan.withdrawal-age.unit",
            })}
          </dd>
        </dl>
        <dl>
          <dt>
            {intl.formatMessage({
              id: "sweden.withdrawal-pension.done.withdrawal-plan.withdrawal-period.label",
            })}
          </dt>
          <dd>
            {`${summary.expectedEnd}, ${summary.length} `}
            {intl.formatMessage({
              id: "sweden.withdrawal-pension.done.withdrawal-plan.withdrawal-period.unit",
            })}
          </dd>
        </dl>
        <dl>
          <dt>
            {intl.formatMessage({
              id: "sweden.withdrawal-pension.done.withdrawal-plan.tax-rate.label",
            })}
            <CircleButton
              className="tax-rate-information-button"
              icon="Help"
              onClick={handleModalOpen}
            />
          </dt>
          <dd>
            {intl.formatMessage({
              id: "sweden.withdrawal-pension.done.withdrawal-plan.tax-rate.value",
            })}
          </dd>
        </dl>
        {showModal && (
          <Modal
            header={intl.formatMessage({
              id: "sweden.withdrawal-pension.done.withdrawal-plan.tax-rate.modal.header",
            })}
            showModal={!!showModal}
            onClose={handleModalClose}
            closeOnOverlayClick
          >
            <Typography type="body">
              <TranslatedText id="sweden.withdrawal-pension.done.withdrawal-plan.tax-rate.modal.body" />
            </Typography>
          </Modal>
        )}
      </section>
      <section className="withdrawal-account">
        <Typography type="label">
          <TranslatedText id="sweden.withdrawal-pension.done.withdrawal-account.header" />
        </Typography>
        <div className="withdrawal-account-row">
          <BankLogo bank={dataBanks.getBank(externalAccount.bank)} />
          <span>
            <Typography type="label" component="span">
              {externalAccount.accountName}
            </Typography>
            <Typography type="label" component="span">
              {getBankName(externalAccount)}
            </Typography>
          </span>
        </div>
      </section>
      <FAQ />
      <Help />
      <Button
        className="next"
        block
        variant="primary"
        onClick={toNext}
        label={intl.formatMessage({
          id: "sweden.withdrawal-pension.done.button",
        })}
      />
    </article>
  );
};

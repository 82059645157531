import { FunctionComponent, useContext, useState } from "react";

import { useIntl } from "react-intl";
import { MAX_AGE } from "../TransferContext";
import { getUserAge } from "../utils/userAge";
import { PensionVideo } from "./PensionVIdeo";
import { Help } from "../components/help/Help";
import { Ingress } from "../components/ingress/Ingress";
import { FAQ, FaqItemProps } from "../components/faq/FAQ";
import { Button, NewIcon, Typography } from "@lysaab/ui-2";
import { Modal } from "../../../../../components/modal/Modal";
import { LegalEntityType } from "../../../../../data/dataLogin";
import { ReactComponent as GraphIllustration } from "./graph.svg";
import { ReactComponent as RocketIllustration } from "./rocket.svg";
import { ReactComponent as WelcomeIllustration } from "./welcome.svg";
import { UserContext, useUser } from "../../../../../context/UserContext";
import { TranslatedText } from "../../../../../components/TranslatedText";

import "./Intro.scss";

// TODO: Translate these.

const faqItems: FaqItemProps[] = [
  {
    title: "Vilka pensioner kan jag flytta?",
    content:
      "De flesta icke kollektivavtalade tjänstepensioner går att flytta, men vissa försäkringar kan ha särskilda villkor som påverkar möjligheten. När du startar flyttprocessen i vår app eller på hemsidan hjälper vi dig att enkelt se vilka pensioner som kan flyttas och tar hand om resten åt dig.",
  },
  {
    title: "Varför ska jag flytta min pension?",
    content:
      "När du flyttar din pension till Lysa får du dina pengar att växa med låga avgifter och investeringar i breda, billiga indexfonder. Vi anpassar automatiskt risken utifrån din ålder och när utbetalningarna närmar sig – så att du alltid har rätt risknivå utan att behöva tänka på det.",
  },
  {
    title: "Hur vet jag var jag har mina tjänstepensioner?",
    content:
      "Vi hjälper dig att identifiera var du har dina tjänstepensioner direkt i flyttflödet och kontrollerar om de är flyttbara. Du kan även logga in på minPension.se för en komplett översikt över alla dina pensioner och nuvarande försäkringsbolag.",
  },
  {
    title: "Behöver jag göra något själv under processen?",
    content:
      "Nej, vi sköter allt åt dig – från att kontakta din tidigare arbetsgivare till att hantera flytten med försäkringsbolaget. Om något behövs från dig så hör vi av oss direkt.",
  },
  {
    title: "Hur lång tid tar en flytt?",
    content:
      "Att flytta en pension tar normalt cirka tre månader, beroende på vilket bolag du flyttar ifrån och hur snabbt det går att samla in alla nödvändiga signaturer. Under flyttprocessen är dina pengar fortsatt investerade och du ligger därför inte utanför marknaden mer än några bankdagar. ",
  },
];

interface Props {
  next: () => void;
  exit: () => void;
}

export const Intro: FunctionComponent<Props> = ({ next, exit }) => {
  const intl = useIntl();
  const user = useUser();
  const userContext = useContext(UserContext);
  const age = getUserAge(user.tin);
  const [readMore, setReadMore] = useState(false);

  if (age && age > MAX_AGE) {
    return (
      <article className="transfer-pension-intro">
        <header>
          <NewIcon.AlertOutline
            size={96}
            primaryColor="var(--lysa-icon-secondary)"
          />
          <Typography type="display-small">
            <TranslatedText id={"sweden.transfer-pension.intro.block.header"} />
          </Typography>
          <Ingress>
            <TranslatedText
              id={"sweden.transfer-pension.intro.block.reason.age"}
              values={{
                max: (text) => {
                  return <span>{MAX_AGE}</span>;
                },
              }}
            />
          </Ingress>
        </header>
        <Button
          style={{ marginTop: "6rem" }}
          block
          variant="primary"
          type="button"
          label={intl.formatMessage({
            id: "sweden.transfer-pension.intro.exit.button",
          })}
          onClick={exit}
        />
      </article>
    );
  }

  if (userContext.state.legalEntityType === LegalEntityType.CORPORATION) {
    return (
      <article className="transfer-pension-intro">
        <header>
          <NewIcon.AlertOutline
            size={96}
            primaryColor="var(--lysa-icon-secondary)"
          />
          <Typography type="display-small">
            <TranslatedText id={"sweden.transfer-pension.intro.block.header"} />
          </Typography>
          <Ingress>
            <TranslatedText
              id={"sweden.transfer-pension.intro.block.reason.corporation"}
            />
          </Ingress>
        </header>
        <Button
          style={{ marginTop: "6rem" }}
          block
          variant="primary"
          type="button"
          label={intl.formatMessage({
            id: "sweden.transfer-pension.intro.exit.button",
          })}
          onClick={exit}
        />
      </article>
    );
  }

  return (
    <article className="transfer-pension-intro">
      <header>
        <PensionVideo src="https://r2.lysa.se/PENSION_FRIBREV.mp4" />
        <Typography type="display-small">
          <TranslatedText
            id={"sweden.transfer-pension.intro.header"}
            values={{
              first: (text) => {
                return (
                  <span>
                    {text}
                    <br />
                  </span>
                );
              },
              second: (text) => {
                return <span>{text}</span>;
              },
            }}
          />
        </Typography>
        <Typography type="h3" className="ingress">
          <TranslatedText id={"sweden.transfer-pension.intro.ingress"} />
        </Typography>
        <section className="time-estimate">
          <NewIcon.Clock size={16} primaryColor="var(--lysa-text-secondary)" />
          <TranslatedText id={"sweden.transfer-pension.intro.time-estimate"} />
        </section>
      </header>
      <hr className="divider" />
      <ul className="usp-list">
        <li className="list-item">
          <span className="icon-circle">
            <NewIcon.Check primaryColor="#1840e3" />
          </span>
          <span className="list-item-text">
            <Typography type="label">
              <TranslatedText
                id={"sweden.transfer-pension.intro.list-item-1.header"}
              />
            </Typography>
          </span>
        </li>
        <li className="list-item">
          <span className="icon-circle">
            <NewIcon.Check primaryColor="#1840e3" />
          </span>
          <span className="list-item-text">
            <Typography type="label">
              <TranslatedText
                id={"sweden.transfer-pension.intro.list-item-2.header"}
              />
            </Typography>
          </span>
        </li>
        <li className="list-item">
          <span className="icon-circle">
            <NewIcon.Check primaryColor="#1840e3" />
          </span>
          <span className="list-item-text">
            <Typography type="label">
              <TranslatedText
                id={"sweden.transfer-pension.intro.list-item-3.header"}
              />
            </Typography>
          </span>
        </li>
      </ul>
      <hr className="divider" />
      <section className="why">
        <Typography type="h2" className="why-header">
          <TranslatedText id="sweden.transfer-pension.intro.why.header" />
        </Typography>
        <div className="why-sub-section">
          <RocketIllustration />
          <Typography type="h3" className="why-sub-section-header">
            <TranslatedText id="sweden.transfer-pension.intro.why.sub-section-one.header" />
          </Typography>
          <Typography>
            <TranslatedText
              id="sweden.transfer-pension.intro.why.sub-section-one.body"
              values={{
                link: (text) => {
                  return (
                    <button
                      className="read-more-button"
                      onClick={() => setReadMore(true)}
                    >
                      {text}
                    </button>
                  );
                },
              }}
            />
          </Typography>
        </div>
        <div className="why-sub-section grey-bg">
          <GraphIllustration />
          <Typography type="h3" className="why-sub-section-header">
            <TranslatedText id="sweden.transfer-pension.intro.why.sub-section-two.header" />
          </Typography>
          <Typography>
            <TranslatedText id="sweden.transfer-pension.intro.why.sub-section-two.body" />
          </Typography>
        </div>
        <div className="why-sub-section">
          <WelcomeIllustration />
          <Typography type="h3" className="why-sub-section-header">
            <TranslatedText id="sweden.transfer-pension.intro.why.sub-section-three.header" />
          </Typography>
          <Typography>
            <TranslatedText id="sweden.transfer-pension.intro.why.sub-section-three.body" />
          </Typography>
        </div>
        <section className="contact grey-bg">
          <FAQ faqItems={faqItems} />
          <Help />
        </section>
        {readMore && (
          <Modal
            header={intl.formatMessage({
              id: "sweden.transfer-pension.intro.read-more.fees.heading",
            })}
            showModal={readMore}
            onClose={() => {
              setReadMore(false);
            }}
            closeOnOverlayClick
          >
            <Typography type="body">
              <TranslatedText id="sweden.transfer-pension.intro.read-more.fees.body.one" />
            </Typography>
            <Typography type="body">
              <TranslatedText id="sweden.transfer-pension.intro.read-more.fees.body.two" />
            </Typography>
            <Typography type="body">
              <TranslatedText id="sweden.transfer-pension.intro.read-more.fees.body.three" />
            </Typography>
            <Typography type="body-small">
              <TranslatedText id="sweden.transfer-pension.intro.read-more.fees.footnote" />
            </Typography>
          </Modal>
        )}
      </section>
      <Button
        className="next"
        block
        variant="primary"
        onClick={next}
        label={intl.formatMessage({
          id: "sweden.transfer-pension.intro.button",
        })}
      />
    </article>
  );
};

import {
  LysaFormRef,
  Form,
  Card,
  Button,
  MoneyInput,
  RequiredValidator,
  MinValidator,
  MaxValidator,
  Alternative,
  RadioGroup,
  SNACKBAR_TYPES,
  Snackbar,
  Spinner,
} from "@lysaab/ui-2";
import { Fragment, useEffect, useContext, useRef, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router";
import { EventTracker } from "../../../../components/eventTracker/EventTracker";
import { TranslatedText } from "../../../../components/TranslatedText";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import { dataWithdrawals } from "../../../../data/dataWithdrawals";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { Disclaimer } from "../components/disclaimer/Disclaimer";
import { useWithdrawalContext } from "../WithdrawalContext";
import { WITHDRAWALS_REQUEST_PAGE } from "../WithdrawalStory";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
  TrackerEvent,
} from "../../../../data/dataCustomerTracking";
import { useIsReadOnly } from "../../../../hooks/useIsReadOnly";
import HorizontalDivider from "../../../../components/horizontalDivider/HorizontalDivider";
import { WithdrawalHelp } from "../components/withdrawalHelp/WithdrawalHelp";
import { WithdrawalItem } from "../../../../components/withdrawalItem/WithdrawalItem";
import { AccountType } from "../../../../data/dataAccounts";

const messages = defineMessages({
  amountLabel: {
    id: "withdrawal.story.amount.label",
  },
  amountRequired: {
    id: "withdrawal.story.amount.required",
  },
  amountMin: {
    id: "withdrawal.story.amount.min",
  },
  amountMax: {
    id: "withdrawal.story.amount.max",
  },
  alternativeAmount: {
    id: "withdrawal.story.amount.type.amount",
  },
  alternativeHeader: {
    id: "withdrawal.story.amount.type.header",
  },
  alternativeRequired: {
    id: "withdrawal.story.amount.type.required",
  },
  reasonLabel: {
    id: "withdrawal.story.amount.reason.label",
  },
  reasonPlaceholder: {
    id: "withdrawal.story.amount.reason.placeholder",
  },
});

const drainLabelMessages = defineMessages<"KF" | "OTHER">({
  KF: {
    id: "withdrawal.story.amount.kf.type.drain",
  },
  OTHER: {
    id: "withdrawal.story.amount.type.drain",
  },
});

const MIN_PERCENTAGE = 0.8;

export enum WithdrawalType {
  AMOUNT,
  DRAIN,
}

interface Props {
  next: (withdrawalType: WithdrawalType) => void;
}

export function Amount({ next }: Props) {
  const formRef = useRef<LysaFormRef>();
  const localizationContext = useContext(LocalizationContext);
  const withdrawalContext = useWithdrawalContext();
  const intl = useIntl();
  const [selectedAlternative, setSelectedAlternative] =
    useState<Alternative<WithdrawalType>>();
  const history = useHistory();
  const isReadOnly = useIsReadOnly();
  const [loading, setLoading] = useState(false);

  const alternatives: Alternative<WithdrawalType>[] = [
    {
      text: intl.formatMessage(messages.alternativeAmount),
      value: WithdrawalType.AMOUNT,
    },
    {
      text: intl.formatMessage(
        withdrawalContext.state.selectedLysaAccount?.type === AccountType.KF_SWE
          ? drainLabelMessages.KF
          : drainLabelMessages.OTHER
      ),
      value: WithdrawalType.DRAIN,
    },
  ];

  useEffect(() => {
    if (
      !withdrawalContext.state.selectedLysaAccount ||
      !withdrawalContext.state.selectedExternalAccount
    ) {
      history.push(getNavLink(WITHDRAWALS_REQUEST_PAGE));
    }
  }, [
    history,
    withdrawalContext.state.selectedExternalAccount,
    withdrawalContext.state.selectedLysaAccount,
  ]);

  useEffect(() => {
    dataCustomerTrackingService.postEvent({
      domain: FeatureDomain.TRANSFERS,
      subDomain: SubDomain.WITHDRAWAL,
      eventName: "withdrawalAmount",
    });
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <h2>
        <TranslatedText id="withdrawal.amount-selection.header" />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (
            !formRef.current?.isValid ||
            isReadOnly ||
            !withdrawalContext.state.selectedLysaAccount ||
            !withdrawalContext.state.selectedExternalAccount ||
            !selectedAlternative
          ) {
            return;
          }
          setLoading(true);
          dataWithdrawals
            .putWithdrawal({
              from: withdrawalContext.state.selectedLysaAccount,
              amount: Number(withdrawalContext.state.amount),
              to: withdrawalContext.state.selectedExternalAccount,
              complete: selectedAlternative.value === WithdrawalType.DRAIN,
            })
            .then(() => {
              EventTracker.track({
                event: TrackerEvent.WITHDRAWAL,
                message: intl.formatNumber(
                  Number(withdrawalContext.state.amount),
                  {
                    style: "currency",
                    currency: localizationContext.state.currency,
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  }
                ),
              });
              dataCustomerTrackingService.postEvent({
                eventName: "clickedConfirmWithdrawal",
                domain: FeatureDomain.TRANSFERS,
                subDomain: SubDomain.WITHDRAWAL,
                payload: {
                  amount:
                    selectedAlternative.value === WithdrawalType.AMOUNT &&
                    withdrawalContext.state.amount
                      ? withdrawalContext.state.amount
                      : withdrawalContext.state.selectedLysaAccount
                      ? withdrawalContext.state.selectedLysaAccount.worth.toString()
                      : "",
                  is_drain:
                    selectedAlternative.value === WithdrawalType.DRAIN
                      ? "True"
                      : "False",
                },
              });
              next(selectedAlternative.value);
            })
            .catch((error) => {
              setLoading(false);
              throw error;
            });
        }}
      >
        <Card>
          <RadioGroup
            alternatives={alternatives}
            value={selectedAlternative}
            header={intl.formatMessage(messages.alternativeHeader)}
            onChange={(alt) => setSelectedAlternative(alt)}
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.alternativeRequired)
              ),
            ]}
          />
          {selectedAlternative?.value === WithdrawalType.AMOUNT && (
            <Fragment>
              <Snackbar type={SNACKBAR_TYPES.INFO}>
                <div>
                  {withdrawalContext.state.selectedLysaAccount?.type ===
                  AccountType.KF_SWE ? (
                    <TranslatedText
                      id="withdrawal.amount-amount.kf.amount-snackbar"
                      values={{
                        minPercentage: intl.formatNumber(MIN_PERCENTAGE, {
                          style: "percent",
                        }),
                        maxTransfer: intl.formatNumber(
                          withdrawalContext.state.selectedLysaAccount
                            ?.maxTransfer || 0,
                          {
                            style: "currency",
                            currency: localizationContext.state.currency,
                          }
                        ),
                      }}
                    />
                  ) : (
                    <TranslatedText
                      id="withdrawal.amount-amount.amount-snackbar"
                      values={{
                        minPercentage: intl.formatNumber(MIN_PERCENTAGE, {
                          style: "percent",
                        }),
                        maxTransfer: intl.formatNumber(
                          withdrawalContext.state.selectedLysaAccount
                            ?.maxTransfer || 0,
                          {
                            style: "currency",
                            currency: localizationContext.state.currency,
                          }
                        ),
                      }}
                    />
                  )}
                </div>
              </Snackbar>
              <MoneyInput
                decimalScale={0}
                currency={localizationContext.state.currency}
                value={withdrawalContext.state.amount?.toString()}
                onChange={(value) =>
                  withdrawalContext.setState({ amount: value })
                }
                label={intl.formatMessage(messages.amountLabel)}
                validators={[
                  new RequiredValidator(
                    intl.formatMessage(messages.amountRequired)
                  ),
                  new MinValidator(
                    1,
                    intl.formatMessage(messages.amountMin, {
                      amount: intl.formatNumber(1, {
                        style: "currency",
                        currency: localizationContext.state.currency,
                      }),
                    })
                  ),
                  new MaxValidator(
                    withdrawalContext.state.selectedLysaAccount?.maxTransfer ||
                      0,
                    intl.formatMessage(messages.amountMax, {
                      amount: intl.formatNumber(
                        withdrawalContext.state.selectedLysaAccount
                          ?.maxTransfer || 0,
                        {
                          style: "currency",
                          currency: localizationContext.state.currency,
                        }
                      ),
                    })
                  ),
                ]}
              />
            </Fragment>
          )}

          {selectedAlternative?.value === WithdrawalType.DRAIN &&
            withdrawalContext.state.selectedLysaAccount?.type ===
              AccountType.KF_SWE && (
              <Snackbar type={SNACKBAR_TYPES.INFO}>
                <div>
                  <TranslatedText id="withdrawal.amount-amount.kf.drain-snackbar" />
                </div>
              </Snackbar>
            )}
        </Card>

        {withdrawalContext.state.selectedLysaAccount &&
          withdrawalContext.state.selectedExternalAccount && (
            <WithdrawalItem
              moneyOnAccount={withdrawalContext.state.selectedLysaAccount.worth}
              name={withdrawalContext.state.selectedLysaAccount.name}
              externalBank={
                withdrawalContext.state.selectedExternalAccount.bank
              }
              externalBankAccount={
                withdrawalContext.state.selectedExternalAccount
                  .externalBankAccount
              }
              withdrawalAmount={
                selectedAlternative?.value === WithdrawalType.DRAIN
                  ? withdrawalContext.state.selectedLysaAccount.worth.toString()
                  : withdrawalContext.state.amount
              }
            />
          )}

        <Button
          type="submit"
          block
          label={<TranslatedText id="withdrawal.story.amount.button" />}
        />
      </Form>
      <HorizontalDivider />
      <WithdrawalHelp />
      <Disclaimer />
    </div>
  );
}

import { FunctionComponent, useEffect, useRef, useState } from "react";
import {
  Button,
  Form,
  LysaFormRef,
  Snackbar,
  SNACKBAR_TYPES,
  Typography,
} from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { useLocation } from "react-router";
import {
  PensionMoveSigningOptions,
  dataLifePensionMove,
} from "../../../../../data/dataLifePensionMove";
import { useTransfer } from "../TransferContext";
import { TranslatedText } from "../../../../../components/TranslatedText";
import type { DeliveryMethod } from "../components/deliveryMethod/DeliveryMethodInput";
import { DeliveryMethodInput } from "../components/deliveryMethod/DeliveryMethodInput";
import { MoveCard } from "../components/moveCard/MoveCard";

import "./SnailMail.scss";
import { SignStepIndicator } from "../digitalSigning/SignStepIndicator";

interface Props {
  next: (caseId: string) => void;
}

export const SnailMail: FunctionComponent<Props> = ({ next }) => {
  const intl = useIntl();
  const [transfer, setTransfer] = useTransfer();
  const location = useLocation();
  const formRef = useRef<LysaFormRef>();
  const [deliveryMethod, setDeliveryMethod] = useState<DeliveryMethod>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  const { pathname } = location;
  const pathnameParts = pathname.split("/");
  const caseId = pathnameParts[pathnameParts.length - 1];

  /**
   * We use an effect to preserve the user's state across refreshes.
   * In this context, 'move' refers to the entire transfer request.
   */
  useEffect(() => {
    if (caseId) {
      dataLifePensionMove.getMove(caseId).then((transfer) => {
        setTransfer(transfer);
      });
    }
  }, [caseId, setTransfer]);

  const moves = transfer.moves.filter(
    (move) => move.signing === PensionMoveSigningOptions.MANUAL
  );

  const hasDigitalSignedMove = transfer.moves.some(
    (move) => move.signing === PensionMoveSigningOptions.SCRIVE
  );

  return (
    <article className="transfer-pension-snail-mail">
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (formRef.current?.isValid && deliveryMethod) {
            setError(undefined);
            setIsLoading(true);
            dataLifePensionMove
              .setDeliveryMethodForCase(caseId, deliveryMethod)
              .then(() => {
                next(transfer.caseId);
                setIsLoading(false);
              })
              .catch(() => {
                setError(
                  intl.formatMessage({
                    id: "sweden.transfer-pension.snail-mail.mail-error",
                  })
                );
                setIsLoading(false);
              });
          }
        }}
      >
        <span className="header">
          <Typography type="h2">
            <TranslatedText id={"sweden.transfer-pension.snail-mail.header"} />
          </Typography>
          {hasDigitalSignedMove && <SignStepIndicator step={2} />}
        </span>
        <Typography type="body">
          <TranslatedText id={"sweden.transfer-pension.snail-mail.ingress"} />
        </Typography>
        <section className="pensions">
          {moves.map((move) => {
            return (
              <MoveCard
                key={move.id}
                insuranceCompany={move.institute}
                insuranceNumber={move.insuranceNumber}
                currentWorth={move.currentWorth}
                insuranceHolderName={move.employer}
                insuranceHolderTin={move.employerTin}
                moveAccountType={move.type}
              />
            );
          })}
        </section>

        <DeliveryMethodInput
          caseId={caseId}
          value={deliveryMethod}
          onChange={(newDeliveryMethod) => setDeliveryMethod(newDeliveryMethod)}
        />

        {error && <Snackbar type={SNACKBAR_TYPES.ERROR}>{error}</Snackbar>}
        <Button
          block
          variant="primary"
          type="submit"
          disabled={isLoading}
          label={intl.formatMessage({
            id: "sweden.transfer-pension.snail-mail.sign.button",
          })}
        />
      </Form>
    </article>
  );
};

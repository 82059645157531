import { UserState, useUser } from "../context/UserContext";
import { EconomicSituation } from "../data/dataInvestments";
import { LegalEntityType } from "../data/dataLogin";

export function usePermissions() {
  const user = useUser();

  const permissions = {
    isReadOnly: !!user.readOnly,
    isPensionOnly: isPensionOnly(user),
    allowedToOpenSavingsAccount: user.isSavingsAccountsAllowed,
  };

  return permissions;
}

function isPensionOnly(user: UserState): boolean {
  const economicSituation = user.economicSituation;

  if (user.legalEntityType !== LegalEntityType.PERSON) {
    return false;
  }

  if (!economicSituation) {
    return false;
  }

  if (
    economicSituation.includes(EconomicSituation.OK) &&
    !economicSituation.includes(EconomicSituation.INSUFFICIENT_BUFFER) &&
    !economicSituation.includes(EconomicSituation.INSUFFICIENT_MEANS) &&
    !economicSituation.includes(EconomicSituation.RUNNING_OUT_OF_MEANS)
  ) {
    return false;
  }

  // Here we can break out to support different kind of statuses.
  // For now we just check for pension only.
  if (
    economicSituation.includes(EconomicSituation.INSUFFICIENT_BUFFER) ||
    economicSituation.includes(EconomicSituation.INSUFFICIENT_MEANS) ||
    economicSituation.includes(EconomicSituation.RUNNING_OUT_OF_MEANS)
  ) {
    return true;
  }

  // Pessimistic fallback since we rather want to be more restrictive so customers
  // without permission can't e.g. start investing with us
  return true;
}

import { Story } from "@lysaab/ui-2";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  generatePath,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../../hooks/useSafeNavigation";
import { KlarnaIntegrationClient } from "../../../utils/KlarnaIntegrationClient";
import { PageStripped } from "../../PageStripped";
import { WITHDRAWAL_PAGE_URL } from "../overview/WithdrawalPage";
import { AccountLoadingWrapper } from "./accountLoading/AccountLoadingWrapper";
import { AccountLoadingWrapper as TinkAccountLoadingWrapper } from "./accountLoading/tink/AccountLoadingWrapper";
import { AccountSelection } from "./accountSelection/AccountSelection";
import { AccountSelection as TinkAccountSelection } from "./accountSelection/tink/AccountSelection";
import { AddAccountKlarnaContextProvider } from "./AddAccountKlarnaContext";
import { BankSelectionWrapper } from "./bankSelection/BankSelectionWrapper";
import * as H from "history";
import { Done } from "./done/Done";
import { useStoryValues } from "../../../hooks/useStoryValues";
import { useIsPerson } from "../../../hooks/useIsPerson";
import { OVERVIEW_PAGE_URL } from "../../overview/OverviewPage";
import { Variant } from "@lysaab/shared";
import { experimentKeys } from "../../../experimentConfig";
import { TinkBankSelectionWrapper } from "./bankSelection/TinkBankSelectionWrapper";

export const ADD_EXTERNAL_KLARNA_URL = "/withdrawals/add/klarna";

export interface Props {
  missingBank?: string;
}

export const BASE_ROUTES = {
  BANK_SELECTION: `${ADD_EXTERNAL_KLARNA_URL}/`,
  ACCOUNT_LOADING: `${ADD_EXTERNAL_KLARNA_URL}/account-loading`,
  ACCOUNT_SELECTION: `${ADD_EXTERNAL_KLARNA_URL}/account-selection`,
  DONE: `${ADD_EXTERNAL_KLARNA_URL}/done`,
};

const messages = defineMessages({
  header: {
    id: "withdrawal.add.klarna.story.header",
  },
  ariaProgressLabel: {
    id: "withdrawal.add.klarna.story.ariaProgressLabel",
  },
});

export interface AddKlarnaAccountLocationState {
  returnUrl: string;
  returnState?: H.History.LocationState;
}

export function AddAccountKlarnaStory({ missingBank }: Props) {
  const location = useLocation<AddKlarnaAccountLocationState | undefined>();
  const safeNavigation = useSafeNavigation();
  const intl = useIntl();
  const [locationState, setLocationState] =
    useState<AddKlarnaAccountLocationState>();
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);
  const isPerson = useIsPerson();
  const history = useHistory();

  useEffect(() => {
    if (!isPerson) {
      console.error("Company reached AddAccountKlarnaStory");
      history.replace(OVERVIEW_PAGE_URL);
    }
  }, [history, isPerson]);

  useEffect(() => {
    KlarnaIntegrationClient.preLoad();
  }, []);

  useEffect(() => {
    if (!locationState && location.state) {
      setLocationState(location.state);
    }
  }, [location.state, locationState]);

  const onBack = (currentIndex: number) => {
    if (
      currentIndex === 0 ||
      currentIndex === Object.values(ROUTES).indexOf(ROUTES.BANK_SELECTION)
    ) {
      return;
    } else if (
      currentIndex === Object.values(ROUTES).indexOf(ROUTES.ACCOUNT_LOADING) ||
      currentIndex === Object.values(ROUTES).indexOf(ROUTES.ACCOUNT_SELECTION)
    ) {
      safeNavigation(ROUTES.BANK_SELECTION);
      return;
    } else {
      safeNavigation(generatePath(Object.values(ROUTES)[currentIndex - 1]));
      return;
    }
  };

  const accountLoadingNext = useCallback(() => {
    safeNavigation(ROUTES.ACCOUNT_SELECTION);
  }, [ROUTES.ACCOUNT_SELECTION, safeNavigation]);

  return (
    <PageStripped>
      <div className="add-klarna-account">
        <AddAccountKlarnaContextProvider>
          <Story
            ariaLabelProgress={() =>
              intl.formatMessage(messages.ariaProgressLabel, {
                current: currentIndex + 1,
                total: storyLength,
              })
            }
            header={intl.formatMessage(messages.header)}
            progress={storyProgress}
            showBack={
              currentIndex > 0 &&
              currentIndex < Object.values(ROUTES).length - 1
            }
            showClose={true}
            transitionKey={currentIndex.toString()}
            onExit={() => {
              if (locationState) {
                safeNavigation({
                  pathname: locationState.returnUrl,
                  state: { returnState: locationState.returnState },
                });
              } else {
                safeNavigation(getNavLink(WITHDRAWAL_PAGE_URL));
              }
            }}
            onBack={() => onBack(currentIndex)}
          >
            <Switch
              location={location}
              {...{
                order: currentIndex,
              }}
            >
              <Route
                path={ROUTES.BANK_SELECTION}
                exact
                render={() => (
                  <>
                    <Variant
                      propertyKey={experimentKeys.TINK_ENABLED}
                      value="true"
                    >
                      <TinkBankSelectionWrapper
                        next={() => safeNavigation(ROUTES.ACCOUNT_LOADING)}
                        missingBank={
                          locationState
                            ? {
                                pathname: missingBank,
                                state: {
                                  returnUrl: locationState.returnUrl,
                                  returnState: locationState.returnState,
                                },
                              }
                            : missingBank
                        }
                      />
                    </Variant>
                    <Variant
                      propertyKey={experimentKeys.TINK_ENABLED}
                      value="false"
                    >
                      <BankSelectionWrapper
                        next={() => safeNavigation(ROUTES.ACCOUNT_LOADING)}
                        missingBank={
                          locationState
                            ? {
                                pathname: missingBank,
                                state: {
                                  returnUrl: locationState.returnUrl,
                                  returnState: locationState.returnState,
                                },
                              }
                            : missingBank
                        }
                      />
                    </Variant>
                  </>
                )}
              />

              <Route
                path={ROUTES.ACCOUNT_LOADING}
                exact
                render={() => (
                  <>
                    <Variant
                      disableExposure
                      propertyKey={experimentKeys.TINK_ENABLED}
                      value="true"
                    >
                      <TinkAccountLoadingWrapper next={accountLoadingNext} />
                    </Variant>
                    <Variant
                      disableExposure
                      propertyKey={experimentKeys.TINK_ENABLED}
                      value="false"
                    >
                      <AccountLoadingWrapper next={accountLoadingNext} />
                    </Variant>
                  </>
                )}
              />

              <Route
                path={ROUTES.ACCOUNT_SELECTION}
                exact
                render={() => (
                  <>
                    <Variant
                      disableExposure
                      propertyKey={experimentKeys.TINK_ENABLED}
                      value="true"
                    >
                      <TinkAccountSelection
                        next={() => {
                          if (locationState) {
                            safeNavigation({
                              pathname: locationState.returnUrl,
                              state: { returnState: locationState.returnState },
                            });
                          } else {
                            safeNavigation(ROUTES.DONE);
                          }
                        }}
                      />
                    </Variant>
                    <Variant
                      disableExposure
                      propertyKey={experimentKeys.TINK_ENABLED}
                      value="false"
                    >
                      <AccountSelection
                        next={() => {
                          if (locationState) {
                            safeNavigation({
                              pathname: locationState.returnUrl,
                              state: { returnState: locationState.returnState },
                            });
                          } else {
                            safeNavigation(ROUTES.DONE);
                          }
                        }}
                      />
                    </Variant>
                  </>
                )}
              />
              <Route path={ROUTES.DONE} exact render={() => <Done />} />
            </Switch>
          </Story>
        </AddAccountKlarnaContextProvider>
      </div>
    </PageStripped>
  );
}

import type { FunctionComponent } from "react";
import { WithdrawalItem } from "../../../../../components/withdrawalItem/WithdrawalItem";
import {
  getAccountAmount,
  SavingsAccount,
} from "../../../../../data/dataAccounts";
import { ExternalPendingSavingsWithdrawalResponse } from "../../../../../data/dataWithdrawals";
import { CancelPendingSavingsWithdrawal } from "../../../../overview/pendingTransactions/CancelPendingSavingsWithdrawal";

interface Props {
  account: SavingsAccount;
  withdrawal: ExternalPendingSavingsWithdrawalResponse;
  onWithdrawalCancelled: () => void;
}

export const PendingSavingsWithdrawal: FunctionComponent<Props> = ({
  withdrawal,
  account,
  onWithdrawalCancelled,
}) => {
  return (
    <div className="pending-withdrawal">
      <WithdrawalItem
        date={new Date(withdrawal.requested)}
        name={account.name}
        moneyOnAccount={getAccountAmount(account)}
        externalBank={withdrawal.bank}
        externalBankAccount={withdrawal.externalBankAccount}
        withdrawalAmount={withdrawal.amount.toString()}
      />
      <CancelPendingSavingsWithdrawal
        withdrawal={withdrawal}
        onWithdrawalCancelled={onWithdrawalCancelled}
      />
    </div>
  );
};

import { useCallback, useEffect, useState } from "react";
import * as React from "react";
import cx from "classnames";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { OverviewAccount } from "./accountCards/OverviewAccount";
import {
  AccountOrderDirection,
  AccountOrder,
  dataAccounts,
  isInvestmentAccount,
  AllAccountResponse,
  CompoundAccount,
  isSavingsAccount,
  SavingsAccount,
  InvestmentAccount,
} from "../../data/dataAccounts";
import {
  Store,
  STORE_ACCOUNTS_ORDER,
  STORE_ACCOUNTS_ORDER_DIRECTION,
} from "../../Store";
import { Spinner, CircleButton, Alternative, Select } from "@lysaab/ui-2";
import { OpenNewAccount } from "./OpenNewAccount";
import "./OverviewPageAccounts.scss";
import { RouteAwareToggle } from "../../components/route/RouteAwareToggle";
import { getNavLink } from "../../hooks/useCountryUrls";
import { CREATE_ACCOUNT_URL } from "../createAccount/CreateAccountStory";
import { OverviewSavingsAccount } from "./accountCards/OverviewSavingsAccount";
import { LayoutGroup } from "framer-motion";
import { OverviewSharedAccount } from "./accountCards/OverviewSharedAccount";
import { OverviewSharedSavingsAccount } from "./accountCards/OverviewSharedSavingsAccount";
import {
  dataSavingsAccount,
  EffectiveInterestRateWithAccountId,
} from "../../data/dataSavingsAccount";
import {
  dataSavingsAccountInterest,
  ExternalAccruedInterestResponse,
} from "../../data/dataSavingsAccountInterest";
import { PendingDeposits } from "./pendingTransactions/PendingDeposits";
import { PendingWithdrawals } from "./pendingTransactions/PendingWithdrawals";
import { PendingInternalTransfers } from "./pendingTransactions/PendingInternalTransfers";
import { PendingPensions } from "./pendingTransactions/PendingPensions";
import "./pendingTransactions/OverviewPending.scss";
import { usePermissions } from "../../hooks/usePermissions";
import { PensionOnlyShortcuts } from "./components/pensionOnlyShortcuts/PensionOnlyShortcuts";
import { PendingPensionWithdrawals } from "./pendingTransactions/PendingPensionWithdrawals";

const MAX_HORIZONTAL_ITEMS = 3;

const ACCOUNT_ANIMATION_SCOPE = "ACCOUNT_ANIMATION_SCOPE";

const messages = defineMessages({
  name: {
    id: "overview.accounts.order.name",
  },
  created: {
    id: "overview.accounts.order.created",
  },
  used: {
    id: "overview.accounts.order.used",
  },
  sortBy: {
    id: "overview.accounts.order.sortBy",
  },
});

interface Props {
  accounts: AllAccountResponse | undefined;
}

export const OverviewPageAccounts: React.VFC<Props> = ({ accounts }) => {
  const intl = useIntl();
  const [order, setOrder] = useState<AccountOrder>(
    Store.getValue(STORE_ACCOUNTS_ORDER) as AccountOrder
  );
  const [direction, setDirection] = useState<AccountOrderDirection>(
    Store.getValue(STORE_ACCOUNTS_ORDER_DIRECTION) as AccountOrderDirection
  );

  const alternatives = [
    {
      text: intl.formatMessage(messages.name),
      value: AccountOrder.NAME,
    },
    {
      text: intl.formatMessage(messages.created),
      value: AccountOrder.CREATED,
    },
  ];

  const onChange = useCallback((ev: Alternative<AccountOrder>) => {
    Store.setValue(STORE_ACCOUNTS_ORDER, ev.value);
    setOrder(ev.value);
  }, []);

  const onClick = useCallback(() => {
    Store.setValue(STORE_ACCOUNTS_ORDER_DIRECTION, direction);
    if (direction === AccountOrderDirection.DESCENDING) {
      setDirection(AccountOrderDirection.ASCENDING);
      Store.setValue(
        STORE_ACCOUNTS_ORDER_DIRECTION,
        AccountOrderDirection.ASCENDING
      );
    } else {
      setDirection(AccountOrderDirection.DESCENDING);
      Store.setValue(
        STORE_ACCOUNTS_ORDER_DIRECTION,
        AccountOrderDirection.DESCENDING
      );
    }
  }, [direction]);

  if (typeof accounts === "undefined") {
    return <Spinner />;
  }

  const personalAccounts = [
    ...accounts.investmentAccounts,
    ...accounts.savingsAccounts,
  ];

  const sharedAccounts = [
    ...accounts.sharedInvestmentAccounts,
    ...accounts.sharedSavingsAccounts,
  ];

  const value =
    alternatives.filter((item) => item.value === order)[0] ||
    AccountOrder.CREATED;

  return (
    <div className="overview-page-accounts">
      <PendingPensionWithdrawals />
      <PendingDeposits />
      <PendingWithdrawals />
      <PendingInternalTransfers />
      <PendingPensions />

      <div className="overview-page-accounts-top">
        <h2>
          <FormattedMessage id="overview.accounts.header" />
        </h2>
        {personalAccounts.length > 1 ? (
          <div>
            <Select
              placeholder=""
              value={value}
              label={intl.formatMessage(messages.sortBy)}
              onChange={onChange}
              alternatives={alternatives}
            />
            <CircleButton
              className={cx(direction.toLocaleLowerCase())}
              onClick={onClick}
              icon="ChevronUp"
            />
          </div>
        ) : null}
      </div>
      <AccountList
        accounts={personalAccounts}
        investmentAccounts={accounts.investmentAccounts}
        order={order}
        direction={direction}
      />

      {sharedAccounts.length > 0 && (
        <>
          <h2>
            <FormattedMessage id="overview.accounts.followedHeader" />
          </h2>
          <AccountList
            accounts={sharedAccounts}
            investmentAccounts={accounts.sharedInvestmentAccounts}
            order={order}
            direction={direction}
            isShared
          />
        </>
      )}
    </div>
  );
};

interface AccountListProps {
  accounts: CompoundAccount[];
  investmentAccounts: InvestmentAccount[];
  order: AccountOrder;
  direction: AccountOrderDirection;
  isShared?: boolean;
}

const AccountList: React.VFC<AccountListProps> = ({
  accounts,
  investmentAccounts,
  order,
  direction,
  isShared = false,
}) => {
  const rest = accounts.length % MAX_HORIZONTAL_ITEMS;
  const isSingleAccount = accounts.length === 1;
  const [savingsAccountInterests, setSavingsAccountInterests] =
    useState<EffectiveInterestRateWithAccountId[]>();
  const [accruedInterests, setAccruedInterests] =
    useState<ExternalAccruedInterestResponse[]>();
  const permissions = usePermissions();

  useEffect(() => {
    const savingsAccountToList = accounts
      .filter((account): account is SavingsAccount => isSavingsAccount(account))
      .map((account) => account.accountId);

    if (savingsAccountToList.length === 0) {
      return;
    }
    dataSavingsAccountInterest
      .getMultipleAccruedInterest(savingsAccountToList)
      .then(setAccruedInterests)
      .catch((e) => {
        console.error("Overview - Could not load accrued interest");
        throw e;
      });
    dataSavingsAccount
      .getEffectiveInterestRateByAccount(savingsAccountToList)
      .then(setSavingsAccountInterests)
      .catch((e) => {
        console.error("Overview - Could not load effective interest");
        throw e;
      });
  }, [accounts]);

  dataAccounts.accountSort(accounts, order, direction);

  return (
    <LayoutGroup id={ACCOUNT_ANIMATION_SCOPE}>
      <div
        className={cx("overview-page-accounts-list", {
          appendix: rest === 2,
          "single-account": isSingleAccount,
        })}
      >
        {accounts.map((account) => {
          if (isInvestmentAccount(account)) {
            return isShared ? (
              <OverviewSharedAccount
                key={account.accountId}
                accounts={investmentAccounts}
                account={account}
              />
            ) : (
              <OverviewAccount
                key={account.accountId}
                accounts={investmentAccounts}
                account={account}
              />
            );
          }
          if (isSavingsAccount(account)) {
            const interestRate = savingsAccountInterests?.find(
              (interest) => interest.accountId === account.accountId
            );
            const accruedInterest = accruedInterests?.find(
              (accruedInterest) =>
                accruedInterest.accountId === account.accountId
            );
            return isShared ? (
              <OverviewSharedSavingsAccount
                accountId={account.accountId}
                key={account.accountId}
                interestRate={interestRate}
                accruedInterest={accruedInterest}
              />
            ) : (
              <OverviewSavingsAccount
                accountId={account.accountId}
                key={account.accountId}
                interestRate={interestRate}
                accruedInterest={accruedInterest}
              />
            );
          }
          return null;
        })}

        {!isShared && (
          <RouteAwareToggle path={getNavLink(CREATE_ACCOUNT_URL)}>
            <OpenNewAccount />
          </RouteAwareToggle>
        )}
        {permissions.isPensionOnly && <PensionOnlyShortcuts />}
      </div>
    </LayoutGroup>
  );
};

import type { FunctionComponent } from "react";
import { useCallback, useState } from "react";
import { SNACKBAR_TYPES, useFlash } from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { PendingDeposit } from "./PendingDeposits";
import { CancelPendingTransaction } from "../../../components/pendingTransactions/CancelPendingTransaction";
import { EventTracker } from "../../../components/eventTracker/EventTracker";
import { TrackerEvent } from "../../../data/dataCustomerTracking";
import { useCurrency } from "../../../context/LocalizationContext";

interface Props {
  pendingDeposit: PendingDeposit;
  onDepositCancelled: () => void;
}

export const CancelPendingDeposit: FunctionComponent<Props> = ({
  pendingDeposit,
  onDepositCancelled,
}) => {
  const intl = useIntl();
  const currency = useCurrency();
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const pushFlash = useFlash();

  const cancelDeposit = useCallback(() => {
    setHasError(false);
    setIsLoading(true);

    return pendingDeposit
      .abort?.()
      .then(() => {
        const amountMsg = intl.formatNumber(pendingDeposit.amount, {
          style: "currency",
          currency,
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });

        EventTracker.track({
          event: TrackerEvent.DELETE_DEPOSIT,
          message: `Cancelled deposit of ${amountMsg} to account ${pendingDeposit.accountId}`,
        });
        pushFlash({
          text: intl.formatMessage({ id: "pending-deposit.cancelSuccess" }),
          type: SNACKBAR_TYPES.SUCCESS,
        });
        onDepositCancelled();
        // We deliberately don't set isLoading to false here, as the component will flicker
        // with the "confirm"-part of <CancelPendingTransaction> component before closing.
      })
      .catch(() => {
        setHasError(true);
        setIsLoading(false);
      });
  }, [currency, intl, onDepositCancelled, pendingDeposit, pushFlash]);

  return (
    <CancelPendingTransaction
      isCancellable={Boolean(pendingDeposit.abort)}
      onCancelTransaction={cancelDeposit}
      isLoading={isLoading}
      hasError={hasError}
      cancelMessage={intl.formatMessage({ id: "pending-deposit.cancel" })}
      errorMessage={intl.formatMessage({
        id: "pending-deposit.cancelError",
      })}
      notCancellableMessage={intl.formatMessage({
        id: "pending-deposit.notCancellable",
      })}
      confirmCancelMessage={intl.formatMessage({
        id: "pending-deposit.confirmCancel",
      })}
      denyCancelMessage={intl.formatMessage({
        id: "pending-deposit.denyCancel",
      })}
    />
  );
};

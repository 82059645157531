import { Button, Snackbar, SNACKBAR_TYPES, Spinner } from "@lysaab/ui-2";
import { LayoutGroup, motion } from "framer-motion";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { GridCol } from "../../../components/grid/gridCol/GridCol";
import { GridRow } from "../../../components/grid/gridRow/GridRow";
import { AccountShareWithMe, dataAttorney } from "../../../data/dataAttorney";
import { getNavLink } from "../../../hooks/useCountryUrls";
import {
  ShareAccountInvitationsView,
  SHARE_ACCOUNT_INVITATIONS_VIEW_URL,
} from "../../shareAccountInvitations/ShareAccountInvitationsPage";
import { SHARE_ACCOUNT_URL } from "../../shareAccountStory/ShareAccountStory";
import { AccountShareCard } from "../AccountShareCard";
import {
  SharedAccountsView,
  SHARED_ACCOUNTS_VIEW_URL,
} from "../SharedAccountsPage";
import { RouteAwareToggle } from "../../../components/route/RouteAwareToggle";

const ANIMATION_LAYOUT_SCOPE = "SHARED_WITH_ME_LAYOUT";

export const SharedWithMe: React.VFC = () => {
  const [accountShares, setAccountShares] = useState<AccountShareWithMe[]>();
  const [error, setError] = useState<boolean>(false);

  const load = useCallback(async () => {
    return dataAttorney
      .getAccountSharesWithMe()
      .then((shares) => {
        setAccountShares(shares);
        setError(false);
      })
      .catch(() => {
        setError(true);
      });
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  const sortedShares = useMemo(
    () =>
      accountShares?.sort(
        (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
      ),
    [accountShares]
  );

  if (error) {
    return (
      <Snackbar type={SNACKBAR_TYPES.ERROR}>
        <FormattedMessage id="sharedAccountsPage.sharedWithMe.error" />
      </Snackbar>
    );
  }

  if (typeof sortedShares === "undefined") {
    return <Spinner />;
  }

  return (
    <div>
      <GridRow>
        <GridCol xsmall={12} medium={6} large={4} className="col-button">
          <Button
            component={Link}
            to={getNavLink(
              SHARE_ACCOUNT_INVITATIONS_VIEW_URL(
                ShareAccountInvitationsView.RECEIVED
              )
            )}
            block
            variant="secondary"
            label={
              <FormattedMessage id="sharedAccountsPage.sharedWithMe.viewInvitesButton" />
            }
          />
        </GridCol>
        <RouteAwareToggle path={getNavLink(SHARE_ACCOUNT_URL)}>
          <GridCol xsmall={12} medium={6} large={4} className="col-button">
            <Button
              component={Link}
              block
              to={{
                pathname: getNavLink(SHARE_ACCOUNT_URL),
                state: {
                  returnUrl: getNavLink(
                    SHARED_ACCOUNTS_VIEW_URL(SharedAccountsView.SHARED_WITH_ME)
                  ),
                },
              }}
              label={
                <FormattedMessage id="sharedAccountsPage.sharedByMe.newInviteButton" />
              }
            />
          </GridCol>
        </RouteAwareToggle>
      </GridRow>
      <h3>
        <FormattedMessage id="sharedAccountsPage.sharedWithMe.activeSharesHeader" />
      </h3>
      <LayoutGroup id={ANIMATION_LAYOUT_SCOPE}>
        <GridRow>
          {sortedShares.length ? (
            sortedShares.map((accountShare) => (
              <GridCol
                key={accountShare.accountShareId}
                xsmall={12}
                medium={6}
                large={4}
              >
                <motion.div
                  layout
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  layoutId={accountShare.accountShareId}
                >
                  <AccountShareCard
                    accountShare={accountShare}
                    onDelete={load}
                  />
                </motion.div>
              </GridCol>
            ))
          ) : (
            <GridCol>
              <p>
                <FormattedMessage id="sharedAccountsPage.sharedWithMe.noEntriesText" />
              </p>
            </GridCol>
          )}
        </GridRow>
      </LayoutGroup>
    </div>
  );
};

import { FC, useRef, useState } from "react";
import { useIntl } from "react-intl";
import {
  Slider,
  Typography,
  Button,
  LysaFormRef,
  Form,
  Spinner,
} from "@lysaab/ui-2";
import { TranslatedText } from "../../../components/TranslatedText";
import { usePensionAllocation } from "../../../hooks/usePensionAllocation";
import { useUser } from "../../../context/UserContext";
import { getUserAge } from "../../../utils/userAge";
import { GlidePathGraph } from "../../../components/glidePathGraph/GlidePathGraph";

import "./UpdateTakenRiskDeviation.scss";

// TODO: Should we debounce to avoid overloading server?
// TODO: Move consts to central config.

const MIN_DEVIATION = -20;
const MAX_DEVIATION = 20;

interface Props {
  withdrawalAge: number;
  withdrawalMonths: number | undefined;
  takenRiskDeviation: number;
  lifeLong: boolean;
  action: "next" | "save";
  onNext: (value: number) => void;
}

export const UpdateTakenRiskDeviation: FC<Props> = ({
  withdrawalAge,
  withdrawalMonths,
  takenRiskDeviation: currentTakenRiskDeviation,
  action,
  lifeLong,
  onNext,
}) => {
  const intl = useIntl();
  const formRef = useRef<LysaFormRef>();
  const [takenRiskDeviation, setTakenRiskDeviation] = useState(
    currentTakenRiskDeviation
  );

  const user = useUser();
  const age = getUserAge(user.tin, user.country);
  const { allocation, status } = usePensionAllocation({
    age,
    withdrawalAge,
    withdrawalMonths,
    takenRiskDeviation,
    lifeLong,
  });

  if (status !== "success") {
    return <Spinner />;
  }

  return (
    <section className="UpdateTakenRiskDeviation">
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (formRef.current?.isValid) {
            onNext(takenRiskDeviation);
          }
        }}
      >
        <div className="glide-path-container">
          <GlidePathGraph
            glidePath={allocation.glidePath}
            reallocationAge={allocation.reallocationAge}
            withdrawalAge={allocation.withdrawalAge}
          />
        </div>

        <Slider
          label={
            <>
              <Typography type="h3">
                <TranslatedText id="storyComponents.updatePension.updateTakenRiskDeviation.header" />
              </Typography>
              <Typography>
                <TranslatedText id="storyComponents.updatePension.updateTakenRiskDeviation.body" />
              </Typography>
              <Typography component="div" type="label" className="new-value">
                {intl.formatNumber(takenRiskDeviation / 100, {
                  style: "percent",
                })}
              </Typography>
            </>
          }
          min={MIN_DEVIATION}
          max={MAX_DEVIATION}
          value={takenRiskDeviation}
          onChange={(takenRiskDeviation) => {
            setTakenRiskDeviation(takenRiskDeviation);
          }}
        />

        <div className="slider-scale">
          <span>
            {intl.formatNumber(MIN_DEVIATION / 100, { style: "percent" })}
          </span>
          <span>{intl.formatNumber(0, { style: "percent" })}</span>
          <span>
            {intl.formatNumber(MAX_DEVIATION / 100, { style: "percent" })}
          </span>
        </div>

        <div className="buttons-container">
          <Button
            block
            type="submit"
            label={
              action === "save"
                ? intl.formatMessage({
                    id: "storyComponents.updatePension.updateTakenRiskDeviation.button.submit-save",
                  })
                : intl.formatMessage({
                    id: "storyComponents.updatePension.updateTakenRiskDeviation.button.submit-next",
                  })
            }
          />

          <Button
            block
            type="button"
            variant="secondary"
            label={intl.formatMessage({
              id: "storyComponents.updatePension.updateTakenRiskDeviation.button.reset",
            })}
            onClick={() => {
              setTakenRiskDeviation(currentTakenRiskDeviation);
            }}
          />
        </div>
      </Form>
    </section>
  );
};

import { Typography } from "@lysaab/ui-2";
import { useRef, useState } from "react";
import PensionVideoPoster from "./pensionVideoPoster.webp";
import { TranslatedText } from "../../../../../components/TranslatedText";

import "./PensionVideo.scss";

export const PensionVideo = ({ src }: { src: string; poster?: string }) => {
  const [showControls, setShowControls] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handlePlayClick = () => {
    if (videoRef.current) {
      setShowControls(true);
      videoRef.current.play();
    }
  };

  return (
    <div className="pension-video-wrapper">
      {!showControls && (
        <div className="video-player" onClick={handlePlayClick}>
          <span className="play-button"></span>
        </div>
      )}
      <video controls={showControls} poster={PensionVideoPoster} ref={videoRef}>
        <source src={src} type="video/mp4" />
        <Typography type="body">
          <TranslatedText id="sweden.transfer-pension.intro.video.fallback" />
        </Typography>
      </video>
    </div>
  );
};

import {
  FunctionComponent,
  useContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import {
  Button,
  PendingHintCode,
  FailedHintCode,
  Icon,
  OrderRef,
  useBankId,
  BankIDStatus,
  useDisablePollInBackground,
  ServerError,
  Snackbar,
  SNACKBAR_TYPES,
  Typography,
} from "@lysaab/ui-2";
import { setUserState, UserContext } from "../../../context/UserContext";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { dataBankid } from "../../../data/dataBankid";
import { useBootstrapUser } from "../../../data/dataLogin";
import {
  loginMessages,
  loginPendingMessages,
  loginFailedMessages,
} from "./LoginMessages";
import "./LoginPage.scss";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { LoginTimeoutModal } from "../../../pages/login/LoginTimeoutModal";
import { parse, stringify } from "query-string";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { Location } from "history";
import { COUNTRY_SELECT_PAGE_URL } from "../../../pages/countrySelect/CountrySelectPage";
import { Link } from "react-router-dom";
import { FlagIcon } from "../../../components/flagIcon/FlagIcon";
import { useUserStorage } from "../../../context/UserStorageContext";
import { useRefreshPageReturningUsers } from "../../../hooks/useRefreshPageReturningUsers";
import { DateTime } from "luxon";

interface Props {}

interface HistoryState {
  from?: string;
}

interface SearchParams {
  redirect?: string;
  feature?: string;
  at?: string;
  t?: string;
  q?: string;
}

interface Match {
  orderRef?: OrderRef;
}

const RELOAD_TIMER_MINUTES = 3;

const LOGIN_SWEDEN_BASE_URL = "/login/";
export const LOGIN_SWEDEN_PAGE_URL = "/login/:orderRef?";
export const LOGIN_PAGE_URL_VARIABLE = "/:orderRef?";

export const LoginPage: FunctionComponent<Props> = () => {
  const intl = useIntl();
  const userContext = useContext(UserContext);
  const history = useHistory<HistoryState>();
  const location = useLocation();
  const match = useRouteMatch<Match>();
  const { setUserStorage } = useUserStorage();
  const [error, setError] = useState<"NOT_CUSTOMER" | "RELOAD_TIMEOUT" | false>(
    false
  );
  const bootstrapUser = useBootstrapUser();

  useRefreshPageReturningUsers();

  const { orderRef } = match.params;
  const { autoStartToken, timestamp, quickStart } = getParamsFromUrl(location);

  const diff = DateTime.local().diff(
    DateTime.fromMillis(Number(timestamp)),
    "minutes"
  ).minutes;

  if (orderRef && !error && diff > RELOAD_TIMER_MINUTES) {
    // If we don't do this, we'll poll the backend and get a 500 error because
    // the orderRef is no longer valid. That's usually fine, but since the
    // login has a high volume of requests, we want to avoid it.
    setError("RELOAD_TIMEOUT");
  }

  const onComplete = useCallback(() => {
    bootstrapUser().then(
      ([
        bootstrap,
        legalEntity,
        userStates,
        isSavingsAccountsAllowed,
        economicSituation,
      ]) => {
        const rawRedirect = (parse(history.location.search) as SearchParams)
          .redirect;
        setUserState(
          bootstrap,
          legalEntity,
          userContext.setState,
          isSavingsAccountsAllowed,
          economicSituation
        );
        setUserStorage(userStates);
        if (!rawRedirect) {
          return;
        }
        const redirect = decodeURIComponent(rawRedirect);
        history.replace(redirect);
      }
    );
  }, [history, setUserStorage, userContext.setState, bootstrapUser]);

  const onPollError = useCallback(
    (error: unknown) => {
      if (error instanceof ServerError && error.status === 404) {
        setError("NOT_CUSTOMER");
        history.replace(getNavLink(LOGIN_SWEDEN_BASE_URL));
      }
    },
    [history]
  );

  const pollFnRaw = useMemo(() => {
    return orderRef && !error
      ? () => dataBankid.loginPoll(orderRef)
      : undefined;
  }, [orderRef, error]);
  const pollFn = useDisablePollInBackground(pollFnRaw);

  const qrCodePollFn = useCallback(
    () => dataBankid.qrCode(orderRef),
    [orderRef]
  );

  const { pollStatus, initiate, latestResponse, qrCode, setOpenOnOtherDevice } =
    useBankId({
      onComplete,
      onPollError,
      initPollFn: dataBankid.login,
      pollFn,
      qrCodePollFn: qrCodePollFn,
    });

  const initiateLogin = useCallback(() => {
    if (pollStatus === "IDLE" || pollStatus === "FAILED") {
      initiate().then((response) => {
        if (response) {
          history.push(
            getUrlWithParams(
              location,
              response.orderRef,
              response.autoStartToken,
              Date.now()
            )
          );
        }
      });
    }
  }, [history, initiate, location, pollStatus]);

  const shouldQuickStart = quickStart && !orderRef && !error;
  useEffect(() => {
    if (shouldQuickStart) {
      initiateLogin();
    }
  }, [initiateLogin, shouldQuickStart]);

  if (error) {
    return (
      <div className="sweden-login-page">
        <Header />
        <div className="center-wrapper">
          <h1 className="sweden-login-page--heading">
            <FormattedMessage id="sweden.login.bankid.header" />
          </h1>
          {error === "NOT_CUSTOMER" && <NotACustomerError />}
          {error === "RELOAD_TIMEOUT" && <ReloadTimeoutError />}
        </div>
      </div>
    );
  }

  if (pollStatus === "PENDING" || pollStatus === "FAILED" || shouldQuickStart) {
    return (
      <div className="sweden-login-page">
        <Header />
        <div className="center-wrapper">
          <h1>
            <FormattedMessage id="sweden.login.bankid.header" />
          </h1>
          <BankIDStatus
            retry={() => initiateLogin()}
            response={latestResponse}
            getMessages={getMessages(intl)}
            getPendingMessages={getPendingMessages(intl)}
            getFailedMessages={getFailedMessages(intl)}
            autoStartToken={autoStartToken}
            qrCode={qrCode}
            setOpenOnOtherDevice={setOpenOnOtherDevice}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="sweden-login-page">
      <LoginTimeoutModal />
      <Header />
      <div className="center-wrapper">
        <h1 className="sweden-login-page--heading">
          <FormattedMessage id="sweden.login.header" />
        </h1>
        <div className="button-wrapper">
          <Button
            block
            onClick={() => initiateLogin()}
            label={<FormattedMessage id="sweden.login.button" />}
          />
        </div>
        <div className="login-footer">
          <p>
            <FormattedMessage
              id="sweden.login.footer"
              values={{
                link: (parts) => (
                  <a href={process.env.REACT_APP_SIGNUP_SITE}>{parts}</a>
                ),
              }}
            />
          </p>
          <p>
            <FlagIcon code="se" />
            <FormattedMessage
              id="sweden.login.country-select"
              values={{
                link: (parts) => (
                  <Link to={COUNTRY_SELECT_PAGE_URL}>{parts}</Link>
                ),
              }}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

function getMessages(intl: IntlShape) {
  return () => {
    return {
      qrInfo1: intl.formatMessage(loginMessages.qrInfo1),
      qrInfo2: intl.formatMessage(loginMessages.qrInfo2),
      qrInfo3: intl.formatMessage(loginMessages.qrInfo3),
      buttonOpen: intl.formatMessage(loginMessages.buttonOpen),
      buttonErrorHeader: intl.formatMessage(loginMessages.buttonErrorHeader),
      buttonRetry: intl.formatMessage(loginMessages.buttonRetry),
      buttonClose: intl.formatMessage(loginMessages.buttonClose),
      buttonOtherDevice: intl.formatMessage(loginMessages.buttonOtherDevice),
    };
  };
}

function getPendingMessages(intl: IntlShape) {
  return (hintCode: PendingHintCode) =>
    intl.formatMessage(loginPendingMessages[hintCode]);
}

function getFailedMessages(intl: IntlShape) {
  return (hintCode: FailedHintCode) =>
    intl.formatMessage(loginFailedMessages[hintCode]);
}

function Header() {
  return (
    <header className="login-page-header">
      <Icon.Logo />
    </header>
  );
}

function getUrlWithParams(
  location: Location,
  orderRef: OrderRef | undefined,
  autoStartToken: string,
  timestamp: number
) {
  const search = parse(location.search) as SearchParams;
  search.at = autoStartToken;
  search.t = timestamp.toString();
  delete search.q;

  return {
    pathname: getNavLink(LOGIN_SWEDEN_PAGE_URL).replace(
      ":orderRef?",
      orderRef || ""
    ),
    search: stringify(search as Record<string, any>, { skipEmptyString: true }),
  };
}

function getParamsFromUrl(location: Location) {
  const search = parse(location.search) as SearchParams;
  return {
    autoStartToken: search.at,
    timestamp: search.t,
    quickStart: search.q === "t",
  };
}

const NotACustomerError = () => {
  return (
    <>
      <Snackbar icon type={SNACKBAR_TYPES.INFO}>
        <Typography type="h5" component="h2">
          <FormattedMessage id="sweden.login.error.customer-not-found.header" />
        </Typography>
        <FormattedMessage id="sweden.login.error.customer-not-found.text" />
      </Snackbar>

      <div className="button-wrapper">
        <Button
          component="a"
          block
          href={process.env.REACT_APP_SIGNUP_SITE}
          label={
            <FormattedMessage id="sweden.login.error.customer-not-found.button" />
          }
        />
      </div>
    </>
  );
};

const ReloadTimeoutError = () => {
  return (
    <>
      <Snackbar icon type={SNACKBAR_TYPES.ERROR}>
        <Typography type="h5" component="h2">
          <FormattedMessage id="sweden.login.error.timeout.header" />
        </Typography>
        <FormattedMessage id="sweden.login.error.timeout.text" />
      </Snackbar>

      <div className="button-wrapper">
        <Button
          block
          component="a"
          href={getNavLink(LOGIN_SWEDEN_BASE_URL)}
          label={<FormattedMessage id="sweden.login.error.timeout.button" />}
        />
      </div>
    </>
  );
};

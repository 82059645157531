import { useIntl } from "react-intl";
import { useLocation } from "react-router";
import { FunctionComponent, useContext } from "react";
import { Button, NewIcon, Typography } from "@lysaab/ui-2";
import { LegalEntityType } from "../../../../../data/dataLogin";
import { UserContext } from "../../../../../context/UserContext";
import { TranslatedText } from "../../../../../components/TranslatedText";
import CalendarImage from "../../../../../assets/illustration-calendar.png";

import "./Intro.scss";

interface Props {
  toNext: (accountId: string) => void;
  toOverview: () => void;
}

export const Intro: FunctionComponent<Props> = ({ toNext, toOverview }) => {
  const intl = useIntl();
  const userContext = useContext(UserContext);
  const location = useLocation();
  const { pathname } = location;
  const pathnameParts = pathname.split("/");
  const accountId = pathnameParts[pathnameParts.length - 1];

  if (userContext.state.legalEntityType === LegalEntityType.CORPORATION) {
    return (
      <article className="withdrawal-pension-intro">
        <header>
          <NewIcon.AlertOutline
            size={96}
            primaryColor="var(--lysa-icon-secondary)"
          />
          <Typography type="display-small">
            <TranslatedText
              id="sweden.withdrawal-pension.intro.block.header"
              defaultMessage="Tjänst inte tillgänglig"
            />
          </Typography>
          <Typography type="body" className="ingress">
            <TranslatedText
              id="sweden.withdrawal-pension.intro.block.reason.corporation"
              defaultMessage="Pensionsutbetalning endast tillgänglig för privatpersoner."
            />
          </Typography>
        </header>
        <Button
          block
          variant="primary"
          type="button"
          label={intl.formatMessage({
            id: "sweden.withdrawal-pension.intro.exit.button",
            defaultMessage: "Stäng",
          })}
          onClick={toOverview}
        />
      </article>
    );
  }

  return (
    <article className="withdrawal-pension-intro">
      <section>
        <img src={CalendarImage} alt="Calendar" width="200px" height="auto" />
        <Typography type="display-small">
          <TranslatedText
            id="sweden.withdrawal-pension.intro.header"
            defaultMessage="Dags att göra pensionsval!"
          />
        </Typography>
        <Typography type="body" className="ingress">
          <TranslatedText
            id="sweden.withdrawal-pension.intro.ingress"
            defaultMessage="Nu när dina pensionsutbetalningar snart drar igång, ta en stund att dubbelkolla dina inställningar. Gör de sista valen så att allt är klart och redo när utbetalningarna börjar."
          />
        </Typography>
        <section className="time-estimate">
          <NewIcon.Clock size={16} primaryColor="var(--lysa-text-secondary)" />
          <TranslatedText
            id="sweden.withdrawal-pension.intro.time-estimate"
            defaultMessage="Ca: 2 min"
          />
        </section>
      </section>
      <Button
        className="next"
        block
        variant="primary"
        onClick={() => toNext(accountId)}
        label={intl.formatMessage({
          id: "sweden.withdrawal-pension.intro.button",
          defaultMessage: "Sätt igång",
        })}
      />
    </article>
  );
};

import { useCallback, useContext, useEffect } from "react";
import { BankSelection } from "../../../../components/klarna/bankSelection/BankSelection";
import { AvailableBank } from "../../../../data/dataKlarna";
import { AddAccountKlarnaContext } from "../AddAccountKlarnaContext";
import * as H from "history";
import { TranslatedText } from "../../../../components/TranslatedText";
import {
  dataCustomerTrackingService,
  SubDomain,
} from "../../../../data/dataCustomerTracking";
import { FeatureDomain } from "../../../../data/dataCustomerTracking";

interface Props {
  next: () => void;
  missingBank?: H.LocationDescriptor<H.LocationState>;
}

export function BankSelectionWrapper({ next, missingBank }: Props) {
  const klarnaContext = useContext(AddAccountKlarnaContext);
  const setState = klarnaContext.setState;

  const onComplete = useCallback(
    (bank: AvailableBank) => {
      setState({ bank });
      dataCustomerTrackingService.postEvent({
        eventName: "withdrawalBankSelected",
        domain: FeatureDomain.TRANSFERS,
        subDomain: SubDomain.WITHDRAWAL,
      });
      setTimeout(() => {
        next();
      }, 0);
    },
    [next, setState]
  );

  useEffect(() => {
    if (!klarnaContext.state.bank) {
      dataCustomerTrackingService.postEvent({
        eventName: "withdrawalInitBankSelection",
        domain: FeatureDomain.TRANSFERS,
        subDomain: SubDomain.WITHDRAWAL,
      });
    }
  }, [klarnaContext.state.bank]);

  return (
    <BankSelection
      onComplete={onComplete}
      missingBank={missingBank}
      /* We filter some banks since we can't verify the owner of the account from these banks */
      filterBanksFunction={(bank: AvailableBank) =>
        bank.allowWithdrawalAccounts
      }
      missingBankHeader={
        <TranslatedText id="klarna.bank-selection.missing-bank.header" />
      }
      missingBankDescription={
        <TranslatedText id="klarna.bank-selection.missing-bank.description" />
      }
    />
  );
}

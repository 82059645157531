import { NewIcon, Typography } from "@lysaab/ui-2";
import { FunctionComponent, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { TranslatedText } from "../../../../../components/TranslatedText";

import "./FAQ.scss";

const faqItems: FaqItemProps[] = [
  {
    title: "Kan jag ändra mitt utbetalningsdatum?",
    content:
      "Ja, fram till dess att din första utbetalning har skett kan du enkelt ändra ditt utbetalningsdatum i appen eller på webben.",
  },
  {
    title: "Kan jag pausa mina utbetalningar?",
    content:
      "Från och med den 1:e januari 2025 kommer en lagändring som gör det tillåtet att pausa, förkorta eller förlänga utbetalningen från en pensionsförsäkring. Lysa Life kommer möjliggöra dessa ändringar under året. ",
  },
  {
    title: "Vad innebär skattesats?",
    content:
      "Skattesatsen avgör hur mycket skatt som dras från dina pensionsutbetalningar. 30 % är förvalt, och om du vill ändra till skattetabell eller en valfri procentsats behöver du kontakta oss. Detta påverkar hur mycket pengar du får utbetalt varje månad.",
  },
  {
    title: "Hur påverkar min utbetalningsplan skatten?",
    content:
      "Din utbetalningsplan påverkar hur mycket skatt du betalar på dina pensionsutbetalningar. Om du väljer att ta ut en större del av pensionen under en kortare period kan det innebära högre beskattning, eftersom inkomsten då blir högre och kan hamna i en högre skatteklass. Om du däremot sprider ut utbetalningarna över en längre tid kan det leda till lägre årlig inkomst och därmed lägre skatt. Det är viktigt att planera dina uttag noggrant för att optimera din skattesituation. För att få en bättre förståelse för hur olika uttagsalternativ påverkar din skatt kan du använda Skatteverkets tjänst “Räkna ut din skatt” (Skatteverket).",
  },
];

export const FAQWithoutChange: FunctionComponent = () => {
  return (
    <section className="withdrawal-pension-done-faq">
      <Typography type="h2">
        <TranslatedText id="sweden.withdrawal-pension.done.faq.header" />
      </Typography>
      {faqItems.map((item, index) => (
        <AccordionItem key={index} title={item.title} content={item.content} />
      ))}
    </section>
  );
};

interface FaqItemProps {
  title: string;
  content: string;
}

const AccordionItem: FunctionComponent<FaqItemProps> = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-item">
      <button onClick={toggleOpen} className="faq-button">
        <Typography type="label" className="title">
          {title}
        </Typography>
        <span
          className={isOpen ? "chevron chevron-open" : "chevron chevron-closed"}
        >
          <NewIcon.ChevronRight />
        </span>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{
              opacity: 0,
              height: 0,
            }}
            transition={{ duration: 0.3 }}
          >
            <Typography type="body" className="faq-content">
              {content}
            </Typography>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
